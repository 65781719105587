import * as React from 'react';
import { observer } from 'mobx-react-lite';
import DocumentViewVisualStore from '../stores/DocumentViewVisualStore';
import { InputNumber } from 'antd';

const ImageViewer: React.FC<{store: DocumentViewVisualStore}> = ({store}) => {
    const [currentScale, setCurrentScale] = React.useState<number>(50);

    return (
        <>
            <div style={{marginBottom: 10}}>
                <label>Scale:</label>
                <InputNumber
                    defaultValue={currentScale}
                    min={0}
                    step={10}
                    max={200}
                    formatter={value => `${value}%`}
                    onChange={v => {
                        setCurrentScale(v as number);
                    }}
                    parser={value => parseInt(value!.replace('%', ''), 10)}
                />
            </div>
            <div>
                <img src={store.imageUrl} style={{maxWidth: `${currentScale}%`, display: 'block', margin: 'auto'}}/>
            </div>
        </>
    );
};

export default observer(ImageViewer);