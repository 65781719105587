import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../common/stores';
import { useTaskTemplateStore } from '../stores/context';
import TaskTemplatesList from '../components/TaskTemplatesList';

const TaskTemplatesPage: React.FC = () => {
    const context = useTaskTemplateStore();
    const rootContext = useStore();
    return (
        <TaskTemplatesList store={context.store} projectsStore={rootContext.projectsStore}/>
    );
};

export default observer(TaskTemplatesPage);