import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EditorConfig } from '../types/Actions';
import { Checkbox, Form, Input, Select, Space, Tooltip } from 'antd';
import ActionDefinitionStore from '../stores/ActionDefinitionStore';
import { TaskBase } from '../../tasks/types';
import { Utils } from '../../common/misc/Utils';
import { FormInstance } from 'antd/es/form/Form';
import { FormOutlined, MinusCircleOutlined } from '@ant-design/icons';

interface Props {
    store: ActionDefinitionStore;
    index: number;
    form: FormInstance;
    remove: (index: number | number[]) => void
}

const ActionFieldEditor: React.FC<Props> = ({ store, index, form, remove }) => {
    const [fieldType, setFieldType] = useState<string | undefined>(form.getFieldValue(['fields', index, 'fieldType']));

    useEffect(() => {
        if (form.isFieldTouched(['fields', index, 'fieldType'])) {
            form.setFieldValue(['fields', index, 'defaultValue'], undefined);
        }
    }, [fieldType, form]);

    const onEditorChange = (ec: EditorConfig) => {
        form.setFieldValue(['fields', index, 'editor'], ec);
    };

    const getValue = (name: string) => form.getFieldValue(['fields', index, name]);

    const fieldTypes = [
        { value: 'FixedValue', label: 'Fixed value' },
        { value: 'TaskProperty', label: 'Task property' },
        { value: 'Metadata', label: 'Metadata field' },
        { value: 'UserInput', label: 'User input' }
    ];

    const fieldTypeValues = {
        'TaskProperty': 'Task Property',
        'Metadata': 'Metadata Field',
        'FixedValue': 'Value'
    };

    const taskProperties = Object.keys(new TaskBase())
        .map(key => Utils.capitalizeWord(key))
        .map(key => ({ label: key, value: key }))
        .sort((a, b) => a.label.localeCompare(b.label));
    const metadataFields = store.metadata.map(m => ({ label: m.title, value: m.name }));

    return (
        <Space>
            <Form.Item name={[index, 'isPredefined']} noStyle><Input type="hidden" /></Form.Item>
            <Form.Item name={[index, 'name']} rules={[{ required: true, message: 'Name is required' }]}>
                <Input
                    placeholder="Field name"
                    style={{ width: 140 }}
                    readOnly={getValue('isPredefined') || false}
                />
            </Form.Item>
            <Form.Item name={[index, 'title']}>
                <Input
                    placeholder="Title"
                    style={{ width: 140 }}
                    readOnly={getValue('isPredefined') || false}
                />
            </Form.Item>
            <Form.Item name={[index, 'fieldType']} rules={[{ required: true, message: 'Type is required' }]}>
                <Select
                    options={fieldTypes}
                    placeholder="Field type"
                    style={{ width: 140 }}
                    onChange={setFieldType}
                />
            </Form.Item>
            <Form.Item
                name={[index, 'defaultValue']}
                rules={[{
                    required: fieldType !== undefined && ['TaskProperty', 'Metadata', 'FixedValue'].includes(fieldType),
                    message: `${fieldType ? fieldTypeValues[fieldType] : 'Value'} is required`
                }]
                }
            >
                {fieldType !== undefined && ['Metadata', 'TaskProperty'].includes(fieldType) ?
                    <Select
                        options={getValue('fieldType') === 'Metadata' ? metadataFields : taskProperties}
                        placeholder={getValue('fieldType') === 'Metadata' ? 'Metadata field' : 'Task property'}
                        style={{ width: 350 }}
                    /> :
                    <Input
                        style={{ width: 350 }}
                        placeholder={getValue('fieldType') == 'FixedValue' ? 'Value' : 'Default value'}
                    />
                }
            </Form.Item>
            <Form.Item name={[index, 'isRequired']} valuePropName="checked">
                <Checkbox
                    disabled={getValue('isPredefined') || false}
                >
                    Required
                </Checkbox>
            </Form.Item>
            {fieldType === 'UserInput' &&
                <Form.Item>
                    <Tooltip title="Configure input" placement="bottom">
                        <FormOutlined className="alpha-antd-icon" onClick={() => {
                            store.setEditInputDialogVisible(true, getValue('editor'), onEditorChange);
                        }} />
                    </Tooltip>
                </Form.Item>
            }
            {form.getFieldValue(['fields', index, 'isPredefined']) ? null :
                <Form.Item>
                    <MinusCircleOutlined className="alpha-antd-icon no-margin" onClick={() => remove(index)} />
                </Form.Item>
            }
        </Space>
    );
};

export default observer(ActionFieldEditor);