import * as React from 'react';
import { observer } from 'mobx-react-lite';
import DashboardStore from '../stores/DashboardStore';
import CustomPieChart from './CustomPieChart';
import PieChartWidget from '../models/PieChartWidget';
import { PieChartColors } from './PieChartColors';
import { ALL_PROJECTS } from '../../tasks/screens/TasksPage';
import WidgetEditor from './WidgetEditor';
import moment from 'moment';
import { Utils } from '../../common/misc/Utils';

type Props = {
    dashboardStore: DashboardStore;
    widget: PieChartWidget;
    openEditWidgetModal: (id: string) => void
};

const PieWidgetCard: React.FC<Props> = ({widget, dashboardStore, openEditWidgetModal}) => {
    const statisticsData = Object.keys(widget.chartData).map((key, index) => 
        ({
            key,
            name: moment(widget.chartData[key].title, moment.ISO_8601, true).isValid() ? 
                Utils.formatDateStringShort(widget.chartData[key].title, true, true) :
                widget.chartData[key].title, 
            value: widget.chartData[key].count, 
            color: index <= 20 ? PieChartColors[index] : `#${Math.floor(Math.random()*16777215).toString(16)}` 
        }));

    const getPercentage = (percentage: number) => {
        return percentage === 0 ? '(< 1%)' : percentage === 100 && statisticsData.length > 0 ? '(> 99%)':`(${percentage}%)`;
    };

    const handleWidgetClick = (value: string) => {
        const url = `${window.location.href}/../tasks/project/${widget.projectId || ALL_PROJECTS}/widget/${widget.id}/value/${encodeURIComponent(value)}`;
        window.open(url, '_blank')!.focus();
    };

    return (
        <div className='card statistics-chart'>
            {!widget.isDefaultWidget && <WidgetEditor dashboardStore={dashboardStore} widget={widget} openEditWidgetModal={openEditWidgetModal}/>}
            <div className='section-title'>{widget.name}</div>
            <div className='statistics-container'>
                <CustomPieChart statisicsData={statisticsData}/>
                <div className='cards-container'>
                    {statisticsData.length ? statisticsData.map(s=> 
                        <div className='legend-label' key={s.name.toString()} style={{cursor: 'pointer'}} onClick={() => handleWidgetClick(s.key)}>
                            <div className='color-indicator' style={{backgroundColor: s.color}}/>
                            <div style={{display: 'inline'}}>{`${s.name}  ${getPercentage(s.value)}`}</div>
                        </div>
                    ) : <div className='empty-card-title'>There are no tasks yet</div>}
                </div>
            </div>
        </div>
    );

};

export default observer(PieWidgetCard);
