import { TaskType, TaskTypeDueDate } from '../types';
import appClient, { ResultApi } from '../../common/services/AppClient';

export default class TaskTypesService {
    async getTaskTypesForProject(projectId?: string): Promise<ResultApi<TaskType[]>> {
        const queryParams = projectId ? `?projectId=${projectId}` : '';
        const url = `${process.env.REACT_APP_TASKS_URL}tasktypes${queryParams}`;
        return appClient.get<TaskType[]>(url);
    }

    createTaskType(name: string, assignee: string, code: string, projectId: string, 
                   canChangeStatusManually: boolean, distinctSubtaskStatuses: boolean, dueDate: TaskTypeDueDate | null) {
        var request = {
            name,
            assignee,
            projectId,
            code,
            canChangeStatusManually,
            distinctSubtaskStatuses,
            dueDate
        };
        const url = process.env.REACT_APP_TASKS_URL + 'tasktypes/new';
        return appClient.post<string>(url, request);
    }

    editTaskType(typeId: string, name: string, assignee: string, code: string, canChangeStatusManually: boolean, 
                 distinctSubtaskStatuses: boolean, dueDate: TaskTypeDueDate | null) {
        var request = {
            typeId,
            name,
            assignee,
            code,
            canChangeStatusManually,
            distinctSubtaskStatuses,
            dueDate
        };
        const url = process.env.REACT_APP_TASKS_URL + 'tasktypes/edit';
        return appClient.update(url, request);
    }

    toggleTaskType(typeId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `tasktypes/toggle/${typeId}`;
        return appClient.update(url); 
    }
    
    deleteTaskType(typeId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `tasktypes/delete/${typeId}`;
        return appClient.post(url);
    }
}