import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useDocumentsStore } from '../stores/context';
import ModalHeader from '../../common/components/ModalHeader';
import DocumentViewer from './DocumentViewer';

type Props = {
    isVisible: boolean;
    packageId: string | undefined;
    packageName: string | undefined;
    onCloseDialog: () => void;
    onDownload: (id: string) => void
};

export const DocumentPreviewDialog: React.FC<Props> = ({ isVisible, packageId, onCloseDialog, onDownload, packageName }) => {
    const [isDialogVisible, setIsDialogVisible] = React.useState(isVisible);
    React.useEffect(() => {
        setIsDialogVisible(isVisible);
    }, [isVisible]);
    
    const {documentViewVisualStore} = useDocumentsStore();
    const onCloseCallback = () => {
        onCloseDialog();
        setIsDialogVisible(false);
    };

    const header = 
        <ModalHeader 
            title={packageName ? `${packageName} preview` : ''} 
            onCloseCallback={onCloseCallback} 
            icons={[ <i style={{marginBottom: 1}} key='1' className='alpha-icon md download' onClick={() => onDownload(packageId!)}/>]}/>;
    return (
        <Modal
            title={header}
            maskClosable={false}
            closable={false}
            centered
            className="document-preview-dialog"
            open={isDialogVisible}
            onCancel={() => setIsDialogVisible(false)}
            width={packageName?.endsWith('xlsx')? '100%' : '50%'}
            destroyOnClose
            footer={null}
        >
           
            <DocumentViewer store={documentViewVisualStore} path={packageId!}/>
        </Modal>
    );
};

export default observer(DocumentPreviewDialog);