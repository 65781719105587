import appClient from '../../common/services/AppClient';
import { PublishedReport } from '../types';

export default class ReportsService {
    uriPrefix = process.env.REACT_APP_TASKS_URL + 'reports';

    async getReports() {
        const resp = await appClient.get<PublishedReport[]>(this.uriPrefix);
        return resp.mapErr(err => new Error(err.data?.title));
    }

    async publishReport(formData: FormData) {
        const resp = await appClient.post<string>(this.uriPrefix, formData);
        return resp.mapErr(err => new Error(err.data?.title));
    }

    async updateReport(reportId: string, formData: FormData) {
        const url = this.uriPrefix + `/${reportId}`;
        const resp = await appClient.update<string>(url, formData);
        return resp.mapErr(err => new Error(err.data?.title));
    }

    async deleteReport(reportId: string) {
        const url = this.uriPrefix + `/${reportId}`;
        var resp = await appClient.delete<string>(url);
        return resp.mapErr(err => new Error(err.data?.title));
    }

    async toggleReportAvailability(reportId: string) {
        const url = this.uriPrefix + `/toggle/${reportId}`;
        var resp = await appClient.patch<string>(url);
        return resp.mapErr(err => new Error(err.data?.title));
    }
}