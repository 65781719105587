import { WarningOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import QueryDialog from '../../tasks/components/QueryDialog';
import { useTasksStore } from '../../tasks/stores/context';


type Props = {
    onReload?: () => void;
    errors?: string[];
    showingBlocks: boolean;
    onTogglePageBlocks: () => void;
    handleClick: (id: string) => void;
    dataId?: string
};

const DocumentHeaderRenderer: React.FC<Props> = ({ onReload, onTogglePageBlocks, showingBlocks, dataId, errors }) => {
    const { taskId, projectId } = useParams();
    const context = useTasksStore();
    const [isQueryDialogVisible, setIsQueryDialogVisible] = React.useState(false);
    const {taskPreviewVisualStore} = context;
    return (
        <div className="alpha-form-title-container">
            <h1 title={'eMessage'}>{'eMessage'}</h1>
            {errors && errors.length
                ? <h3 style={{ color: 'orange' }}><WarningOutlined /> Please correct your details.</h3>
                : <h3>Please review the form and fill the empty fields.</h3>
            }
            <div className="app-form-controls">
                <Tooltip title="Reload">
                    <Button
                        type="link"
                        className="reload-button"
                        onClick={onReload}
                        data-id={`alpha-application-form-reload-left-${dataId ? dataId : 'undefined'}`}
                    >
                        <i className="alpha-icon reload xs" />
                    </Button>
                </Tooltip>
                <Tooltip title="Toggle found blocks">
                    <Button
                        type="link"
                        className="reload-button"
                        onClick={onTogglePageBlocks}
                        data-id={`alpha-application-form-blocks-left-${dataId ? dataId : 'undefined'}`}
                    >
                        <i className={`alpha-icon xs ${showingBlocks ? 'eye-visible' : 'eye-invisible'}`} style={{ width: '21px' }} />
                    </Button>
                </Tooltip>
                <div className='query-button' style={{display: 'inline'}}>
                    <Button style={{ height: 40 }} onClick={() => setIsQueryDialogVisible(true)}>
                        Query
                    </Button>
                </div>
                <Button
                    key={0}
                    className="submit-button"
                    name={'submitButton'}
                    htmlType="submit"
                    type="primary"
                    data-id={`alpha-application-form-submit-left-${dataId ? dataId : 'undefined'}`}
                    onClick={() => taskPreviewVisualStore.acceptMessage(taskId!)}
                >
                    Accept
                </Button>
            </div>
            <QueryDialog
                projectId={projectId!}
                taskId={taskId!}
                store={taskPreviewVisualStore}
                isVisible={isQueryDialogVisible}
                closeDialog={() => setIsQueryDialogVisible(false)}
            />
        </div>);
};

export default observer(DocumentHeaderRenderer);
