import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import MetadataDefinitionStore from '../stores/MetadataDefinitionStore';
import MetadataDefinitionEditForm from './MetadataDefinitionEditForm';

type Props = {
    store: MetadataDefinitionStore
};

const MetadataDefinitionCreateEditDialog: React.FC<Props> = ({ store }) => {
    const mode = store.editDialogMode;
    const [form] = Form.useForm();
    return (
        <Modal
            data-id-type="modal-window"
            data-id-name="edit-metadata-definition"
            className="alpha-modal"
            title={`${mode === 'create' ? 'Create new' : 'Edit'} metadata definition`}
            open={store.editDefinitionDialogVisible}
            closable={false}
            centered
            footer={[
                <Button
                    data-id="button-edit-metadata-definition-cancel"
                    key="edit-metadata-definition-cancel"
                    size="large"
                    className="light"
                    onClick={() => {
                        store.setNewDefinitionDialogVisible(false);
                        form.resetFields();
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="button-edit-metadata-definition-save"
                    type="primary"
                    key="edit-metadata-definition-save"
                    size="large"
                    className="dark"
                    htmlType='submit'
                    form='edit-metadata-definition-form'
                >
                    Save
                </Button>
            ]}
        >
            <MetadataDefinitionEditForm store={store} form={form}/>
        </Modal>
    );
};

export default observer(MetadataDefinitionCreateEditDialog);