import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Table, Switch, Popconfirm, Layout, Tooltip, Select, Checkbox } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { TaskType } from '../types';
import TaskTypesVisualStore from '../stores/TaskTypesVisualStore';
import TaskTypeCreateDialog from './TaskTypeCreateDialog';
import TaskTypeEditDialog from './TaskTypeEditDialog';
import { ProjectsStore } from '../../common/stores';
import { Utils } from '../../common/misc/Utils';

const { Content } = Layout;
type Props = {
    store: TaskTypesVisualStore;
    projectsStore: ProjectsStore
};

const TaskTypesList: React.FC<Props> = ({ store, projectsStore }) => {
    React.useEffect(() => {
        return () => {
            store.setTaskTypes([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserInacitivityLabel = (userId: string) => {
        const isDeleted = store.usersForProject.some(u=> u.userId === userId && u.isDeleted);
        return <span data-id-cells="is-active" style={{color: 'red'}}>{isDeleted ? ' (Inactive)' : ''}</span>;
    };

    const columns: ColumnProps<TaskType>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record: TaskType) => (
                <span data-id-cells="name">{record.name}</span>
            )
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee',
            key: 'assignee',
            render: (_, record: TaskType) => (
                <>
                    <span data-id-cells="assignee">{store.getUserNameById(record.assignee)}</span>
                    {getUserInacitivityLabel(record.assignee)}
                </>
            )
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (_, record: TaskType) => (
                <span data-id-cells="code">{record.code}</span>
            )
        },
        {
            title: 'Manual status change',
            dataIndex: 'canChangeStatusManually',
            key: 'canChangeStatusManually',
            render: (_, record: TaskType) => (
                <Checkbox disabled checked={record.canChangeStatusManually} />
            )
        },
        {
            title: 'Distinct subtask statuses',
            dataIndex: 'distinctSubtaskStatuses',
            key: 'distinctSubtaskStatuses',
            render: (_, record: TaskType) => (
                <Checkbox disabled checked={record.distinctSubtaskStatuses} />
            )
        },
        {
            title: 'Actions',
            width: 200,
            render: (_, record: TaskType) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit type"
                        title="Rename"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.selectType(record);
                        }}
                    >
                        <Tooltip title="Edit type" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this type?"
                        onConfirm={() => {
                            store.deleteTaskType(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button data-id-cells="Delete type" title="Delete" size="small" type="link">
                            <Tooltip title="Delete type" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                    <Switch
                        data-id-cells="is-active"
                        data-id-value={record.isEnabled}
                        style={{ visibility: 'visible', marginBottom: 18, marginLeft: 14 }}
                        checked={record.isEnabled} onChange={() => {
                            store.toggleTaskType(record.id);
                        }}
                    />
                </div>
            )
        }
    ];
    return (
        <Layout className="screen-size tasks-types-layout layout-with-table">
            <div className='header-wrapper'>
                <div className='title-wrapper'>
                    <div className="header-title">Task Types</div>
                </div>
                <Button key={1} disabled={!store.selectedProjectId} data-id="button-add-type" type="primary" size="large" onClick={() => {
                    store.setNewTypeDialogVisible(true);
                }}>Add type</Button>
            </div>
            <TaskTypeCreateDialog store={store} />
            <TaskTypeEditDialog store={store} />
            <Layout>
                <Content>
                    <div className='projects-list-container' style={{ marginBottom: 10 }}>
                        <Select
                            options={projectsStore.administrableProjects?.map(p => ({ label: p.name, value: p.id })) || []}
                            placeholder='Select project'
                            onChange={store.setSelectedProjectId}
                            showSearch
                            filterOption={Utils.filterOption}
                        />
                    </div>
                    <Table
                        data-id="table-tasks-types-list"
                        className="alpha-table task-types"
                        loading={store.taskTypesLoading}
                        columns={columns}
                        dataSource={store.selectedProjectId ? store.taskTypes : []}
                        rowKey={(r) => r.id}
                        pagination={false}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(TaskTypesList);