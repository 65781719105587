
import React from 'react';
import { Checkbox, Form, FormInstance, Input, Select } from 'antd';
import ReportsStore from '../stores/ReportsStore';
import { observer } from 'mobx-react-lite';

type Props = {
    store: ReportsStore;
    form: FormInstance
};

const ReportEditForm: React.FC<Props> = ({ store, form }) => {
    React.useEffect(() => {
        if (store.selectedReport) {
            form.setFieldsValue(store.selectedReport);
        }
    }, [store.editReportDialogVisible, store.selectedReport, form]);

    const handleSubmit = () => {
        form.validateFields().then((formData) => {
            if (store.editDialogMode === 'create') {
                store.publishReport(formData);
            } else {
                store.updateReport(formData);
            }
            form.resetFields();
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <div className="dialog-section">
            <Form form={form} onFinish={handleSubmit} className="inputs-container" id="edit-published-report-form" layout="vertical">
                <Form.Item
                    name="title"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Title</span>}
                    rules={[{ required: true, message: 'Title is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="source"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Report Source</span>}
                    rules={[{ required: true, message: 'Report Source is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Description</span>}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="reportAccess"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Report Access</span>}
                    rules={[{ required: true, message: 'Report Access is required' }]}
                >
                    <Select
                        suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />}
                    >
                        {Object.keys(store.reportAccessOptions).map(x => <Select.Option value={x} key={x}>{store.reportAccessOptions[x]}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="projects"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Projects</span>}
                >
                    <Select
                        mode='multiple'
                        options={store.projects.map(p => ({ value: p.id, label: p.name }))}
                    />
                </Form.Item>
                <Form.Item
                    name="enabled"
                    valuePropName="checked"
                >
                    <Checkbox>
                        Is enabled
                    </Checkbox>
                </Form.Item>
            </Form>
        </div>
    );
};

export default observer(ReportEditForm);