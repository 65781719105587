import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import ActionDefinitionStore from '../stores/ActionDefinitionStore';
import ActionDefinitionForm from './ActionDefinitionForm';

type Props = {
    store: ActionDefinitionStore
};

const ActionDefinitionCreateEditDialog: React.FC<Props> = ({store}) => {
    const mode = store.editDialogMode;

    return (
        <Modal
            data-id-type="modal-window"
            data-id-name="edit-action-definition"
            className="alpha-modal"
            title={`${mode === 'create' ? 'Create new' : 'Edit' } action definition`}
            open={store.editDialogVisible}
            closable={false}
            centered
            width={1000}
            footer={[
                <Button 
                    data-id="button-edit-action-definition-cancel"
                    key="edit-action-definition-cancel" 
                    size="large"
                    className="light"
                    onClick={() => {
                        store.setNewDefinitionDialogVisible(false); 
                    }}
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="button-edit-action-definition-save"
                    type="primary"
                    key="edit-action-definition-save" 
                    size="large"
                    className="dark"
                    htmlType='submit'
                    form='edit-action-definition-form'
                >
                    Save
                </Button>
            ]}
        >
            <ActionDefinitionForm store={store}/>
        </Modal>
    );
};

export default observer(ActionDefinitionCreateEditDialog);