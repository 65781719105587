import { Menu, Dropdown, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AuthConsumer } from '../../authorization/AuthContext';
import { AppPermissions } from '../../authorization/Permissions';
import { hasPermission } from '../../authorization/components/HasPermission';
import DashboardStore from '../stores/DashboardStore';
import WidgetBase from '../models/WidgetBase';
import { ALL_PROJECTS } from 'src/modules/tasks/screens/TasksPage';

type Props = {
    dashboardStore: DashboardStore;
    widget: WidgetBase;
    openEditWidgetModal: (id: string) => void
};

const WidgetEditor: React.FC<Props> = ({ widget, dashboardStore, openEditWidgetModal}) => {

    const actionsMenu = (
        <AuthConsumer>
            {({ permissions, projectRoles }) => (
                <Menu>
                    <Menu.Item 
                        key="edit" 
                        onClick={() => openEditWidgetModal(widget.id)} 
                        disabled={!hasPermission(permissions, AppPermissions.CanAccessAdministration, projectRoles, widget.projectId || ALL_PROJECTS)}
                    >
                        <i className="alpha-icon edit-pen xs" />
                        <span>Edit</span> 
                    </Menu.Item>
                    <Menu.Item onClick={() => dashboardStore.deactivateWidget(widget.id)} key="remove">
                        <i className="alpha-icon danger-delete-icon xs" />
                        <span>Remove</span> 
                    </Menu.Item>
                </Menu>)}
        </AuthConsumer>
    );

    return (
        <Dropdown
            trigger={['click']}
            overlay={actionsMenu}
            overlayClassName="alpha-dropdown-overlay widget-editor"
        >
            <Button data-id="button-three-dots" className="dropdown-btn editor" type="ghost">
                <i className="alpha-icon xs action-more-dots"/>
            </Button>
        </Dropdown>
    );
};

export default observer(WidgetEditor);
