import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './styles/common.less';
import './styles/tasks.less';
import './styles/preview.less';
import './styles/dashboard.less';
import './styles/analysis.less';
import './styles/task-types.less';
import './styles/table.less';
import './styles/users.less';
import './styles/widgets-constructor.less';
import './styles/side-menu.less';
import './styles/filters.less';
import './styles/admin.less';
import './styles/notifications.less';

import 'react-quill/dist/quill.snow.css';

import { bootstrap } from './modules/common';
import MainLayout from './modules/common/components/MainLayout';

class App extends React.Component {
    componentDidMount() {
        if (process.env.REACT_APP_DISABLE_ANIMATIONS === 'true') {
            document.body.className = 'no-anims';
        }
    }

    render() {
        console.log(`Version: ${process.env.REACT_APP_VERSION}`);
        return (
            <bootstrap.StoreProvider>
                <BrowserRouter>    
                    <MainLayout />
                </BrowserRouter>    
            </bootstrap.StoreProvider>
        );
    }
}

export default App;
