import { gql } from 'apollo-boost';
import { execQuery } from '../../../modules/common/services/DataService';
import { UserModel, RoleModel, ProjectRole } from '../types/UserModel';
import appClient, { ResultApi } from '../../common/services/AppClient';
import { ResponseData } from '../../common/services/AppClient';
import { CustomRole } from '../types/CustomRoleModel';
import { MetadataDefinition } from '../types/Metadata';
import { DashboardWidgetMetaResult, DashboardWidgetsResult } from '../types/DashboardWidgetMeta';
import { ALL_PROJECTS } from 'src/modules/tasks/screens/TasksPage';


export class GlobalAdministrationService {
    async getRoles(): Promise<RoleModel[]> {
        let url = process.env.REACT_APP_TASKS_URL + 'users/roles';
        const resp = await appClient.get<RoleModel[]>(url);

        return resp.unwrapOr(Array<RoleModel>());

    }

    async getAppUsers(): Promise<UserModel[]> {
        let url = `${process.env.REACT_APP_TASKS_URL}users`;
        const resp = await appClient.get<UserModel[]>(url);
        return resp.unwrapOr(Array<UserModel>());
    }

    async createUpdateUser(userData: UserModel): Promise<ResponseData>  {
        const url = `${process.env.REACT_APP_TASKS_URL!}users`;
        return appClient.postRaw(url, userData);
    }

    async updateProjectAssignments(userId: string, assignments: ProjectRole[]): Promise<ResponseData>  {
        const url = `${process.env.REACT_APP_TASKS_URL!}admin/assignments/update/${userId}`;
        return appClient.patchRaw(url, assignments);
    }

    async updateDomainUser(userId: string, roles: string, groupId: string): Promise<ResponseData>  {
        const url = `${process.env.REACT_APP_TASKS_URL!}users/domainUser`;
        const data = {groupId, userId, roles};
        return appClient.postRaw(url, data);
    }
    
    async deleteUser(id: string, isDomainUser: boolean) {
        const url = `${process.env.REACT_APP_TASKS_URL!}users/${id}/${isDomainUser}`;
        return appClient.delete(url);
    }
    
    async resetPassword(password: string, userId?: string): Promise<ResponseData> {
        const data = {password: password, userId: userId};
        const url = process.env.REACT_APP_TASKS_URL + 'userProfile/password';
        return appClient.postRaw(url, data);
    }

    async changePassword(newPassword: string, oldPassword: string, userId?: string | null): Promise<ResponseData> {
        const data = {userId, oldPassword, newPassword};
        const url = process.env.REACT_APP_TASKS_URL + 'userProfile/password_change';
        return appClient.postRaw(url, data);
    }

    async changeObjectRoleAssignments(userId: string, objectId: string, objectType: string, roles: string[]): Promise<ResponseData> {
        const data = {userId, objectId, objectType, roles};
        const url = process.env.REACT_APP_TASKS_URL + 'users/object-role-assignments/change';
        return appClient.postRaw(url, data);
    }

    async getCustomRoles(): Promise<CustomRole[]> {
        const url = process.env.REACT_APP_TASKS_URL + 'customRolesManagement';
        var resp = await appClient.get<CustomRole[]>(url);
        return resp.unwrapOr(Array<CustomRole>());
    }

    async createCustomRole(name: string, permissions: string[]) {
        const data = {name, permissions};
        const url = process.env.REACT_APP_TASKS_URL + 'customRolesManagement';
        return appClient.postRaw(url, data);
    }

    async updateCustomRole(id: string, name: string, permissions: string[]) {
        const data = {id, name, permissions};
        const url = process.env.REACT_APP_TASKS_URL + 'customRolesManagement';
        return appClient.updateRaw(url, data);
    }

    async deleteCustomRole(id: string) {
        const url = process.env.REACT_APP_TASKS_URL + `customRolesManagement/${id}`;
        return appClient.deleteRaw(url);
    }
    
    async updateAlphaConfigValue(data: {}) {
        let url = `${process.env.REACT_APP_TASKS_URL}alpha-configs`;
        return appClient.post(url, {data});
    }

    async updateAlphaSettingsValue(key: string, value: unknown) {
        const data = {key, value};
        const url = `${process.env.REACT_APP_TASKS_URL}alpha-settings`;
        return appClient.post(url, data);
    }
    
    async deleteAlphaSettingsValue(key: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}alpha-settings/${key}`;
        return appClient.delete(url);
    }

    async getMetadataDefinitionsForProject(projectId?: string): Promise<ResultApi<MetadataDefinition[]>> {
        const queryParams = projectId ? `?projectId=${projectId}` : '';
        const url = `${process.env.REACT_APP_TASKS_URL}metadata${queryParams}`;
        return appClient.get<MetadataDefinition[]>(url);
    }

    createMetadataDefinition(formData: FormData) {
        const url = process.env.REACT_APP_TASKS_URL + 'metadata/create';
        return appClient.post<string>(url, formData);
    }

    deleteMetadataDefinition(metadataDefinitionId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `metadata/delete/${metadataDefinitionId}`;
        return appClient.delete(url);
    }

    updateMetadataDefinition(metadataDefinitionId: string, title: string, description: string | undefined, format: string, isVisible: boolean, taskTypes: string[]) {
        const request = {
            metadataDefinitionId,
            title,
            description,
            format,
            isVisible,
            taskTypes
        };
        const url = process.env.REACT_APP_TASKS_URL + `metadata/update/${metadataDefinitionId}`;
        return appClient.patch(url, request);
    }

    updateMetadataListOrder(id: string, dragIndex: number, dropIndex: number, lastModified: string | null) {
        const request = {
            dragIndex,
            dropIndex,
            lastModified
        };
        const url = process.env.REACT_APP_TASKS_URL + `metadata/position/update/${id}`;
        return appClient.patch(url, request);
    }

    createUpdateDashboardWidget(formData: {}) {
        const url = process.env.REACT_APP_TASKS_URL + 'admin/widget/create';
        return appClient.update<string>(url, formData);
    }   
    
    createUpdateDashboardGroupedWidget(formData: {}) {
        const url = process.env.REACT_APP_TASKS_URL + 'admin/grouped-widget/create';
        return appClient.update<string>(url, formData);
    }   

    async getDashboardWidgetsMeta(): Promise<DashboardWidgetMetaResult[] | null> {
        const result = await execQuery<DashboardWidgetsResult>({
            query: gql`query getDashboardWidgetsMeta {
                getDashboardWidgetsMeta {
                    id
                    name
                    projectId
                    widgetType
                    isDefaultWidget
                    ... on DashboardWidgetMeta {
                        outputField
                        filters {
                            fieldName
                            operator
                            value
                            extraFilters
                        }
                    }
                    ... on DashboardGroupedWidgetMeta {
                        widgets
                    }
                }
              }`,
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }
        const widgets =  result.data.getDashboardWidgetsMeta;
        for (const res of widgets) {
            res.projectId = res.projectId ?? ALL_PROJECTS;
        }
        return widgets;
    }

    deleteWidget(id: string) {
        const url = process.env.REACT_APP_TASKS_URL + `admin/widget/delete/${id}`;
        return appClient.delete(url);
    }
} 
