import { observable, action, computed, makeObservable } from 'mobx';
import DocumentViewService from '../services/DocumentViewService';
import { Page } from 'react-pdf';
import security from '../../common/services/SecurityService';

type FileType = 'pdf' | 'text' | 'excel' | 'docx' | 'xml' | 'image';

export class DocumentViewVisualStore {

    pageWidth: number | null = null;

    scale: number = 1;

    fileBlob: Blob | null = null;

    isLoading: boolean = false;

    xmlString: string = '';

    wordDocUrl: string;

    imageUrl: string;
    
    pageRefs: Page[] = [];

    constructor(private readonly service: DocumentViewService) {
        makeObservable(this, {
            pageWidth: observable,
            scale: observable,
            fileBlob: observable,
            isLoading: observable,
            file: computed,
            fileType: computed,
            setPageWidth: action,
            setScale: action,
            getDocument: action
        });

    }

    get file(): File | null {
        if (!this.fileBlob) {
            return null;
        }

        return new File([this.fileBlob], 'fileName', { type: this.fileBlob.type });
    }
    get fileType(): FileType | null {
        if (!this.file) {
            return null;
        }

        switch (this.file.type) {
        case 'application/octet-stream':
        case 'application/pdf':
            return 'pdf';
        case 'text/plain':
            return 'text';
        case 'text/xml':
        case 'application/xml':
            return 'xml';
        case 'application/vnd.ms-excel.sheet.macroenabled.12':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'excel';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'docx';
        case 'image/jpeg':
        case 'image/png':
            return 'image';

        default:
            return null;
        }
    }

    setPageWidth(width: number) {
        if (width === this.pageWidth) {
            return;
        }

        this.pageWidth = width;
    }

    setScale(scale: number) {
        this.scale = scale / 100;
    }

    async getDocument(path: string) {
        this.isLoading = true;

        try {
            const fileBlob = await this.service.getDocument(path);

            if (!fileBlob) {
                return;
            }

            this.fileBlob = fileBlob;
            if (fileBlob.type.endsWith('xml')) {
                this.xmlString = await fileBlob.text();
            } else if (fileBlob.type.endsWith('document')) {
                security.invoke((token) => {
                    let t = '?access_token=' + encodeURIComponent(token);
                    this.wordDocUrl = `${process.env.REACT_APP_TASKS_URL}document/${path}${t}`;
                    return Promise.resolve();
                });
            } else if (fileBlob.type.startsWith('image')) {
                security.invoke((token) => {
                    let t = '?access_token=' + encodeURIComponent(token);
                    this.imageUrl = `${process.env.REACT_APP_TASKS_URL}document/${path}${t}`;
                    return Promise.resolve();
                });
            }
        } finally {
            this.isLoading = false;
        }
    }
}
export default DocumentViewVisualStore;
