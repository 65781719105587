import { observer } from 'mobx-react-lite';
import React from 'react';
import ReportsList from '../components/ReportsList';
import { useReportsStore } from '../stores/context';

const ReportAdminPage: React.FC = () => {
    const repContext = useReportsStore();
    
    return (
        <ReportsList store={repContext.store} />
    );
};

export default observer(ReportAdminPage);