import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import ExcelDocumentPreview from '../components/ExcelDocumentPreview';
import PdfDocumentPreview from '../components/PdfDocumentPreview';
import XmlDocumentPreview from '../components/XmlDocumentPreview';

const DocumentViewerPage: React.FC = () => {
    let { documentId, documentName } = useParams();
    if (!documentId) {
        return null;
    }

    if (documentName?.endsWith('pdf')) {
        return (<PdfDocumentPreview  documentId={documentId} />);
    } else  if (documentName?.endsWith('xls') || documentName?.endsWith('xlsx')) {
        return (<ExcelDocumentPreview documentId={documentId} documentName={documentName!} />);
    }

    return <XmlDocumentPreview documentId={documentId}/>;
};

export default observer(DocumentViewerPage);