import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TaskViewVisualStore } from '../stores';
import { Button, Col, Dropdown, Menu, Skeleton } from 'antd';
import ActionInputDialog from './ActionInputDialog';

type Props = {
    store: TaskViewVisualStore
};

const TaskActionControl: React.FC<Props> = ({ store }) => {
    const handleExecuteActionClick = (actionId: string) => {
        const action = store.availableActions
            .find(x => x.id === actionId);
        const hasUserInputFields = action!.fields
            .some(x => x.fieldType === 'UserInput') || false;

        if (hasUserInputFields) {
            // Display form
            store.setSelectedAction(actionId);
        } else {
            // Execute action
            store.executeAction(store.taskPreview!.id, actionId, {}, action!.isClientSide)
                .catch(err => console.log(err));
        }
    };

    const actionsMenu = (
        <Menu>
            {
                store.availableActions.slice(2, store.availableActions.length).map(x =>
                    <Menu.Item style={{padding: 15}}  key={x.id} onClick={() => handleExecuteActionClick(x.id)}>
                        {x.title ?? x.name}
                    </Menu.Item>)
            }
        </Menu>
    );

    return (
        <>
            {store.selectedAction &&
                <ActionInputDialog
                    taskId={store.taskPreview!.id}
                    actionId={store.selectedAction}
                    store={store}
                />
            }
            {store.availableActionsLoading &&
                <Skeleton.Button active size="default" shape="square" />
            }
            {
                store.availableActions.slice(0, 2).map(a=> 
                    (
                        <Col key={a.id}>
                            <Button onClick={() => handleExecuteActionClick(a.id)}>{a.title ?? a.name}</Button>
                        </Col>
                    )
                )
            }
            {store.availableActions.length > 2 && 
                <Col>
                    <Dropdown overlay={actionsMenu} trigger={['click']} overlayClassName="alpha-dropdown-overlay action-menu">
                        <Button>...</Button>
                    </Dropdown>
                </Col>
            }
        </>
    );
};

export default observer(TaskActionControl);