import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTasksStore } from '../stores/context';
import { TaskList, TaskListHeaderControls, TaskPreviewDialog, 
    TaskCreateDialog, TasksAssignDialog, TaskSendViaEmailDialog } from '../components';
import { useStore } from '../../common/stores';
import { TaskListModel } from '../types';
import { useParams } from 'react-router';
import TabsContentContainer from '../../common/components/TabsContentContainer';
import { TaskService } from '../services';
import { TaskViewVisualStore } from '../stores';
import { LoadingIndicator } from '../../common/components/LoadingIndicator';
import ClientActionRunner from '../../administration/misc/ClientActionHandler';
import { AuthConsumer } from '../../authorization/AuthContext';

type Props = {
    isSideMenuCollapsed: boolean
};

export const ALL_PROJECTS = 'all_projects';
const taskService = new TaskService();
const TasksPage: React.FC<Props> = ({isSideMenuCollapsed}) => {
    const context = useTasksStore();
    const rootContext = useStore();

    const { taskId, widgetId, activityType } = useParams();
    const {userProfileStore} = useStore();
    const {tabsStore, errorStore, notificationStore} = rootContext;
    const projectsStore = rootContext.projectsStore;
    const templateStore = rootContext.templateStore;
    const taskViewVisualStore = context.taskPreviewVisualStore;
    const tasksGridVisualStore = context.tasksGridVisualStore;
    const tasksFilterVisualStore = context.tasksFiltersVisualStore;
    const tasksRootStore = context.tasksRootStore;
    React.useEffect(() => {
        const openTaskFullView = async() => {
            const isTaskAccessible = (task: TaskListModel) => {
                const currentUser = userProfileStore.userProfile?.userId;
                return (
                    (task.accessType === 'LimitedAccess' && (task.sharedWith?.includes(currentUser || '') || task.createdBy === currentUser)) ||
                    (task.accessType === 'OnlyMe' && task.createdBy === currentUser) || 
                    task.accessType === 'Everyone' || 
                    !task.accessType);
            };
            let tsk = tasksGridVisualStore.tasks.find(t => t.id === taskId) || null;
            if (!tsk && taskId) {
                const resp = await taskViewVisualStore.getTaskById(taskId);
                resp.map(taskById => {
                    tsk = taskById;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }).mapErr((err: any) => {
                    errorStore.addError(err.data);
                    tasksRootStore.setPageIsLoading(false);
                });
            }
            if (tsk && isTaskAccessible(tsk)) {
                // TODO: Move this logics in tabsStore separate mehod (e.g. tryAddTaskStore)
                if (!tabsStore.taskStores[taskId!]) {
                    const actionRunner = new ClientActionRunner();
                    const store = new TaskViewVisualStore(
                        taskService, tabsStore, errorStore,
                        userProfileStore, notificationStore,
                        tasksRootStore, actionRunner
                    );
                    tabsStore.addTaskStore(store, taskId!);
                }
                tabsStore.taskStores[taskId!].expandTask(tsk);
                tasksRootStore.setPageIsLoading(false);
            }
        };
        if (taskId && userProfileStore.userProfile) {
            openTaskFullView();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [taskId, widgetId, activityType, userProfileStore.userProfile]);

    if (taskId && tabsStore.taskStores[taskId]?.taskPreview) {
        return (
            <>
                <TabsContentContainer/>
                <TaskCreateDialog store={context.taskCreateVisualStore} templateStore={templateStore}/>
            </>);
    }

    if (taskId && tasksRootStore.pageIsLoading) {
        return <LoadingIndicator/>;
    }


    return (
        <div style={{userSelect: 'none'}}>
            <TaskSendViaEmailDialog 
                store={taskViewVisualStore}
                visible={taskViewVisualStore.emailTaskToUserDialogVisible}
                setVisible={taskViewVisualStore.setEmailTaskToUserDialogVisible}
                isSending={taskViewVisualStore.sendingEmailToUsers}
                sendEmails={taskViewVisualStore.emailTaskToUsers}
                taskId={taskViewVisualStore.taskPreview ? taskViewVisualStore.taskPreview.id : undefined}
            />
            <TasksAssignDialog store={tasksGridVisualStore} />
            <TaskCreateDialog store={context.taskCreateVisualStore} templateStore={templateStore} />
            <TaskPreviewDialog store={taskViewVisualStore} commentStore={context.taskCommentsStore}/>
            <div className="tasks-content-header">
                <TaskListHeaderControls 
                    store={tasksGridVisualStore} 
                    filterStore={tasksFilterVisualStore} 
                    createStore={context.taskCreateVisualStore} 
                    projectsStore={projectsStore}
                />
            </div>
            <div 
                className='tasks-content-container'
                style={{
                    width: taskViewVisualStore.showPreviewDialog ? 
                        `calc(100vw - ${taskViewVisualStore.taskDrawerWidth + (isSideMenuCollapsed ? 135 : 330) }px)` : '100%',
                    transition: '.3s'
                }}
            >
                <AuthConsumer>
                    {({permissions}) =>
                        <TaskList 
                            store={tasksGridVisualStore} 
                            taskPreviewStore={taskViewVisualStore}
                            tasksFiltersStore={tasksFilterVisualStore}
                            userPermissions={permissions}
                        />
                    }
                </AuthConsumer> 
            </div>
        </div>
    );
};

export default observer(TasksPage);