import { observer } from 'mobx-react-lite';
import React from 'react';
import UserManager from '../components/UserManager';
import { useAdminStore } from '../stores/context';

const UsersManagementPage: React.FC = () => {
    const context = useAdminStore();

    return (
        <UserManager store={context.usersManagerStore} />
    );
};

export default observer(UsersManagementPage);