import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import ActionDefinitionStore from '../stores/ActionDefinitionStore';
import { ActionField } from '../types/Actions';
import ActionFieldInputSetupDialog from './ActionFieldInputSetupDialog';
import ActionFieldEditor from './ActionFieldEditor';

const FormItem = Form.Item;

type Props = {
    store: ActionDefinitionStore
};

const ActionDefinitionForm: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    const mode = store.editDialogMode;
    const [isTypeSet, setIsTypeSet] = useState<boolean>(false);

    React.useEffect(() => {
        if (!store.editDialogVisible) {
            form.resetFields();
        }
    }, [store.editDialogVisible, form]);

    React.useEffect(() => {
        if (store.selectedDefinition) {
            form.setFieldsValue(store.selectedDefinition);
            setIsTypeSet(store.selectedDefinition.actionType !== undefined && store.selectedDefinition.actionType.length > 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedDefinition?.id, store.editDialogVisible]);

    const handleSubmit = () => {
        form.validateFields().then(() => {
            let formData = form.getFieldsValue();

            if (mode === 'create') {
                store.createActionDefinition(formData);
            } else {
                store.updateActionDefinition(formData.actionType, formData.title, formData.fields);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleActionTypeChange = (newValue: string) => {
        let actionType = store.actionTypes.find(x => x.name === newValue);

        if (actionType) {
            setIsTypeSet(true);
            let currentFields: ActionField[] = form.getFieldValue('fields') || [];
            let requiredFields = actionType?.fields;
            currentFields = currentFields.filter(f => !f.isPredefined);

            if (requiredFields && requiredFields.length > 0) {
                let missing = requiredFields?.filter(x => currentFields?.find((c: ActionField) => c.name === x.name) === undefined)
                    .map(x => ({ ...x, isPredefined: true }));

                if (missing && missing.length > 0) {
                    form.setFieldValue('fields', [...missing, ...currentFields]);
                }
            }
        } else {
            setIsTypeSet(false);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionNameValidator = (rule: any, value: any, callback: any) => { 
        let actions = store!.actionDefinitions.map(a => a.name.toLowerCase());
        if (value && actions.includes(value.toLowerCase()) && store.selectedDefinition?.name !== value) {
            callback('Action with the same name already exists');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionTitleValidator = (rule: any, value: any, callback: any) => { 
        let actions = store!.actionDefinitions.map(a => a.title?.toLowerCase());
        if (value && actions.includes(value.toLowerCase()) && store.selectedDefinition?.title !== value) {
            callback('Action with the same title already exists');
        } else {
            callback();
        }
    };
    

    return (
        <div className="dialog-section">
            <Form form={form} onFinish={handleSubmit} className="alpha-form" id="edit-action-definition-form" layout='vertical'>
                <FormItem name="projectId" noStyle><Input type="hidden" /></FormItem>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name="name"
                            colon={false}
                            className="dialog-field"
                            label={<span className="dialog-field-label">Name</span>}
                            rules={[
                                { required: true, message: 'Name is required' },
                                { validator: actionNameValidator }
                            ]}
                        >
                            <Input disabled={mode === 'edit'} />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="title"
                            colon={false}
                            className="dialog-field"
                            label={<span className="dialog-field-label">Title</span>}
                            rules={[{ validator: actionTitleValidator }]}
                        >
                            <Input />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="actionType"
                            colon={false}
                            className="dialog-field"
                            label={<span className="dialog-field-label">Action type</span>}
                            rules={[{ required: true, message: 'Action type is required' }]}
                        >
                            <Select
                                suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />}
                                onChange={handleActionTypeChange}
                            >
                                {store.actionTypes.map(at => <Select.Option value={at.name} key={at.name}>{at.title}</Select.Option>)}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                {isTypeSet ? (
                    <>
                        <Divider orientation='left'>Action fields</Divider>
                        <Form.List name="fields">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }) => (
                                        <ActionFieldEditor key={key} store={store} index={name} form={form} remove={remove}/>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add({ isPredefined: false })} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </>
                ) : null}
            </Form>
            <ActionFieldInputSetupDialog store={store} />
        </div>
    );
};

export default observer(ActionDefinitionForm);