/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Table, Layout, Tooltip, Popconfirm, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
// import AddProjectDialog from './ProjectCreateDialog';
import { LoadingIndicator } from '../../common/components/LoadingIndicator';
import { PublishedReport, ReportAccess } from '../types';
import ReportsStore from '../stores/ReportsStore';
import ReportEditDialog from './ReportEditDialog';

const { Content } = Layout;
type Props = {
    store: ReportsStore
};

const ReportsList: React.FC<Props> = ({store}) => {
    // const [isProjectDialogVisible, setIsProjectDialogVisible] = React.useState(false);
   
    const columns: ColumnProps<PublishedReport>[] = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (value: string) => (
                <span data-id-cells="name">{value}</span>
            )
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            render: (value: string) => (
                <span data-id-cells="name">{value}</span>
            )
        },
        {
            title: 'Projects',
            dataIndex: 'projects',
            key: 'projects',
            render: (value: string[]) => (
                <span data-id-cells="name">{value.map(v=>store.getProjectName(v)).join(', ')}</span>
            )
        },
        {
            title: 'Access',
            dataIndex: 'reportAccess',
            key: 'reportAccess',
            render: (value: ReportAccess) => (
                <span data-id-cells="name">{value}</span>
            )
        },
        {
            title: 'Actions',
            width: 200,
            render: (record: PublishedReport) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit report"
                        title="Edit"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.setSelectedReport(record);
                        }}
                    >
                        <Tooltip title="Edit report" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this report?"
                        onConfirm={() => {
                            store.deleteReport(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button data-id-cells="Delete report" title="Delete" size="small" type="link">
                            <Tooltip title="Delete report" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                    <Switch   
                        data-id-cells="is-active"
                        data-id-value={record.enabled}
                        style={{visibility: 'visible', marginBottom: 18, marginLeft: 14}} 
                        checked={record.enabled} onChange={() => {
                            store.toggleReportAvailability(record.id);
                        }} 
                    />
                </div>
            )
        }
    ];
    if (store.reportsLoading) {
        return <LoadingIndicator/>; 
    }
    return (
        <Layout className="screen-size tasks-types-layout layout-with-table">
            <div className='header-wrapper'>
                <div className='title-wrapper'>
                    <div className="header-title">Published Reports</div>
                </div>
                <Button data-id="button-add-type" type="primary" size="large" onClick={() => {
                    store.setNewReportDialogVisibility(true);
                }}>Publish report</Button>
            </div>
            <ReportEditDialog store={store} />
            <Layout style={{overflow: 'auto'}}>
                <Content>
                    <Table
                        data-id="table-tasks-reports-list"
                        className="alpha-table"
                        columns={columns}
                        dataSource={store.reports}
                        rowKey={(r) => r.id}
                        pagination={false}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(ReportsList);