import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input, Modal, Select } from 'antd';
import { TaskViewVisualStore } from '../stores';
import { TaskRelationships } from '../types';

type Props = {
    taskId: string;
    store: TaskViewVisualStore
};

const TaskLinkDialog: React.FC<Props> = ({ taskId, store }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.selectedLink) {
            const link = store.taskLinks.find(x => x.id == store.selectedLink);
            if (link) {
                const rel = TaskRelationships.getRelationInfo(link, taskId);
                store.setInitialLookup(link);

                const values = {
                    taskId: taskId,
                    linkType: rel?.value,
                    linkedTaskId: taskId === link!.taskId ? link!.linkedTaskId : link!.taskId,
                };

                form.setFieldsValue(values);
            }
        } else if (store.selectedLink === null) {
            form.setFieldsValue({ taskId: taskId, linkType: 'relation' });
        }
    }, [store, form, store.selectedLink, taskId]);

    const handleSubmit = () => {
        form.validateFields().then(values => {
            const rel = TaskRelationships.getOption(values.linkType);
            const payload = rel?.inverse ? { ...values, taskId: values.linkedTaskId, linkedTaskId: values.taskId, linkType: rel.type } : { ...values, linkType: rel?.type };
            store.createTaskLink(payload);
            handleClose();
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleClose = () => {
        form.resetFields();
        store.clearTaskLookups();
        store.setSelectedLink(undefined);
    };

    return (
        <Modal
            title='Link task'
            open={store.selectedLink !== undefined}
            className="alpha-modal"
            closable={false}
            footer={[
                <Button
                    data-id="link-task-cancel-button"
                    key="exlink-task-cancel-button"
                    className="light"
                    onClick={handleClose}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="link-task-button"
                    key="link-task-button"
                    type="primary"
                    htmlType="submit"
                    form="link-task-form"
                >
                    {store.selectedLink ? 'Update' : 'Link'}
                </Button>
            ]}
        >
            <Form form={form} onFinish={handleSubmit} className='alpha-form' layout='vertical' id='link-task-form'>
                <Form.Item name="taskId" noStyle><Input type="hidden" /></Form.Item>
                <Form.Item
                    name="linkType"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Relationship</span>}
                    rules={[{ required: true, message: 'Relationship type is required' }]}
                >
                    <Select options={TaskRelationships.options} />
                </Form.Item>
                <Form.Item
                    name="linkedTaskId"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Task</span>}
                    rules={[{ required: true, message: 'Task field is required' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select linked task"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={store.lookupTasks}
                        onClear={store.clearTaskLookups}
                        options={store.taskLookups.map(l => ({
                            value: l.id,
                            label: `${l.alphaId ? `${l.alphaId.prefix}-${l.alphaId.index} - `  : ''}${l.name}`
                        })
                        )}
                        allowClear
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(TaskLinkDialog);