import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Utils } from '../../common/misc/Utils';
import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';

type Props = {
    fileName: string;
    fileSizeBytes: number;
    className?: string;
    attachmentId?: string | undefined;
    onClick?: () => void;
    onRemove?: () => void;
    onPreview?: (attachmentId: string | undefined) => void;
    uploadDate?: string
};

const TaskAttachment: React.FC<Props> = ({fileName, fileSizeBytes, onRemove, onClick, onPreview, className = '', attachmentId = undefined, uploadDate = ''}) => {
    const generateDocumentIcon = (documentName: string) => {
        if (!documentName) {
            return <FileOutlined className="attachment-icon" />;
        }
        
        let extension = documentName.split('.').pop();

        switch (extension) {
        case 'pdf':
            return (<i className={'alpha-icon attachment-icon doc-pdf'} />);
        case 'xls':
        case 'xlsx':
        case 'xltm':
        case 'xlsm':
            return (<i className={'alpha-icon attachment-icon doc-xls'} />);
        case 'doc':
        case 'docx':
            return (<i className={'alpha-icon attachment-icon doc-word'} />);
        default:
            return <FileOutlined className="attachment-icon" />;
        }
    };

    const removeFile = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (onRemove) {
            onRemove();
        }
    };

    const handleDoubleClick = () => {
        if (!onPreview) {
            return; 
        }
        let extension = fileName.split('.').pop();

        switch (extension) {
        case 'pdf':
        case 'txt':
            onPreview(attachmentId);
            break;
        default:
            break;
        }
    };

    return (
        <Tooltip title={fileName} overlayStyle={{wordBreak: 'break-all'}}>
            <div
                className={`attachment ${className}`}
                onClick={onClick}
                onDoubleClick={handleDoubleClick}
            >
                {onRemove && (
                    <span className="attachment-remove-button">
                        <Button shape="circle" type="link" size="small" icon={<CloseOutlined />} style={{color: '#273C75'}} onClick={removeFile}/>
                    </span>
                )}
                {generateDocumentIcon(fileName)}
                <div className="attachment-name">
                    {fileName}
                </div>
                <div className="attachment-size">
                    {uploadDate ? `${Utils.formatDateStringShort(uploadDate)}, ` : null}{Utils.readableFileSize(fileSizeBytes, true)}
                </div>
            </div>
        </Tooltip>
    );
};

export default observer(TaskAttachment);