/* eslint-disable @typescript-eslint/no-explicit-any */
import { observable, action, runInAction, reaction, computed, makeObservable } from 'mobx';
import { UserProfileStore } from '.';
import { Notification } from '../../common/types/Notification';
import ErrorStore from './ErrorStore';
import { NotificationsService } from '../services';

type SessionQueueItem = {
    sessionId: string;
    applicationName: string;
    packageName: string
};

export default class NotificationStore {
    sessionsUpdateQueue: SessionQueueItem[] = [];

    notifications: Notification[] = [];

    constructor(
        private errorStore: ErrorStore, 
        private notificationsService: NotificationsService,
        private userProfileStore: UserProfileStore) {
        makeObservable<NotificationStore, 'currentUserId' | 'readQueueFromSessionStorage'>(this, {
            sessionsUpdateQueue: observable,
            notifications: observable,
            unreadNotificationsCount: computed,
            currentUserId: computed,
            addNotification: action.bound,
            addSessionNotification: action.bound,
            loadNotificationsForUser: action.bound,
            deleteNotification: action.bound,
            markNotificationAsRead: action.bound,
            markAllNotificationsAsRead: action.bound,
            readQueueFromSessionStorage: action.bound,
        });

        this.readQueueFromSessionStorage();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }

    get unreadNotificationsCount() {
        return this.notifications ? this.notifications.filter(n => !n.isRead).length : 0;
    }

    private get currentUserId() {
        return this.userProfileStore.currentUserProfileInfo?.userId;
    }

    addNotification(newNotification: Notification) {
        if (newNotification.userId === this.currentUserId && !this.notifications.some(n=> n.id === newNotification.id)) {
            let newList = this.notifications.slice();
            newList.unshift(newNotification);
            this.notifications = newList;
        }
    }

    addSessionNotification(sessionId: string, applicationName: string, packageName: string) {
        this.sessionsUpdateQueue.push({
            sessionId,
            applicationName,
            packageName
        });
        sessionStorage.setItem('sessionQueue', JSON.stringify(this.sessionsUpdateQueue));
    }

    async loadNotificationsForUser() {        
        if (this.currentUserId) {
            var result = await this.notificationsService.getNotificationsForUser(this.currentUserId);
            runInAction(() => {                
                this.notifications = result;
            });
        } else {
            reaction(() => this.currentUserId, (userId, prev, r) => {
                if (this.currentUserId != null) {
                    this.loadNotificationsForUser();
                    r.dispose();
                }
            });
        }
    }

    async deleteNotification(notificationId: string) {
        var response = await this.notificationsService.deleteNotification(notificationId);
        response.map(() => {
            runInAction(() => {
                this.notifications = this.notifications.filter(n => n.id !== notificationId);
            });
        }).mapErr(err => this.errorStore.addError(err.data));
    }

    async markNotificationAsRead(notificationId: string) {
        var response = await this.notificationsService.markNotificationAsRead(notificationId);
        response.map(() => {
            runInAction(() => {
                const notificationToUpdate = this.notifications.find(n => n.id === notificationId);
                if (notificationToUpdate) {
                    const index = this.notifications.indexOf(notificationToUpdate);
                    let newList = this.notifications.slice();
                    newList[index].isRead = true;
                    this.notifications = newList;
                }
            });
        }).mapErr(err => this.errorStore.addError(err.data));
    }

    async markAllNotificationsAsRead() {
        var response = await this.notificationsService.markAllAsRead();
        response.map(() => {
            runInAction(() => {
                let newList = this.notifications.slice();
                newList.forEach(n => n.isRead = true);
                this.notifications = newList;
            });
        }).mapErr(err => this.errorStore.addError(err.data));
    }

    private readQueueFromSessionStorage() {
        var storedQueue = sessionStorage.getItem('sessionQueue');
        if (storedQueue) {
            this.sessionsUpdateQueue = JSON.parse(storedQueue);
        }
    }
}