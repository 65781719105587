import * as React from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react-lite';


type Props = {
    title: string;
    onCloseCallback: () => void;
    icons: React.ReactElement[]
};

const ModalHeader: React.FC<Props> = ({title, onCloseCallback, icons}) => {
    return (
        <Row className='modal-header'>
            <Col>
                <div>{title}</div>
            </Col>
            <Col>
                <div className='icons-container'>
                    {icons}
                    <i className='alpha-icon xs cross' onClick={onCloseCallback}/>
                </div>
            </Col>
        </Row>
    );
};


export default observer(ModalHeader);