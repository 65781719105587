import { Button, Form, Modal } from 'antd';
import * as React from 'react';
import ReportsStore from '../stores/ReportsStore';
import { observer } from 'mobx-react-lite';
import ReportEditForm from './ReportEditForm';

type Props = {
    store: ReportsStore
};

const ReportEditDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    return (
        <Modal
            centered
            width={600}
            title={store.editDialogMode === 'create' ? 'Publish report' : 'Edit report'}
            destroyOnClose
            closable={false}
            maskClosable={false}
            open={store.editReportDialogVisible}
            className="alpha-modal"
            footer={[
                <Button
                    data-id="add-report-cancel"
                    key="add-report-cancel"
                    onClick={() => {
                        form.resetFields();
                        store.setNewReportDialogVisibility(false);
                    }}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="add-report-submit"
                    key="add-report-submit"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    form="edit-published-report-form"
                >
                    {store.editDialogMode === 'create' ? 'Publish' : 'Save'}
                </Button>
            ]}
        >
            <ReportEditForm store={store} form={form} />
        </Modal>
    );
};

export default observer(ReportEditDialog);