import { Button, Form, Input, message, Modal, Select, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import { UploadChangeParam } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TaskViewVisualStore } from '../stores';

type Props = {
    taskId: string;
    projectId: string;
    store: TaskViewVisualStore;
    isVisible: boolean;
    closeDialog: () => void;
    afterCallback?: () => void
};

const Dragger = Upload.Dragger;

const QueryDialog: React.FC<Props> = ({ isVisible, projectId, closeDialog, store, taskId, afterCallback }) => {
    const [form] = Form.useForm();    

    const handleDialogClose = () => {
        form.resetFields();
        closeDialog();
    };

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status !== 'uploading') {                
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {               
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

    };

    const handleSubmit = () => {
        form.validateFields().then(async(vals) => {
            const resp = await store.queryMessage(taskId, {...vals, fileData: vals.upload?.file?.response || [] });
            if (resp.isOk()){
                message.success('Query has been successfully reported');
                handleDialogClose();
                if (afterCallback) {
                    afterCallback();
                }
            } else {
                message.error('There was a problem sending a query');
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <Modal
            title='Query'
            open={isVisible}
            className="alpha-modal comment-in-modal"
            closable={false}
            footer={[
                <Button 
                    data-id="query-cancel-button"
                    key="query-cancel-button" 
                    className="light"
                    onClick={handleDialogClose}
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="query-save-button"
                    key="query-save-button" 
                    type="primary"
                    htmlType="submit"
                    form="query-modal-form"
                >
                    Report Query
                </Button>
            ]}
        >
            <Form form={form} onFinish={handleSubmit} className="alpha-form" layout='vertical' id="query-modal-form">
                <FormItem
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Query Details (Maximum character limit is 350)</span>}
                    name="details"
                    rules={[{ required: true, message: 'Details are required' }]}

                >
                    <TextArea maxLength={350}/>
                </FormItem>
                <div style={{fontSize: 16}}>Contact details</div>
                <FormItem
                    colon={false}
                    label={<span className="dialog-field-label">Name</span>}
                    name="name"
                    rules={[{ required: true, message: 'Name is required' }]}

                >
                    <Input/>
                </FormItem>
                <FormItem
                    colon={false}
                    label={<span className="dialog-field-label">Phone Type</span>}
                    name="phoneType"
                    initialValue={'Phone'}
                >
                    <Select options={[{value: 'Phone', label: 'Phone'}, {value: 'Fax', label: 'Fax'}]}/>
                </FormItem>
                <FormItem
                    colon={false}
                    label={<span className="dialog-field-label">Phone</span>}
                    name="phone"
                    rules={[{ required: true, message: 'Phone is required' }]}
                >
                    <Input/>
                </FormItem>
                <FormItem
                    colon={false}
                    label={<span className="dialog-field-label">Email</span>}
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }]}
                >
                    <Input/>
                </FormItem>
                <Form.Item
                    name="upload"
                    label={<span className="dialog-field-label">Upload</span>}
                >
                    <Dragger
                        className="upload-dragger"
                        name="files"
                        onChange={onChange}
                        multiple
                        style={{ height: '30%' }}
                        action={`${process.env.REACT_APP_TASKS_URL}file-upload/multiple/project/${projectId}`}
                    >
                        <p className="ant-upload-hint">
                            <i className="alpha-icon md upload" style={{verticalAlign: 'middle'}}/>
                            Drag and drop file here to upload...
                        </p>
                    </Dragger>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(QueryDialog);
