import * as React from 'react';
import { Utils } from '../../common/misc/Utils';
import { observer } from 'mobx-react-lite';
import { TaskActivity } from '../types';
import { TaskViewVisualStore } from '../stores';
import { TaskUserProfilePicture } from '.';

type Props = {
    activity: TaskActivity;
    userName: string;
    store: TaskViewVisualStore
};

const TaskActivityEntry: React.FC<Props> = ({ activity, userName, store }) => {
    return (
        <div className="task-activity-entry">
            <div className="activity-entry-content">
                <TaskUserProfilePicture 
                    store={store}
                    userId={activity.doneBy}
                    userName={userName}
                    className="author-avatar"
                    size={24}
                />
                <div>
                    <span className="user-name">{userName}</span>
                    <span className="activity-message">{
                        activity.activityType === 'CommentUpdate' || activity.activityType === 'CommentDelete' || activity.message.startsWith('updated Description')
                            ? (<span dangerouslySetInnerHTML={{ __html: activity.message }}/>)
                            : activity.message
                    }</span>
                    <span className="activity-date"> - {Utils.formatDateStringLong(activity.createdTime)}</span>
                </div>
            </div>      
        </div>
    );
};

export default observer(TaskActivityEntry);