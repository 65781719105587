import { gql } from 'apollo-boost';
import { Notification } from '../types/Notification';
import { execQuery } from './DataService';
import { NotificationsResult } from './types';
import appClient from './AppClient';

export default class NotificationsService {
    async getNotificationsForUser(userId: string): Promise<Notification[]> {
        const result = await execQuery<NotificationsResult>({
            query: gql`query getNotifications($userId:String!) {
                  getNotifications(userId: $userId) {
                    id,
                    userId,
                    notificationType,
                    createdDate,
                    updatedDate,
                    text,
                    isRead,
                    relatedEntityId
                  }
            }`,
            variables: {
                userId
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            throw result.errors[0];
        }

        return result.data.getNotifications || [];
    }


    async markNotificationAsRead(notificationId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `notifications/mark-as-read/${notificationId}`;
        return appClient.update(url);
    }

    async deleteNotification(notificationId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `notifications/${notificationId}`;
        return appClient.post(url);
    }

    async markAllAsRead() {
        const url = process.env.REACT_APP_TASKS_URL + 'notifications/mark-all-as-read';
        return appClient.update(url);
    }
}