import { DashboardWidgetType } from 'src/modules/administration/types/DashboardWidget';
import WidgetBase from './WidgetBase';

type WidgetTaskItem = {
    taskId: string;
    taskTitle: string;
    isSubtask: boolean
};

export default class ListWidget extends WidgetBase {
    tasks: WidgetTaskItem[];

    totalTasksCount: number;

    constructor(id: string, name: string, type: DashboardWidgetType, tasks: WidgetTaskItem[], projectId: string, isDefaultWidget: boolean,  totalTasksCount: number) {
        super(id, type, name, projectId, isDefaultWidget);
        this.tasks = tasks;
        this.totalTasksCount = totalTasksCount;
    }
}