import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TaskViewVisualStore } from '../stores';
import { Modal, Button, Select } from 'antd';

type Props = {
    store: TaskViewVisualStore;
    visible: boolean;
    isSending: boolean;
    setVisible: (visible: boolean) => void;
    sendEmails: (taskId: string, emailAddresses: string[]) => Promise<void>;
    taskId?: string
};

const TaskSendViaEmailDialog: React.FC<Props> = ({visible, isSending, setVisible, sendEmails, taskId}) => {
    const [emails, setEmails] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!visible) {
            setEmails([]);
        }
    },              [visible]);
    
    if (!taskId) {
        return null; 
    }

    return(
        <Modal
            open={visible}
            title="Send task via email"
            destroyOnClose
            maskClosable={false}
            className="alpha-modal"
            onCancel={() => setVisible(false)}
            footer={[
                (<Button key="email-task-cancel" onClick={() => setVisible(false)}>
                    Cancel
                </Button>),
                (<Button 
                    type="primary"
                    key="email-task-submit"
                    disabled={isSending || !emails.length}
                    loading={isSending}
                    onClick={() => sendEmails(taskId, emails)}
                >
                    Send
                </Button>)
            ]}
        >
            <Select 
                mode="tags" 
                value={emails} 
                onChange={(val: unknown ) => {
                    setEmails(val as string[]); 
                }} 
                style={{width: '100%'}} 
                tokenSeparators={[';']} 
                placeholder='Emails'
            />
        </Modal>
    );
};

export default observer(TaskSendViaEmailDialog);