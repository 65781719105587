import * as React from 'react';
import { Route } from 'react-router';
import ReportPage from '../screens/ReportPage';
import { AppPermissions } from '../../authorization/Permissions';
import { hasPermission } from '../../authorization/components/HasPermission';
import ReportAdminPage from '../screens/ReportAdminPage';
import * as reportsContext from '../stores/context';

const reportsRoute = (permissions: string[]) => {
    return (
        <>
            {hasPermission(permissions, AppPermissions.CanAccessAdministration) &&
                <Route
                    path={'/admin/reports'}
                    element={
                        <reportsContext.StoreProvider>
                            <ReportAdminPage />
                        </reportsContext.StoreProvider>
                    }
                />
            }
            <Route
                path={'/reports'}
                element={
                    <reportsContext.StoreProvider>
                        <ReportPage />
                    </reportsContext.StoreProvider>
                }
            />
        </>
    );
};

export default reportsRoute;