import * as React from 'react';
import { observer } from 'mobx-react-lite';
import DashboardStore from '../stores/DashboardStore';
import GroupedWidget from '../models/GroupedWidget';
import { ALL_PROJECTS } from '../../tasks/screens/TasksPage';
import WidgetEditor from './WidgetEditor';
import { Tooltip } from 'antd';

type Props = {
    dashboardStore: DashboardStore;
    widget: GroupedWidget;
    openEditWidgetModal: (id: string) => void
};

const GroupedWidgetsCard: React.FC<Props> = ({widget, dashboardStore, openEditWidgetModal}) => {

    const handleWidgetClick = (widgetId: string) => {
        const url = `${window.location.href}/../tasks/project/${widget.projectId || ALL_PROJECTS}/widget/${widgetId}`;
        window.open(url, '_blank')!.focus();
    };

    return (
        <div className='card'>
            <div className='section-title'>{widget.name}</div>
            {!widget.isDefaultWidget && <WidgetEditor dashboardStore={dashboardStore} widget={widget} openEditWidgetModal={openEditWidgetModal}/>}
            <div className='grouped-card-container'>
                {widget.widgets.length ? widget.widgets.map((w, i) => 
                    <div className='grouped-widget-container' key={i} onClick={() => handleWidgetClick(w.widgetId)}>
                        <Tooltip title={w.widgetName}>
                            <span>{w.widgetName}</span>
                        </Tooltip>
                        <div className='progress-indicator' style={{borderLeft: `${w.percentage * 2.5}px solid #4183D7`}}/> 
                        <div className='pecentage'>{`${w.percentage}%`}</div>   
                    </div>
                ): <div className='empty-card-title'>There are no tasks yet</div>}
            </div>
        </div>
    );

};

export default observer(GroupedWidgetsCard);
