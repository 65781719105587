import * as React from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { TaskService } from '../services';
import { ErrorStore, useStore, ProjectsStore, TabsStore, NotificationStore, UserProfileStore } from '../../common/stores';
import TaskCommentsStore from './TaskCommentsStore';
import { GlobalAdministrationService } from '../../administration/services/GlobalAdministrationService';
import TasksRootStore from './TasksRootStore';
import { ActionService } from '../../administration/services/ActionService';
import TaskTypesService from 'src/modules/task_types/services/TaskTypesService';

function createStore(
    errorStore: ErrorStore, 
    projectStore: ProjectsStore, 
    tabsStore: TabsStore, 
    notificationStore: NotificationStore,
    userProfileStore: UserProfileStore) {
    const taskService = new TaskService();
    const taskTypesService = new TaskTypesService();

    const adminService = new GlobalAdministrationService();
    const actionService = new ActionService();
    const tasksRootStore = new TasksRootStore(taskService, taskTypesService, projectStore, tabsStore, errorStore, adminService, actionService, userProfileStore, notificationStore);
    const taskCommentsStore = new TaskCommentsStore(taskService, errorStore, tasksRootStore.tasksGlobalViewVisualStore);
    return {
        taskPreviewVisualStore: tasksRootStore.tasksGlobalViewVisualStore,
        taskCreateVisualStore: tasksRootStore.taskCreateVisualStore, 
        taskCommentsStore,
        tasksFiltersVisualStore: tasksRootStore.tasksFiltersVisualStore,
        tasksRootStore,
        tasksGridVisualStore: tasksRootStore.tasksGridVisualStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode
};

export const TasksStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalObservable(() => createStore(root.errorStore, root.projectsStore, root.tabsStore, root.notificationStore, root.userProfileStore));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useTasksStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }

    return store;
};