import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import { UserProfileResult, UserProfile } from './types';
import appClient from '../../common/services/AppClient';
import { ResponseData } from '../../common/services/AppClient';
import { UserModel } from '../types/UserModel';

export default class UserProfileService {

    async getUserProfile(): Promise<UserProfile> {
        const result = await execQuery<UserProfileResult>({
            query: gql`query getUserProfile{
                  getUserProfile {
                    userId
                    userName
                    name
                    timezone
                    avatarIconFileId
                    firstName
                    lastName
                    email
                    isDomainUser
                    lastActivityTime
                  }
            }`,
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }
        return result.data.getUserProfile;
    }

    updateUserProfile(data: FormData) {
        const url = process.env.REACT_APP_TASKS_URL + 'userProfile';
        return appClient.post(url, data);
    }

    async getAvatarIcon(fileId: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}userProfile/icon/${fileId}`;
        const resp = await appClient.get<Blob | undefined>(url, 'blob');
        return resp.unwrapOr(undefined);
    }

    getUserByName(name: string) {
        const url = `${process.env.REACT_APP_TASKS_URL}users/${name}`;
        return appClient.get<UserModel>(url);
    }

    resetPassword(password: string) {
        const data = {password};
        const url = process.env.REACT_APP_TASKS_URL + 'userProfile/password';
        return appClient.post<boolean>(url, data);
    }

    async changePassword(newPassword: string, oldPassword: string): Promise<ResponseData> {
        const data = {oldPassword, newPassword};
        const url = process.env.REACT_APP_TASKS_URL + 'userProfile/password_change';
        return appClient.postRaw(url, data);
    }
}