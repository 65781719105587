import { Route } from 'react-router';
import * as React from 'react';
import  * as adminContext  from '../stores/context';
import UsersManagementPage from '../screens/UsersManagementPage';
import MetadataPage from '../screens/MetadataPage';
import WidgetsPage from '../screens/WidgetsPage';
import ActionDefinitionPage from '../screens/ActionDefinitionPage';
import { AppPermissions } from '../../authorization/Permissions';
import { hasPermission } from '../../authorization/components/HasPermission';

const adminRoute = (permissions: string[]) => {
    return (
        <>
            {hasPermission(permissions, AppPermissions.CanAccessAdministration) ? <Route
                path={'/admin/user-management'}
                element={
                    <adminContext.StoreProvider>
                        <UsersManagementPage/>
                    </adminContext.StoreProvider>
                }
            /> : null}
            <Route
                path={'/admin/metadata'}
                element={
                    <adminContext.StoreProvider>
                        <MetadataPage/>
                    </adminContext.StoreProvider>
                }
            />
            <Route
                path={'/admin/dashboard'}
                element={
                    <adminContext.StoreProvider>
                        <WidgetsPage/>
                    </adminContext.StoreProvider>
                }
            />
            <Route
                path={'/admin/actions'}
                element={
                    <adminContext.StoreProvider>
                        <ActionDefinitionPage/>
                    </adminContext.StoreProvider>
                }
            />
        </>
       
    );
};
  
export default adminRoute;