/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExtraDateFilter } from '../../administration/types/DashboardWidgetMeta';
import { FormInputParams } from '../../analysis/types/InputParams';
import { AlphaId, DefaultColumn, MetadataColumn, TaskListModel } from '../../tasks/types';
import { Notification } from '../types/Notification';

export enum PackageStateResult {
    Ready = 'Ready',
    Parsed = 'Parsed',
    Importing = 'Importing',
    Processing = 'Processing',
    Uploading = 'Uploading',
    Broken = 'Broken'
}

export enum PackageSetType {
    Conjunction = 'Conjunction',
    Folder = 'Folder'
}

export type AnchorResult = {
    id: string;
    keyword: string;
    aliases: string[]
};

export type NamedEntity = {
    start: number;
    end: number;
    text: string
};

export type ApplicationSession = {
    id: string;
    packageId: string;
    projectId: string;
    runtimeSessionId: string;
    created: string;
    updated: string;
    applicationDefinitionId: string;
    error: string;
    applicationExtension: string;
    applicationName: string;
    applicationSettings: {};
    iotaApplication: string;
    packageName: string;
    projectName: string;
    state: SessionStates;
    packageSetId?: string;
    userId?: string;
    isBulkSession?: boolean;
    isReloadable?: boolean;
    updatedBy?: string
};

export type TabApplicationData = {
    appId: string;
    appName: string;
    appExtension: string;
    appSettings: unknown;
    iotaApplication: string
};

export type SessionStates = 'Pending' | 'Finished' | 'WaitingAction' | 'Aborted' | 'NotStarted' | 'Archived';

export type ApplicationSessionResult = {
    id: string;
    packageId: string;
    projectId: string;
    runtimeSessionId: string;
    created: string;
    updated: string;
    applicationDefinitionId: string;
    applicationExtension: string;
    applicationSettings: {};
    iotaApplication: string;
    error: string;
    state: SessionStates;
    packageSetId?: string;
    isBulkSession?: boolean
};

export type PackageResult = {
    id: string;
    fileName: string;
    filePath: string;
    projectId: string;
    contentType: string;
    state: PackageStateResult;
    operationState: string[];
    userTags: string[];
    fileSizeBytes: number;
    uploadedTime: string;
    indexDate: string;
    source: string;
    packageSetId?: string
};

export enum PackageListItemType {
    Package = 'package',
    PackageSet = 'packageSet'
}

export type PackageListItemModel = {
    id: string;
    fileName: string;
    projectName: string;
    projectId: string;
    state: PackageStateResult;
    userTags: string[];
    fileSizeBytes: number;
    uploadedTime: string;
    indexDate: string;
    type?: PackageListItemType;
    packages?: PackageResult[]
};

export type PackageSetResult = {
    id: string;
    name: string;
    projectId: string;
    packageIds: string[];
    type: PackageSetType;
    uploadTime?: string;
    state: PackageStateResult;
    packages: PackageResult[]
};

export type ProjectForCurrentUser = {
    id: string;
    name: string;
    isAdmin: boolean;
    owner: string
};

export type Project = {
    id: string;
    name: string;
    owner: string
};

export type TaskListLayoutResult = {
    id: string;
    userId: string;
    columnsConfig: {[key: string]: ColumnResult[]};
    selectedProjectId: string | null;
    pinnedTasks: PinnedTask[] | null
};

export type PinnedTask = {
    id: string;
    alphaId: AlphaId;
    name: string;
    parentTask: TaskListModel
};

export type ColumnResult = MetadataColumn | DefaultColumn;

export type ColumnsFilter = {
    fieldType: 'General' | 'Metadata';
    fieldName: string;
    operator: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any[];
    extraFilters:  {
        extraDateFilter?: ExtraDateFilter
    };
    saved: boolean;
    isWidgetFilter: boolean;
    metatadaId?: string
};

export type DefaultProjectRes = {
    id: string;
    defaultProject: string
};

export type ApplicationCapabilities = {
    isBulkProcessing: boolean;
    isReloadingDisabled: boolean
};

export type ApplicationDefinition = {
    id: string;
    name: string; 
    applicationId: string; 
    workflowId: string; 
    projectId: string; 
    extension: string;
    state: string;
    meta: string;        
    settings?: string;
    iconFileId?: string;
    bindings: {
        inputId: string;
        type: string;
        value: unknown
    }[];
    settingValues?: {[id: string]: unknown};
    iconUrl?: string;
    capabilities?: ApplicationCapabilities
};

export type ApplicationDefinitionSubgroup = {
    id: string;
    name: string;
    applicationDefinitionIds: string[];
    subGroups: ApplicationDefinitionSubgroup[]
};

export type PackageChanges = {
    id: string;
    state: PackageStateResult;
    fileName: string;
    fileSizeBytes: number;
    uploadedTime: string;
    userTags: string[];
    projectId: string;
    filePath: string;
    indexDate: string;
    packageSetId?: string;
    source?: string
};

export type PackageSetSession = {
    id: string;
    packages: PackageSetSessionPackage[]
};

export type PackageSetSessionPackage = {
    packageId: string;
    packageName: string;
    state: string;
    createdTime: string;
    updatedTime: string
};

export type ApplicationDefinitionGroup = {
    id: string;
    projectId: string;
    name: string;
    applicationDefinitionIds: string[];
    subGroups: ApplicationDefinitionSubgroup[]
};

export type PackageSetSessionResult = {
    getPackageSetSessionById: PackageSetSession
};

export type AllSessionsResult = {
    getSessionModels: ApplicationSession[]
};

export type SessionsForProjects = {
    getSessionModelsForProjects: ApplicationSession[]
};

export type SessionModelResult = {
    getSessionModelById: ApplicationSession[]
};


export type GetTaskListLayoutForUserResult = {
    getTaskListLayoutByUser: TaskListLayoutResult
};


export type ApplicationDefinitionGroupsResult = {
    getAppDefinitionGroups: ApplicationDefinitionGroup[]
};

export type ApplicationDefinitionsResult = {
    getAppDefinitions: ApplicationDefinition[]
};

export type NotificationsResult = {
    getNotifications: Notification[]
};

export type WidgetDataItem = {
    text: string; value: string
};

export type ComplexWidgetDataItem = {
    text: string; value: unknown
};

export type PackageSetItemModel = {
    id: string;
    fileName: string;
    contentType: string;
    fileSizeBytes: number;
    indexDate: string
};

export type PackageSetItemsResult = {
    getPackagesFromSet: PackageSetItemModel[]
};

export type WidgetData = {
    total: number;
    skip: number;
    data: WidgetDataItem[]
};

export type CurrentWidgetValueIds = {
    [key: string]: string | string[] | boolean | unknown[]
};

export enum WidgetType { Combobox = 'combobox', 
    DatePicker =  'datepicker',
    Input = 'input',
    Checkbox = 'checkbox', 
    Radio = 'radio',
    ListBox = 'listbox',
    VisualTable = 'visualtable',
    ResultTable= 'resulttable',
    ContainerInputChild = 'containerInputChild'}

export type InputAction = {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    settings: any
};

export type InputStyle = {
    backgroundColor?: string;
    borderColor?: string;
    color?: string
};

export type IotaSessionResult = {
    sessionId: string;
    inProgress: boolean;
    response: FormInputModels
};

export type DisplayColumnsBehavior = {
    key: string;
    header: string;
    canSort: boolean;
    source?: string;
    dataType?: string;
    rowSpecificSourceKey: string
};

export type InputOverrideBehavior = {
    key: string;
    type: 'dropdown' | 'text' | 'datepicker';
    source: string;
    width?: 'default' | 'wide';
    hasModalForm?: boolean;
    parentInputId?: string;
    name?: string;
    multiSelect?: boolean;
    modalFormHeader?: string;
    hasRowSpecificSource?: boolean;
    rowSpecificSourceKey?: string;
    maxLength?: number
};

export type SimpleRuntimeInputParams = {
    id: string;
    controlType: WidgetType;
    type: 'string' | 'number';
    name: string;
    validation: {
        regex: string;
        required: boolean
    };
    description: string;
    behavior?: {
        multiselect?: boolean;
        readonly?: boolean;
        multiline?: boolean;
        onchange?: string;
        width?: number
    };
    value: string | string[] | boolean;
    unmodifiedValue: string | string[] | boolean;
    source: {
        id: string
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta?: { [key: string]: any };
    parentInputId?: string
};

export type ContainerChildInput = {
    input: SimpleRuntimeInputParams;
    column: number;
    row: number
};

export type ContainerInput = {
    children: ContainerChildInput[];
    rows: number;
    columns: number;
    id: string;
    section: string;
    headers: string[];
    footer: FormInputModels;
    behavior?: {
        useSimpleContainerControls: boolean;
        readonly?: boolean
    }
};

export enum InputBindingType {
    CodeRule = 'Business Rule',
    Tag = 'Tag',
    Constant = 'Constant'
}

export type FormSourcesParams = {
    id: string;
    type: 'static' | 'linked';
    link: string;
    data: {value: string; text: string}[]
};

export type FormActionsParams = {
    id: string;
    name: string;
    description: string;
    isDisabled: boolean;
    message: string
};

export type FormSectionsParams = {
    id: string;
    name: string;
    order: number;
    parentSectionId?: string;
    type: SectionType;
    location: SectionLocation;
    isCollapsed: boolean

};

export enum SectionLocation {
    Bottom = 'Bottom',
    Left = 'Left',
}

export type FormErrorParams = {
    errorCode?: string;
    message?: string;
    details?: string
};

export type FormInputModels = {
    ['inputs']: FormInputParams[];
    ['sources']: FormSourcesParams[];
    ['actions']: FormActionsParams[];
    ['sections']: FormSectionsParams[];
    ['errors']: FormErrorParams[] | null;
    ['containerInputs']: ContainerInput[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ['layoutConfiguration']: any | null;
    ['postActions']: RuntimePostAction[] | null;
    ['preSubmitActions']: RuntimePreSubmitAction[] | null
};

export enum SectionType {
    Accordeon = 'Accordeon',
    Tab = 'Tab'
}
export type FormInputFieldData = {
    p: number; // Page number
    x: number;
    y: number;
    w: number; // Width
    h: number; // Height
    pw: number; // Page width
    ph: number; // Page height
    bt: string; // Block type
    pId?: string; // Package Id
    nodeHierarchy?: string
};

export type RuntimePostAction = {
    type: 'Download' | 'Close'; // Add types if necessary
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: {[key: string]: any}
};

export type RuntimePreSubmitAction = {
    type: 'Authorize'; // Add types if necessary
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: {[key: string]: any}
};

export type SessionState = {
    inProgress: boolean;
    response: FormInputModels;
    projectId: string;
    packageId: string
};

export type StartSessionResponse = {
    sessionId: string;
    packageId: string
};

export type StartSessionForMultiplePackagesResponse = {
    sessionId: string;
    packageIds: string[];
    packageSetId: string
};

export type SessionContinueRequest = {
    ['inputs']: {
        [key: string]: string
    }; 
    ['action']: string
};

export type DataResponse = {
    totalCount: number;
    page: number;
    pageSize: number;
    rows: DataResponseEntityRow[]
};

export type DataResponseEntityRow = {
    items: DataResponseEntity[]
};

export type DataResponseEntity = {
    field: string;
    value: unknown
};

export type UserProfile = {
    userId: string;
    userName: string;
    name: string;
    timezone: string;
    avatarIconFileId: string;
    firstName: string;
    lastName: string;
    email: string;
    isDomainUser: boolean;
    isDeleted: boolean;
    lastActivityTime: Date
};

export type UserProfileResult = {
    getUserProfile: UserProfile
};

export type FieldComment = {
    userId: string;
    value: string;
    date: Date
};

export type IotaAppComment = {
    fieldId: string;
    comments: FieldComment[]
};

export type IotaAppCommentsResult = {
    getIotaAppComments: IotaAppComment[]
};


