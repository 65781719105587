import { Button, Modal } from 'antd';
import * as React from 'react';
import { ProjectsStore } from '../../common/stores';
import AddProjectForm from '../../administration/components/AddProjectForm';
import { observer } from 'mobx-react-lite';

type Props = {
    projectsStore: ProjectsStore;
    isDialogVisible: boolean;
    closeDialog: () => void
};

const AddProjectDialog: React.FC<Props> = ({ projectsStore, isDialogVisible, closeDialog}) => {

    const createUpdateProject = async(name: string, owner: string) => {
        await projectsStore.createUpdateProject(name, owner,  () => closeDialog());
    };

    const handleCandel = () => {
        closeDialog();
        projectsStore.setEditableProject(null);
    };

    return (
        <Modal
            centered
            width={600}
            title={projectsStore.editableProject ? 'Edit project' : 'Add project'}
            destroyOnClose
            closable={false}
            maskClosable={false}
            open={isDialogVisible}
            className="alpha-modal"
            footer={[
                <Button 
                    data-id="add-project-cancel" 
                    key="add-project-cancel" 
                    onClick={handleCandel} 
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="add-project-submit" 
                    key="add-project-submit" 
                    size="large"
                    type="primary"
                    htmlType="submit"
                    form="add-project-form"
                    loading={projectsStore.isProjectUpdateLoading}
                >
                    {projectsStore.editableProject ?  'Save' : 'Create new project'}
                </Button>
            ]}
        >
            <AddProjectForm 
                onAddProject={createUpdateProject}
                store={projectsStore}
            />
        </Modal>
    );
};

export default observer(AddProjectDialog);