import { DashboardWidgetType } from '../../administration/types/DashboardWidget';
import WidgetBase from './WidgetBase';

type WidgetItem = {
    taskIds: string[];
    widgetId: string;
    widgetName: string;
    percentage: number
};

export default class GroupedWidget extends WidgetBase {
    widgets: WidgetItem[];

    constructor(id: string, name: string, type: DashboardWidgetType, widgets: WidgetItem[], projecId: string, isDefaultWidget: boolean) {
        super(id, type, name, projecId, isDefaultWidget);
        this.widgets = widgets;
    }
}