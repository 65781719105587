import { action, makeObservable, observable, runInAction } from 'mobx';
import { ProjectsStore, UserProfileStore } from '../../common/stores';
import { TaskCommentModel } from '../../tasks/types';
import DashboardService from '../services/DashboardService';
import { ActivitiesPeriodEnum, Activity, ActivityByProjects, AssignmentMsg, UpdatedActivitiesPushMsg } from '../types/Activity';
import tasksPushClient from '../../tasks/services/TaskPushClient';
import { Observable } from 'rxjs';
import WidgetTypes from '../models/WidgetTypes';
import ListWidget from '../models/ListWidget';
import GroupedWidget from '../models/GroupedWidget';
import PieChartWidget from '../models/PieChartWidget';
import { UserDashboardSettings } from '../types/DashboardSettings';

export default class DashboardStore {
    assignedTasks: string[];

    comments: TaskCommentModel[];

    activities: ActivityByProjects = { AllProjects: [], SelectedProject: [] };

    widgets: WidgetTypes[] = [];

    isLoading: boolean;

    reloadingLastActivities: boolean = false;

    selectedActivitiesProjectId: string | null; 

    activitiesPeriod: ActivitiesPeriodEnum = ActivitiesPeriodEnum.Today;
    
    private newComments: Observable<TaskCommentModel & {projectId: string}>;

    constructor(private dashboardService: DashboardService, private userProfileStore: UserProfileStore, private projectsStore: ProjectsStore) {
        makeObservable<DashboardStore>(this, {
            assignedTasks: observable,
            activities: observable,
            widgets: observable,
            isLoading: observable,
            reloadingLastActivities: observable,
            selectedActivitiesProjectId: observable,
            setIsLoading: action,
            setIsReloadingLastActivities: action,
            handleNewCommentMsg: action.bound,
            handleDeletedCommentMsg: action.bound,
            loadActivities: action.bound,
            getWidgets: action.bound,
            refreshDashboard: action.bound,
            updateDashboardActivities: action.bound,
            updateTaskAssigmentActivities: action.bound,
            setSelectedActivitiesProjectId: action,
            handleActivitiesPeriodChange: action.bound,
            handleActivitiesProjectChange: action.bound
        });
        const { pushClient } = tasksPushClient;

        const newCommentObs = pushClient.createTaskNewCommentListener().publish();
        this.newComments = newCommentObs;
        newCommentObs.connect();
        this.newComments.subscribe(this.handleNewCommentMsg);

        const deletedCommentObs = pushClient.createTaskDeleteCommentListener().publish();
        deletedCommentObs.subscribe(this.handleDeletedCommentMsg);
        deletedCommentObs.connect();

        const updatedActivitiesObs = pushClient.createDashboardActivitiesListener().publish();
        updatedActivitiesObs.subscribe(this.updateDashboardActivities);
        updatedActivitiesObs.connect();

        const newTaskAssingmentObs = pushClient.createTaskAssignmentListener().publish();
        newTaskAssingmentObs.subscribe(this.updateTaskAssigmentActivities);
        newTaskAssingmentObs.connect();
    }

    get allProjectsActivities() {
        return this.activities.AllProjects;
    }

    get selectedProjectActivities() {
        return this.activities.SelectedProject;
    }

    get projects() {
        return this.projectsStore.projects ?? [];
    }
    
    // TODO: Revise this argument. May we don't need it
    async loadActivities(projectId: string | null = null) {
        this.setIsReloadingLastActivities(true);
        const selectedProjectId = projectId ?? this.projects[0]?.id;
        if (selectedProjectId) {
            const resp = await this.dashboardService.getActivities(this.activitiesPeriod, selectedProjectId);
            resp.map((r) => {
                this.activities = r;
            });
        }
        this.setIsReloadingLastActivities(false);

    }

    handleActivitiesProjectChange(projectId: string) {
        this.setSelectedActivitiesProjectId(projectId);
        this.loadActivities(projectId);
    }

    handleActivitiesPeriodChange(period: string) {
        this.setActivitiesPeriod(ActivitiesPeriodEnum[period]);
        this.loadActivities(this.selectedActivitiesProjectId);
    }


    setSelectedActivitiesProjectId(projectId: string) {
        this.selectedActivitiesProjectId = projectId;
    }

    setActivitiesPeriod(period: ActivitiesPeriodEnum) {
        this.activitiesPeriod = period;
    }

    handleNewCommentMsg(comment: TaskCommentModel & {projectId: string}) {
        const allProjectsActivities = this.activities.AllProjects.find(a => a.activityType === 'NewComments');
        const selectedProjectsActivities = this.activities.SelectedProject.find(a => a.activityType === 'NewComments');

        const {projectId } = comment;
        const hasAccessToProject = this.projectsStore.projects.some(p=> p.id === projectId);
        if (hasAccessToProject) {
            allProjectsActivities!.ids.push(comment.id);
            if (projectId === this.selectedActivitiesProjectId) {
                selectedProjectsActivities!.ids.push(comment.id);
            }
        }
    }

    handleDeletedCommentMsg(id: string) {
        const activities = [...this.activities.AllProjects, ...this.activities.SelectedProject].filter(a => a.activityType === 'NewComments')!;
        activities.forEach(activity=>  activity.ids = activity.ids.filter(i=> i!== id));
    }

    updateDashboardActivities(activitiesMsg: UpdatedActivitiesPushMsg) {
        if(activitiesMsg.deletedComments.length) {
            const activity = this.activities.AllProjects.find(a => a.activityType === 'NewComments')!;
            activity.ids = activity.ids.filter(t=> !activitiesMsg.deletedComments.includes(t));
        }
        if(activitiesMsg.deletedTask) {
            const activity = this.activities.AllProjects.find(a => a.activityType === 'AssignedTasks')!;
            activity.ids = activity.ids.filter(t=> activitiesMsg.deletedTask !== t);
        }
        if(activitiesMsg.deletedTaskErrors.length) {
            const activity = this.activities.AllProjects.find(a => a.activityType === 'FailedMessages')!;
            activity.ids = activity.ids.filter(t=> !activitiesMsg.deletedTaskErrors.includes(t));
        }
    }

    updateTaskAssigmentActivities(activityMsg: AssignmentMsg) {
        const allProjectsActivity = this.activities.AllProjects.find(a => a.activityType === 'AssignedTasks')!;
        const selectedProjectActivity = this.activities.SelectedProject.find(a => a.activityType === 'AssignedTasks')!;

        const {currentUserId} = this.userProfileStore;

        const updateActivity = (activity: Activity) => {
            if (activityMsg.userId === currentUserId && !activity.ids.includes(activityMsg.taskId)) {
                activity.ids.push(activityMsg.taskId);
            } else if (activityMsg.userId !== currentUserId && activity.ids.includes(activityMsg.taskId)){
                activity.ids = activity.ids.filter(t=> !activityMsg.taskId.includes(t));
            }
        };

        updateActivity(allProjectsActivity);
        updateActivity(selectedProjectActivity);
    }

    async loadInitialData() {
        this.setIsLoading(true);
        const promises = [this.dashboardService.getWidgets(), this.dashboardService.getUserSettings()] as 
            [Promise<(ListWidget | PieChartWidget | GroupedWidget)[]>, Promise<UserDashboardSettings | null>];
        const resp = await Promise.all(promises);
        this.widgets = resp[0];
        const userSettings = resp[1];
        if (userSettings) {
            this.setActivitiesPeriod(userSettings.activitiesSelectedPeriod);
            this.setSelectedActivitiesProjectId(userSettings.activitiesSelectedProject);
        } else {
            this.setActivitiesPeriod(ActivitiesPeriodEnum.Today);
            this.setSelectedActivitiesProjectId(this.projects[0]?.id);
        }
        this.loadActivities(userSettings?.activitiesSelectedProject);
        this.setIsLoading(false);

    }

    async getWidgets() {
        this.setIsLoading(true);
        this.widgets = await this.dashboardService.getWidgets();
        this.setIsLoading(false);
    }

    async refreshDashboard() {
        this.setIsLoading(true);
        const promises = [
            this.dashboardService.getWidgets(), 
            this.loadActivities(this.selectedActivitiesProjectId)
        ] as  [Promise<(ListWidget | PieChartWidget | GroupedWidget)[]>, Promise<void>];
        const resp = await Promise.all(promises);
        this.widgets = resp[0];
        this.setIsLoading(false);
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    setIsReloadingLastActivities(isLoading: boolean) {
        this.reloadingLastActivities = isLoading;
    }

    async activateWidget(id: string) {
        const widget = await this.dashboardService.activateWidget(id);
        const widgets = this.widgets.slice();
        widgets.push(widget);
        runInAction(() => this.widgets = widgets);
    }

    async deactivateWidget(id: string) {
        await this.dashboardService.deactivateWidget(id);
        const widgets = this.widgets.slice();
        const index = this.widgets.findIndex(w=> w.id === id);
        widgets.splice(index, 1);
        runInAction(() => this.widgets = widgets);
    }
}
