import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'src/modules/common/stores';
import WidgetConstructor from '../components/WidgetConstructor';
import { useAdminStore } from '../stores/context';

const WidgetsPage: React.FC = () => {
    const context = useAdminStore();
    const rootContext = useStore();
    return (
        <WidgetConstructor projectsStore={rootContext.projectsStore} store={context.widgetConstructorStore} />
    );
};

export default observer(WidgetsPage);