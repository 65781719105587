import { Divider, Menu, Tooltip } from 'antd';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import UserProfileControls from '../../user_profile/components/UserProfileControls';
import { AuthConsumer } from '../../authorization/AuthContext';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { useTasksStore } from '../../tasks/stores/context';
import { TabsStore, useStore } from '../stores';
import NotificationsContainer from './NotificationsContainer';
import TaskTabHeader from './TaskTabHeader';

type Props = {
    isCollapsed: boolean;
    tabsStore: TabsStore
};

export const SideMenu: React.FC<Props> = ({ isCollapsed, tabsStore }) => {
    const tasksStore = useTasksStore().taskPreviewVisualStore;
    const tasksGridStore = useTasksStore().tasksGridVisualStore;

    const navigateTo = useNavigate();
    const location = useLocation();
    const { notificationStore, projectsStore } = useStore();

    const [selectedSection, setSelectedSection] = React.useState(location.pathname.includes('tasks') ? '5' : '1');
    const [isAdminView, setIsAdminView] = React.useState(false);


    React.useEffect(() => {
        if (tabsStore.tabs.find(t => t.isSelected)) {
            setSelectedSection('0');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabsStore.tabs.length]);

    React.useEffect(() => {
        const { pathname } = location;
        if (pathname.includes('tasks')) {
            setSelectedSection('9');
            return;
        }
        if (pathname.includes('admin/dashboard')) {
            setSelectedSection('11');
            return;
        }
        if (pathname.includes('dashboard')) {
            setSelectedSection('5');
            return;
        }
        if (pathname.includes('analysis')) {
            setSelectedSection('7');
            return;
        }
        if (pathname.includes('admin/projects')) {
            setSelectedSection('1');
            return;
        }
        if (pathname.includes('admin/user-management')) {
            setSelectedSection('2');
            return;
        }
        if (pathname.includes('task/types')) {
            setSelectedSection('3');
            return;
        }
        if (pathname.includes('task/statuses')) {
            setSelectedSection('4');
            return;
        }
        if (pathname.includes('task/tasktemplates')) {
            setSelectedSection('12');
            return;
        }
        if (pathname.includes('projects')) {
            setSelectedSection('14');
            return;
        }
        if (pathname.includes('admin/metadata')) {
            setSelectedSection('10');
            return;
        }
        if (pathname.includes('admin/actions')) {
            setSelectedSection('13');
            return;
        }
        if (pathname.includes('admin/reports')) {
            setSelectedSection('16');
            return;
        }
        if (pathname.includes('reports')) {
            setSelectedSection('17');
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const navigate = (path: string) => {
        tabsStore.resetSelection();
        navigateTo(path);
    };

    const handleHomeClick = () => {
        setSelectedSection('5');
        navigate('/dashboard');
    };

    const handleAdministationClick = () => {
        setIsAdminView(prevState => !prevState);
    };
    // TODO: Create dedicated store for sideMenu and move all this logics there
    const handleTasksClick = () => {
        tasksStore.togglePreview(undefined);
        tasksStore.togglePreview(undefined);
        if (tasksGridStore.currentWidgetName) {
            tasksGridStore.resetAllFilters();
        }
        setSelectedSection('9');
        navigate('/tasks');
    };

    const closeAnalysedDoc = (e: React.MouseEvent, taskIndex: number) => {
        e.stopPropagation();
        const tab = tabsStore.tabs[taskIndex];
        if (tab.isSelected && tabsStore.documentTabs[taskIndex - 1]) {
            tabsStore.selectTab(taskIndex - 1);
        }
        tabsStore.removeTab(taskIndex);
        setSelectedSection('5');
        navigate('/tasks');
    };

    const handleAnalisedDocClick = (id: string) => {
        const tab = tabsStore.tabs.find(t => t.id === id && t.type === 'document')!;
        tabsStore.addTab(tab);
        navigateTo(`/analysis/project/${tab.metadata.projectId}/task/${tab.metadata.taskId}`);
    };

    const handleProjectMetadataClick = () => {
        setSelectedSection('10');
        navigateTo('/admin/metadata');
    };

    const handleUserManagementClick = () => {
        setSelectedSection('2');
        navigateTo('/admin/user-management');
    };

    const handleActionsClick = () => {
        setSelectedSection('13');
        navigateTo('/admin/actions');
    };

    const analisedDocs = tabsStore.documentTabs.map(t =>
        <div
            key={t.id}
            className={`frame ${t.isSelected ? 'selected' : ''}`}
            onClick={() => handleAnalisedDocClick(t.id)}
        >
            <div className="status-wrapper" style={{paddingLeft: 0, paddingRight: 0}}>
                <i className="alpha-icon sm doc" />
            </div>
            <div className="titles-frame">
                <p className='parent'>{t.parentTitle}</p>
                <p className="title">
                    {t.title}
                </p>
            </div>
            <div className="icon-wrapper"onClick={(e) => closeAnalysedDoc(e, tabsStore.tabs.indexOf(t))}>
                <i className="alpha-icon xs cross no-margin" />
            </div>
        </div>);


    const handleTaskTypeClick = () => {
        setSelectedSection('3');
        navigateTo('/task/types');
    };

    const handleTaskStatusesClick = () => {
        setSelectedSection('4');
        navigateTo('/task/statuses');
    };

    const handleTaskTemplateClick = () => {
        setSelectedSection('12');
        navigateTo('/task/tasktemplates');
    };

    const handleProjectClick = () => {
        setSelectedSection('14');
        navigateTo('/projects');
    };

    const handleManageDashboardClick = () => {
        setSelectedSection('11');
        navigateTo('/admin/dashboard');
    };

    const handleManageReportsClick = () => {
        setSelectedSection('16');
        navigateTo('/admin/reports');
    };

    const handleReportsClick = () => {
        setSelectedSection('17');
        navigateTo('/reports');
    };

    const getToolTip = (icon: React.ReactElement, title: string) => {
        return <Tooltip placement='right' title={isCollapsed ? title : ''}>{icon}</Tooltip>;
    };

    const getExpandIcon = () => {
        return (
            <div className='expand-icon-container'>
                <i style={!isAdminView ? { transform: 'rotate(-90deg)', transition: 'transform 0.24s' } :
                    { transition: 'transform 0.24s' }} className="alpha-icon xxxs arrow-down-icon" />
            </div>
        );
    };
    return (
        <div className={`side-menu ${isAdminView ? 'is-admin-view' : ''}`}>
            <div className="header-container">
                <i className="alpha-icon logo lg" />
                <span style={{ marginLeft: 12 }}>Alpha Tasks</span>
                <span className="controls-container">
                    <NotificationsContainer store={notificationStore}/>
                </span>
            </div>
            {isCollapsed && <div className="controls-container">
                <NotificationsContainer store={notificationStore}/>
                <div className='divider'>
                    <Divider/>
                </div>
            </div>}
            <div className={`sections-container ${isCollapsed ? 'collapsed': ''}`}>
                <Observer>{() => (
                    projectsStore.projects ? <AuthConsumer>
                        {({ permissions }) => (
                            <Menu 
                                expandIcon={<></>} 
                                mode="inline" 
                                inlineIndent={18} 
                                style={{ overflow: 'hidden auto', borderRight: 0 }} 
                                selectedKeys={[selectedSection]} openKeys={['3']}
                            >
                                {!permissions || hasPermission(permissions, AppPermissions.CanAccessAdministration) || projectsStore.adminInProject ?
                                    <Menu.Item key='15' onClick={handleAdministationClick}>
                                        <div>
                                            {getToolTip(<i className="alpha-icon admin-icon sm" />, 'Administration')}
                                            <span className='title'>Administration</span>
                                            {!isCollapsed && getExpandIcon()}
                                        </div>
                                    </Menu.Item> : <></>}

                                {isAdminView && (
                                    <>
                                        <Menu.Item className='admin-item' key='11' onClick={handleManageDashboardClick}>
                                            <div>
                                                {getToolTip(<i className="alpha-icon pie-chart sm" />, 'Manage Dashboard')}
                                                <span className='title'>Manage Dashboard</span>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item className='admin-item' key='10' onClick={handleProjectMetadataClick}>
                                            <div>
                                                {getToolTip(<i className="alpha-icon meta sm" />, 'Project Metadata')}
                                                <span className='title'>Project Metadata</span>
                                            </div>
                                        </Menu.Item>
                                        {hasPermission(permissions, AppPermissions.CanAccessAdministration) ? 
                                            <Menu.Item className='admin-item' key='2' onClick={handleUserManagementClick}>
                                                <div>
                                                    {getToolTip(<i className="alpha-icon members-white sm" />, 'Manage Users')}
                                                    <span className='title'>Manage Users</span>
                                                </div>
                                            </Menu.Item> :null}
                                        <Menu.Item className='admin-item' key='3' onClick={handleTaskTypeClick}>
                                            <div>
                                                {getToolTip(<i className="alpha-icon task-types sm" />, 'Task Types')}
                                                <span className='title'>Task Types</span>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item className='admin-item' key='4' onClick={handleTaskStatusesClick}>
                                            <div>
                                                {getToolTip(<i className="alpha-icon flag sm" />, 'Task Statuses')}
                                                <span className='title'>Task Statuses</span>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item className='admin-item' key='12' onClick={handleTaskTemplateClick}>
                                            <div>
                                                {getToolTip(<i className="alpha-icon star-icon sm" />, 'Task Templates')}
                                                <span className='title'>Task Templates</span>
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item className='admin-item' key='13' onClick={handleActionsClick}>
                                            <div>
                                                {getToolTip(<i className="alpha-icon play-icon sm" />, 'Action Definitions')}
                                                <span className='title'>Action Definitions</span>
                                            </div>
                                        </Menu.Item>
                                        {hasPermission(permissions, AppPermissions.CanAccessAdministration) ? 
                                            <Menu.Item className='admin-item' key='14' onClick={handleProjectClick}>
                                                <div>
                                                    {getToolTip(<i className="alpha-icon projects-white sm" />, 'Projects')}
                                                    <span className='title'>Projects</span>
                                                </div>
                                            </Menu.Item> : null
                                        }
                                        {hasPermission(permissions, AppPermissions.CanAccessAdministration) && 
                                            <Menu.Item className='admin-item' key='16' onClick={handleManageReportsClick}>
                                                <div>
                                                    {getToolTip(<i className="alpha-icon reports-icon sm" />, 'Reports')}
                                                    <span className='title'>Published reports</span>
                                                </div>
                                            </Menu.Item>
                                        }
                                    </>
                                )}
                                {isCollapsed && isAdminView && <div className='divider'>
                                    <Divider/>
                                </div>}
                                <Menu.Item key='5' onClick={handleHomeClick}>
                                    <div>
                                        {getToolTip(<i className="alpha-icon dashboard-icon sm" />, 'Dashboard')}
                                        <span className='title'>Dashboard</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item key='17' onClick={handleReportsClick}>
                                    <div>
                                        {getToolTip(<i className="alpha-icon reports-icon sm" />, 'Reports')}
                                        <span className='title'>Reports</span>
                                    </div>
                                </Menu.Item>
                                {/* <Menu.SubMenu key='7' title={
                                    <div className='analysed-doc-menu-container'>
                                        <i className="alpha-icon analysis xs" />
                                        <span className='title'>Analysis</span>
                                    </div>

                                }>
                                    {!isCollapsed && getAnalisedDocs()}
                                </Menu.SubMenu> */}
                                {/* <Menu.Item key='8'>
                                    <div>
                                        <i className="alpha-icon side-menu-archive xs" />
                                        <span className='title'>Archive</span>
                                    </div>
                                </Menu.Item> */}
                                <Menu.Item key='9' onClick={handleTasksClick}>
                                    <div>
                                        {getToolTip(<i className="alpha-icon tasks sm" />, 'Tasks')}
                                        <span className='title'>Tasks</span>
                                    </div>
                                </Menu.Item>
                            </Menu>)}
                    </AuthConsumer> : null)}
                </Observer>
                <div className='divider'>
                    <Divider/>
                </div>
                <div className='pinned-tasks-title'>
                    Pinned tasks
                </div>
                <Observer>
                    {() => (
                        <div className={`hint ${tabsStore.tabs.some(s=> s.type === 'task') ? 'hidden' : ''}`}>
                            You can pin a task
                            by clicking  &nbsp;{<i className='alpha-icon pin-white-icon xs'/>} icon
                        </div>)}
                </Observer>
                <Observer>
                    {() => (
                        <div className={`opened-tabs ${isCollapsed ? 'hidden' : ''}`}>
                            {tabsStore.taskTabs.map(t => 
                                <TaskTabHeader key={t.id} {...{ tab: t, tasksStore, tabsStore, isCollapsed}} />
                            )}
                            {analisedDocs.length > 0 ? <>
                                <div className='divider'>
                                    <Divider/>
                                </div>
                                <div className='pinned-tasks-title'>
                                    Documents
                                </div>
                            </> : null}
                            {analisedDocs}
                        </div>)}
                </Observer>
            </div>
            <div className="profile-icon-container">
                <UserProfileControls/>
            </div>
        </div>
    );
};

export default observer(SideMenu);
