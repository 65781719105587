import { Route } from 'react-router';
import * as React from 'react';
import ProjectPage from '../screens/ProjectPage';
import { AppPermissions } from '../../authorization/Permissions';
import { hasPermission } from '../../authorization/components/HasPermission';


const projectsRoute = (permissions: string[]) => {
    if (!hasPermission(permissions, AppPermissions.CanAccessAdministration)) {
        return null;
    }
    return (
        <>
            <Route
                path={'/projects'}
                element={
                    <ProjectPage/>
                }
            />
        </>
       
    );
};
  
export default projectsRoute;

