import * as React from 'react';
import { Route } from 'react-router';
import AnalysisPage from '../screens/AnalysisPage';

const analysisRoute = () => {
    return (
        <Route
            path={'/analysis/project/:projectId/task/:taskId'}
            element={
                <AnalysisPage />
            }
        />
    );
};

export default analysisRoute;