import { Button, Popconfirm, Tag, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { ProjectsStore } from 'src/modules/common/stores';
import WidgetConstructorStore from '../stores/WidgetConstructorStore';
import { DashboardGroupedWidgetMeta } from '../types/DashboardWidgetMeta';
import CreateGroupedWidgetDialog from './CreateGroupedWidgetDialog';

type Props = {
    store: WidgetConstructorStore;
    projectsStore: ProjectsStore;
    isCreateModalVisible: boolean;
    setIsCreateModalVisible: (val: boolean) => void
};
export const GroupedWidgetsTable: React.FC<Props> = ({ store, projectsStore, isCreateModalVisible, setIsCreateModalVisible }) => {

    const editWidget = (widget: DashboardGroupedWidgetMeta) => {
        store.setEditableWidget(widget.id);
        setIsCreateModalVisible(true);
    };

    const columns: ColumnsType<DashboardGroupedWidgetMeta> = [{
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
    }, 
    {
        key: 'projectId',
        dataIndex: 'projectId',
        title: 'Project',
        render: (projectId: string) => (
            <span>{projectsStore.projects?.find(p => p.id === projectId)?.name}</span>
        )
    },
    {
        key: 'widgets',
        dataIndex: 'widgets',
        title: 'widgets',
        render: (widgets: string[]) => (widgets.map(w => <Tag key={w}>{store.widgets.find(x=> x.id === w)?.name}</Tag>)
        )
    },
    {
        key: 'actions',
        title: null,
        render: (_: unknown, record: DashboardGroupedWidgetMeta) => (
            <div className="row-actions-wrapper">
                <Tooltip title="Edit widget">
                    <Button
                        type="link"
                        onClick={() => editWidget(record)}
                        size="small"
                        data-id-cells="Edit widget"
                        data-id-name={record.name}
                    >
                        <i className="alpha-icon md table-action-edit" /> 
                    </Button>
                </Tooltip>
                <Popconfirm id="data-id-popconfirm-box" title="Are you sure you want to delete this widget?" onConfirm={() => {
                    store.deleteWidget(record.id);
                }}>
                    <Button data-id-cells="Delete widget" title="Delete" size="small" type="link">
                        <Tooltip title="Delete widget" placement="bottom">
                            <i className="alpha-icon md table-action-delete" />
                        </Tooltip>
                    </Button>
                </Popconfirm>
            </div>
        )
    }];

    return (
        <Observer>{() => (
            <>
                <CreateGroupedWidgetDialog isVisible={isCreateModalVisible} closeDialog={() => setIsCreateModalVisible(false)} />
                <Table
                    rowKey={(r) => r.id}
                    className="alpha-table"
                    columns={columns}
                    dataSource={store.groupedWidgets}
                    pagination={{
                        showSizeChanger: false,
                        pageSize: 20,
                        hideOnSinglePage: true
                    }}
                />
            </>
        )}
        </Observer>
    );
};

export default observer(GroupedWidgetsTable);

