import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import { Tooltip, InputNumber, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { getConfLevelColor, ConfidenceLevel } from './ConfidenceLevel';
import * as _ from 'lodash';
import { FormInstance, Rule } from 'antd/lib/form';
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Subject, Subscription } from 'rxjs';
import { FormInputFieldData } from '../../common/services/types';
import { Utils } from '../../common/misc/Utils';
import { FormInputParams } from '../types/InputParams';

export type InpurProps = {
    inputParams: FormInputParams;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onAction: (id: string, action: string, value?: any) => void;
    form: FormInstance;
    onHighlightBlock: (field: FormInputFieldData, inputId: string) => void;
    getGearIcon: (inputParams: FormInputParams) => React.ReactElement;
    highlightInputSubject?: Subject<string>;
    onDownload?: (fileName: string) => void;
    highlightOnFocus?: boolean
};
export const ScanResultsInput: React.FC<InpurProps> = ({ inputParams, form, onHighlightBlock, highlightInputSubject, getGearIcon, onAction, 
    highlightOnFocus }) => {

    const getInitialValue = () => {
        if (['number', 'decimal'].indexOf(inputParams.type) > -1 && inputParams.value) {
            if (inputParams.type === 'decimal') {
                return Number((inputParams.value.toString()).replace(/,/g, ''));
            }
            return typeof(inputParams.value) === 'number' ? inputParams.value : Number((inputParams.value as string).replace(/,/g, ''));
        }
        return inputParams.value && inputParams.value.toString();
    };
    const [value, setValue] = useState(getInitialValue());
    const [isHighlighted, setIsHighlighted] = useState(false);

    const initialValue = value;
    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();
    
    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }
  
        return (() => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        });
    },              [highlightInputSubject, inputParams.id]);

    const textInputChangeHandler = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const val = event.target.value;
        setValue(val);
        if (actionId) {
            if (actionId.value) {
                onAction(inputParams.id, actionId.value, val);
            } else {
                onAction(inputParams.id, actionId, val);
            }
        }
    };    

    const actionId = inputParams.behavior && inputParams.behavior.onchange;

    const numberInputChangeHandler = (val: number | string) => {
        if (initialValue === val) {
            return;
        }
        setValue(val);
        if (val == null) {
            return;
        }
        if (actionId) {
            if (actionId.value) {
                onAction(inputParams.id, actionId.value, val);
            } else {
                onAction(inputParams.id, actionId, val);
            }
        }
    };    

    let handleInputNumberChange = numberInputChangeHandler;
    let handleInputTextChange = textInputChangeHandler;
    if (actionId && actionId.delay) {
        handleInputNumberChange = _.throttle(numberInputChangeHandler, actionId.delay, {leading: false});
        handleInputTextChange = _.throttle(textInputChangeHandler, actionId.delay, {leading: false});
    }

    let type = 'number';
    if (inputParams.type === 'string') {
        type = 'text';
    }

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    const handleFocus = () => {
        if (highlightOnFocus) {
            highlightField();
        }
    };

    const getTooltip = () => {
        if (!inputParams.behavior || !inputParams.behavior.inputTooltip) {
            return null;
        }
        return(
            <Tooltip overlayClassName="alpha-input-tooltip" title={inputParams.behavior.inputTooltip} placement="top">
                <QuestionCircleOutlined style={{marginLeft: 6, verticalAlign: 'middle'}}/>
            </Tooltip>
        );
    };

    const label = (
        <>
            <span className="alpha-doc-control-label with-controls">
                <Tooltip title={inputParams.name}>
                    <span className="alpha-doc-control-name">
                        {inputParams.name}
                    </span>
                </Tooltip>
                <span className="controls-container">
                    {inputParams.behavior && inputParams.behavior.readonly 
                        ? <></> 
                        : <ConfidenceLevel input={inputParams} /> } 
                    
                    {inputParams.meta && inputParams.meta.isEdited === true && (
                        <Tooltip title="Edited">
                            <WarningOutlined style={{color: 'red', marginBottom: 2}} />
                        </Tooltip>
                    )}
                    {inputParams.meta && inputParams.meta.field && inputParams.value != null && highlightOnFocus !== true && (
                        <Tooltip title="View in document">
                            <i className="alpha-icon xs form-anchor" onClick={highlightField}/>
                        </Tooltip>
                    )  || getGearIcon(inputParams)}
                    {getTooltip()}
                </span>
            </span>
        </>
    );

    const getClassName = () => {
        return inputParams.value && !(inputParams.behavior && inputParams.behavior.readonly) ? 
            getConfLevelColor(inputParams) + getInputCssClass() : getInputCssClass();
    };

    const getMaskedInput = (val: string) => {
        if (val === null || !val.length) {
            return val;
        }
        let res = `${val}`.replace(new RegExp(inputParams.inputMask!, 'g'), ',');
        return res;
    };

    const getEditor = () => {
        if (inputParams.behavior && inputParams.behavior.multiline) {
            return (
                <TextArea
                    onFocus={handleFocus}
                    className={getClassName()}
                    style={{
                        padding: '8px 10px',
                        resize: 'vertical'
                    }}
                    onChange={(event) => {
                        event.persist(); handleInputTextChange(event); 
                    }}
                    maxLength={inputParams.validation && inputParams.validation.maxLength && inputParams.validation.maxLength.value}
                    disabled
                    autoSize={{minRows: 1, maxRows: 10}}
                />
            );
        } else { 
            switch (inputParams.type) { 
            case 'number':
                return (
                    <InputNumber 
                        autoComplete="off"
                        onFocus={handleFocus}
                        formatter={val => inputParams.inputMask ? getMaskedInput(val as string) : `${Number.isNaN(val as number) ? '' : val}`}
                        parser={val => val ? val.toString().replace(/\D/g, '') : ''}
                        className={`${getClassName()} ${inputParams.type}-type`}
                        onChange={handleInputNumberChange}
                        maxLength={inputParams.validation && inputParams.validation.maxLength && inputParams.validation.maxLength.value}
                        disabled
                    />
                );

            case 'decimal':
                return (
                    <InputNumber 
                        autoComplete="off"
                        onFocus={handleFocus}
                        step={0.01}
                        precision={2}
                        formatter={val => val ? Number(val).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                        parser={val => val ? val.toString().replace(/,/g, '') : ''}
                        className={`${getClassName()} ${inputParams.type}-type`}
                        onChange={handleInputNumberChange}
                        maxLength={inputParams.validation && inputParams.validation.maxLength && inputParams.validation.maxLength.value}
                        disabled
                    />
                );
            default:
                return(
                    <Input 
                        autoComplete="off"
                        onFocus={handleFocus}
                        className={getClassName()}
                        type={type}
                        style={{background: highlightInput()}}
                        onChange={(event) => {
                            event.persist(); handleInputTextChange(event); 
                        }}
                        maxLength={inputParams.validation && inputParams.validation.maxLength && inputParams.validation.maxLength.value}
                        disabled
                        title={form.getFieldValue(inputParams.id)}        
                    />
                );
            }
        }
        
    };  

    const highlightInput = () => {
        if (!inputParams.behavior || inputParams.behavior.highlightingInput !== true || !value) {
            return;
        }

        const lowerCaseVal = value.toString().toLowerCase();

        switch (lowerCaseVal) {
        case 'true':
            return '#cbffcb';
        case 'false': 
            return '#ff9d9d';
        default:
            return;
        }
    };
    
    const getInputCssClass = () => {
        return isHighlighted ? ' iota-contract-shadow' : '';
    };

    const getValueFromEvent = (event: React.FormEvent<HTMLInputElement> | number) => {
        if (!event || !(event as React.FormEvent<HTMLInputElement>).currentTarget) {
            return event;
        }
        const eventVal = ((event as React.FormEvent<HTMLInputElement>).currentTarget).value;
        let val = inputParams.type === 'number' && eventVal ? Number(eventVal) : eventVal;
        val = inputParams.type === 'numberWithKeyword' ? (val as string).replace(/,/g, '') : val;
        return val;
    };

    const getRules = () => {
        const fieldType = inputParams.type === 'decimal' ? 'number' : inputParams.type === 'numberWithKeyword' ? 'string' : inputParams.type;
        const baseRules = [{
            required: inputParams.validation && inputParams.validation.required && inputParams.validation.required.value,
            max: inputParams.validation && inputParams.validation.maxLength && inputParams.validation.maxLength.value,
            type: fieldType,
            message: inputParams.validation && inputParams.validation.required && inputParams.validation.required.message || `${inputParams.name} is required`
        }] as Rule[];
        if (inputParams.validation && inputParams.validation.regex) {
            baseRules.push(
                {
                    type: fieldType,
                    pattern: new RegExp(inputParams.validation.regex.value),
                    message: inputParams.validation && inputParams.validation.regex && inputParams.validation.regex.message
                }
            );
        }
        return baseRules;
    };

    return (
        <Form.Item
            data-id={inputParams.id}
            label={label}
            colon={false}
            labelCol={{span: 24}}
            className={type === 'text' && inputParams.behavior && inputParams.behavior.width === 1 ? 'wide' : ''}
            name={inputParams.id}
            getValueFromEvent={getValueFromEvent}
            rules={getRules()}
            initialValue={value}
        >
            {getEditor()}
        </Form.Item>
    );
};
