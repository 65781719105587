import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Form from 'antd/lib/form';
import DocumentHeaderRenderer from './DocumentHeaderRenderer';
import { Collapse, Input, Tooltip } from 'antd';
import { ScanResultsInput } from './ScanResultsInput';
import { FormInputFieldData } from '../../common/services/types';
import { FormInputParams } from '../types/InputParams';
import FormItem from 'antd/lib/form/FormItem';
import AnalyseVisualStore from '../stores/AnalyseVisualStore';
import { TabsStore } from 'src/modules/common/stores';
import DocumentVisualStore from 'src/modules/documents/stores/DocumentsVisualStore';
const { Panel } = Collapse;

type Props = {
    store: AnalyseVisualStore;
    tabsStore: TabsStore;
    documentName: string;
    documentId: string
};
const ScanResultsRenderer: React.FC<Props> = ({store, tabsStore, documentName, documentId}) => {
    const [form] = Form.useForm();
  
    const onHighlightBlock = (field: FormInputFieldData, inputId: string) => {
        const docStore = tabsStore.documentStores[documentId];

        if (field.pId) {
            const nextBlockToHighlight = docStore?.getNextBlockToHighlight(inputId);
            if (nextBlockToHighlight) {
                highlightProvidedBlock(docStore, nextBlockToHighlight, inputId);
                highlightProvidedBlock(docStore, nextBlockToHighlight, inputId);
            }
        }
       
        highlightBlockHandler(docStore, field, inputId);
    };

    const getGearIcon = (inputParams: FormInputParams) => {
        console.log(inputParams);
        return <div/>;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onAction = (id: string, action: string, value?: any) => {
        console.log(id, action, value);
    };

    
    const highlightProvidedBlock = (documentStore: DocumentVisualStore, field: FormInputFieldData, inputId: string) => {
        if (documentStore) {
            documentStore.highlightBlock(field, inputId, field != null);
        }
    };

    
    const highlightBlockHandler = (documentStore: DocumentVisualStore, field: FormInputFieldData, inputId: string) => {
        if (documentStore) {
            const nextBlockToHighlight = documentStore.getNextBlockToHighlight(inputId);
            if (inputId === documentStore.highlightedInputId && !nextBlockToHighlight) {
                documentStore.highlightBlock(undefined, undefined);
            } else {
                const selectedData = nextBlockToHighlight ?? field;
                documentStore.highlightBlock(selectedData, inputId, true);
            }
        }
    };

    return (
        <Form form={form} className="alpha-application-form" >
            <DocumentHeaderRenderer 
                onTogglePageBlocks={() => console.log()} 
                showingBlocks
                handleClick={(id) => console.log(id)} 
                dataId={'d1'}
            />
            <div className="alpha-form-controls">
                <div className="scan-results-flex-container">
                    <Collapse 
                        bordered={false} 
                        expandIconPosition="left" 
                        defaultActiveKey={'section-general'}
                    >
                        <Panel 
                            className="alpha-section-header" 
                            header={<h1 data-id="section-general" className="alpha-form-header">General information</h1>} 
                            key={'section-general'}
                        >
                            <FormItem
                                className="alpha-document-title"
                                label={<Tooltip title="Document Name">Document Name</Tooltip>}
                                colon={false}
                                labelCol={{ span: 24 }}
                            >
                                <Input disabled value={documentName} title={'packageName'} />
                            </FormItem>
                            {store.inputs.map(input=> 
                                <ScanResultsInput 
                                    key={input.id} 
                                    inputParams={{id: input.id, name: input.name, type: 'string', value: input.value, meta: input.meta}}
                                    form={form}
                                    onHighlightBlock={onHighlightBlock}
                                    getGearIcon={getGearIcon}
                                    onAction={onAction}
                                />)}
                        </Panel>
                    </Collapse>
                </div>
            </div>
            
        </Form>
    );

};

export default observer(ScanResultsRenderer);