/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Table, Layout, Tooltip, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ProjectsStore } from '../../common/stores';
import { ProjectForCurrentUser } from '../../common/services/types';
import AddProjectDialog from './ProjectCreateDialog';
import ImportProjectDialog from './ImportProjectDialog';
import { LoadingIndicator } from '../../common/components/LoadingIndicator';


const { Content } = Layout;
type Props = {
    projectsStore: ProjectsStore
};

const ProjectsList: React.FC<Props> = ({projectsStore}) => {
    const [isProjectDialogVisible, setIsProjectDialogVisible] = React.useState(false);
    const [isImportProjectVisible, setIsImportProjectVisible] = React.useState(false);
   
    const columns: ColumnProps<ProjectForCurrentUser>[] = [
        {
            title: 'Projects',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: ProjectForCurrentUser) => (
                <span data-id-cells="name">{record.name}</span>
            )
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            render: (text: string, record: ProjectForCurrentUser) => (
                <>
                    <span data-id-cells="owner">{projectsStore.getUserNameById(record.owner)}</span>
                    <span data-id-cells="is-active" style={{color: 'red'}}>{projectsStore.isUserDeleted(record.owner) ? ' (Inactive)' : ''}</span>
                </>
            )
        },
        {
            title: 'Actions',
            width: 200,
            render: (record: ProjectForCurrentUser) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit project"
                        title="Edit"
                        size="small"
                        type="link"
                        onClick={() => {
                            projectsStore.setEditableProject(record.id);
                            setIsProjectDialogVisible(true);
                        }}
                    >
                        <Tooltip title="Edit project" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Button
                        data-id-cells="Export project"
                        title="Export"
                        size="small"
                        type="link"
                        onClick={() => {
                            projectsStore.exportProject(record.id);
                        }}
                    >
                        <Tooltip title="Export project" placement="bottom">
                            <i className="alpha-icon md export" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this project?"
                        onConfirm={() => {
                            projectsStore.deleteProject(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button data-id-cells="Delete project" title="Delete" size="small" type="link">
                            <Tooltip title="Delete project" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                </div>
            )
        }
    ];
    if (projectsStore.isLoading) {
        return <LoadingIndicator/>; 
    }
    return (
        <Layout className="screen-size tasks-types-layout layout-with-table">
            <div className='header-wrapper'>
                <div className='title-wrapper'>
                    <div className="header-title">Projects</div>
                </div>
                <Button data-id="button-add-type" style={{marginRight:8}} type="primary" size="large" onClick={() => {
                    setIsImportProjectVisible(true);
                }}>Import project</Button>
                <Button data-id="button-add-type" type="primary" size="large" onClick={() => {
                    setIsProjectDialogVisible(true);
                }}>Add project</Button>
            </div>
            <AddProjectDialog projectsStore={projectsStore} isDialogVisible={isProjectDialogVisible} closeDialog={() => setIsProjectDialogVisible(false)}/>
            <ImportProjectDialog projectsStore={projectsStore} isDialogVisible={isImportProjectVisible} closeDialog={() => setIsImportProjectVisible(false)}/>
            <Layout style={{overflow: 'auto'}}>
                <Content>
                    <Table
                        data-id="table-tasks-templates-list"
                        className="alpha-table"
                        columns={columns}
                        dataSource={projectsStore.administrableProjects}
                        rowKey={(r) => r.id}
                        pagination={false}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(ProjectsList);