import * as React from 'react';
import { useStore } from '../../common/stores';
import { observer } from 'mobx-react-lite';
import NotificationList from '../../common/components/NotificationList';

const NotificationsPage: React.FC = () => {
    const { notificationStore } = useStore();
    return (<NotificationList store={notificationStore} fullScreen />);
};

export default observer(NotificationsPage);