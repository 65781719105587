import { FileEditorConfigForm } from './FileEditorConfig';
import { SelectEditorConfigForm } from './SelectEditorConfig';
import { TextEditorConfigForm } from './TextEditorConfig';
import { DateEditorConfigForm } from './DateEditorConfig';

export default {
    TextEditorConfig: TextEditorConfigForm,
    SelectEditorConfig: SelectEditorConfigForm,
    FileEditorConfig: FileEditorConfigForm,
    DateEditorConfig: DateEditorConfigForm
};