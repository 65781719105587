import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../common/stores';
import ProjectsList from '../components/ProjectsList';

const ProjectPage: React.FC = () => {
    const rootContext = useStore();
    return (
        <ProjectsList projectsStore={rootContext.projectsStore}/>
    );
};

export default observer(ProjectPage);