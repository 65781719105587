import { Checkbox, Form, Input } from 'antd';
import React from 'react';

export const FileEditorConfigForm: React.FC = () => {
    return (
        <>
            <Form.Item name="extension" label="Extension">
                <Input />
            </Form.Item>
            <Form.Item
                name="allowMultiple"
                wrapperCol={{offset: 8}}
                valuePropName="checked">
                <Checkbox>Allow multiple</Checkbox>
            </Form.Item>
        </>
    );
};