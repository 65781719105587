import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Layout } from 'antd';
import UsersManagerStore from '../stores/UsersManagerStore';
import { LoadingIndicator } from '../../common/components/LoadingIndicator';
import WrappedAddUserDialog from './AddUserDialog';
import EditUserDialog from './EditUserDialog';
import UsersList from './UsersList';

type Props = {
    store: UsersManagerStore
};

export const Users: React.FC<Props> = ({store}) => {
    
    React.useEffect(() => {
        store!.getUsers().catch((err) => console.error(err));
    }, [store]);

    const layout = (
        <Layout className="screen-size layout-with-table">
            <WrappedAddUserDialog store={store}/>
            {store!.isEditUserDialogVisible && <EditUserDialog store={store}/>}
            <div className='header-wrapper'>
                <div className='title-wrapper'>
                    <div className="header-title">User Management</div>
                </div>
                <Button 
                    key="user-manager-add-user-button" 
                    data-id="user-manager-add-user-button" 
                    type="primary"
                    size="large"
                    onClick={() => store!.setIsAddUserDialogVisible(true)}
                >
                        Add user
                </Button>
            </div>
            {/* <LayoutHeader 
                title="User management"
                helpMessage="You can manage users and their roles here."
                buttons={[
                    <Button 
                        key="user-manager-add-user-button" 
                        data-id="user-manager-add-user-button" 
                        type="primary"
                        size="large"
                        onClick={() => store!.setIsAddUserDialogVisible(true)}
                    >
                        Add user
                    </Button>
                ]} /> */}
            <Layout.Content>
                {/* {store!.selectedUser ? <WrappedUserContent UsersManager={store}/> : <></>} */}
                <UsersList store={store!} />
            </Layout.Content>
        </Layout>
    );
    if (store!.isLoading) {
        return <LoadingIndicator/>;
    } else {
        return layout;
    }

};

export default observer(Users);
