import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../common/stores';
import TaskTypesList from '../components/TaskTypesList';
import { useTaskTypesStore } from '../stores/context';

const TaskTypesPage: React.FC = () => {
    const context = useTaskTypesStore();
    const rootContext = useStore();
    return (
        <TaskTypesList store={context.store} projectsStore={rootContext.projectsStore}/>
    );
};

export default observer(TaskTypesPage); 