import * as React from 'react';
import { observer } from 'mobx-react-lite';
import ScanResultsRenderer from '../components/ScanResultsRenderer';
import XmlDocumentPreview from '../../documents/components/XmlDocumentPreview';
import { useStore } from '../../common/stores';

const AnalysisPage: React.FC = () => {
    const context = useStore();
    const {tabsStore} = context;
    const selectedTab = tabsStore.selectedTab;
    const store = tabsStore.analysisStores[selectedTab!.id];
    const {documentId} = selectedTab!.metadata;
    return (
        <div className={'alpha-document-container'} style={{ height: 'calc(100vh - 85px)' }}>
            <div className="leftColumnContainer" style={{width: 520, flex: '0 0 520px', overflowX: 'hidden'}}>
                <div className="alpha-application-form-container">
                    <ScanResultsRenderer tabsStore={tabsStore} documentId={documentId} store={store} documentName={selectedTab!.metadata.documentName}/>
                </div>
            </div>
            <div className="rightColumnContainer">
                <XmlDocumentPreview store={tabsStore.documentStores[documentId]}  documentId={selectedTab!.metadata.documentId}/>
            </div>
        </div>
    );
};

export default observer(AnalysisPage);

