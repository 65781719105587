export enum AppPermissions {
    CanAccessAdministration = 'administration/open',
}

export enum AppRoles {
    admin = 'admin',
    user = 'user'
} 

const permissions = {
    admin: 
        [
            AppPermissions.CanAccessAdministration
        ],        
    user: 
        [   

        ]
};

export default permissions;