/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */
import { ProjectsService } from '../services';
import { ProjectForCurrentUser, PackageListItemModel, PackageListItemType, PackageResult, UserProfile } from '../services/types';
import { action, observable, runInAction, makeObservable, computed } from 'mobx';
import LocalStorageWorker from '../misc/StorageHelper';
import { message } from 'antd';
import { TaskPushClient} from '../../tasks/services';
import ErrorStore from './ErrorStore';

export default class ProjectsStore {
    projectsNames: { value: string; text: string }[];

    projects: ProjectForCurrentUser[] = [];

    selectedProject: ProjectForCurrentUser | undefined = undefined;

    editableProject: string | null;

    projectsForTasks: ProjectForCurrentUser[];

    allUsers: UserProfile[] = [];

    project: ProjectForCurrentUser | null;

    projectIsSelected: boolean = false;

    isLoading: boolean = false;

    localStorageHelper: LocalStorageWorker;

    packageSearchKey: string = '';

    docUploadAction: string = '';

    folderUploadAction: string = '';

    currentProjectId: string | undefined;

    newPackages: PackageListItemModel[] = [];

    allProjectPackages: PackageListItemModel[] = [];

    isPushServiceConnected: boolean = true;

    isProjectUpdateLoading: boolean = false;

    constructor(public service: ProjectsService, private errorStore: ErrorStore, localStorageHelper: LocalStorageWorker) {
        makeObservable<ProjectsStore, 'loadAllUsers'>(this, {
            projects: observable,
            projectsForTasks: observable,
            allUsers: observable,
            projectIsSelected: observable,
            isLoading: observable,
            packageSearchKey: observable,
            docUploadAction: observable,
            folderUploadAction: observable,
            selectedProject: observable,
            currentProjectId: observable,
            newPackages: observable,
            allProjectPackages: observable,
            isPushServiceConnected: observable,
            isProjectUpdateLoading: observable,
            adminInProject: computed,
            uploadPackage: action.bound,
            uploadPackageSet: action.bound,
            selectProject: action.bound,
            loadProjects: action,
            getPackagesFromSet: action.bound,
            loadAllUsers: action.bound,
            deleteProject: action.bound,
            setIsLoading: action,
            administrableProjects: computed,
            filteredUsers: computed,
            setProjectUpdateLoading: action
        });

        this.localStorageHelper = localStorageHelper;
        TaskPushClient.PushClient.ConnectionStatusSubject.subscribe((connected: boolean) => {
            runInAction(() => this.isPushServiceConnected = connected);
        });
        this.init();
    }

    get editableProjectName () {
        return this.projects.find(p=> p.id === this.editableProject)?.name;
    }

    get editableProjectOwner () {
        return this.projects.find(p=> p.id === this.editableProject)?.owner;
    }

    get adminInProject() {
        return this.projects.some(p=> p.isAdmin);
    }

    get administrableProjects() {
        return this.projects.filter(p=> p.isAdmin);
    }

    get filteredUsers() {
        const hiddenUsers = this.allUsers.filter(u=> u.isDeleted && u.userId !== this.editableProjectOwner).map(h=> h.userId);
        return this.allUsers.filter(f=> !hiddenUsers.includes(f.userId));
    }

    async init() {
        this.setIsLoading(true);
        const promises = [this.loadProjects(), this.loadAllUsers()];
        await Promise.all(promises);
        this.setIsLoading(false);
    }

    setProjects(projects: ProjectForCurrentUser[]) {
        this.projects = projects;
    }
    
    uploadPackage(formData: FormData, projectId: string) {
        return this.service.uploadPackage(formData, projectId);
    }

    uploadPackageSet(formData: FormData, projectId: string) {
        return this.service.uploadPackageSet(formData, projectId);
    }

    selectProject(project: ProjectForCurrentUser | undefined) {
        this.selectedProject = project;
    }

    setEditableProject(id: string | null) {
        this.editableProject = id;
    }

    async exportProject(projectId: string) {
        await this.service.exportProject(projectId);
    }

    async deleteProject(projectId: string) {
        await this.service.deleteProject(projectId);
        this.loadProjectsForTask();
        this.loadProjects();
        const index = this.projects.findIndex(p=> p.id === projectId);
        const projects = this.projects.slice();
        projects.splice(index, 1);
        this.projects = projects;
    }


    async importProject(formValues: any) {
        const formData = new FormData();
        formData.append('file', formValues.file);
        formData.append('projectName', formValues.name);
        formData.append('projectOwner', formValues.projectOwner);
        const result = await this.service.importProject(formData);
        result
            .map(() => { 
                this.loadProjects();
                this.loadProjectsForTask();
            })
            .mapErr(err=>  {
                this.errorStore.addError(err.data);
            });
        return result;
    }

    async loadProjects() {
        const resp = await this.service.getProjectsForCurrentUser();
        resp.map(res=> {
            if (res && res.length) {
                runInAction(() => this.setProjects(res));
                this.projectsNames = res.map((r) => {
                    return { text: r.name, value: r.id }; 
                });
                runInAction(() => {
                    // eslint-disable-next-line max-len
                    this.currentProjectId = this.projects.length && this.projects[0].id || undefined;
                });
            } else {
                message.warning('There are no projects assigned to current user, no contract can be created', 5);
            }
        }).mapErr(err=>  {
            this.errorStore.addError(err.data);
        });

        return Promise.resolve();
    }

    async loadProjectsForTask() {
        const resp = await this.service.getProjectsForCurrentUser();
        resp.map(r=> {
            this.projectsForTasks = r;
        }).mapErr(err => {
            this.errorStore.addError(err.data);
        });
    }

    addItemsToStorage(key: string, value: string) {
        this.localStorageHelper.add(key, value);
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    setProjectUpdateLoading(isLoading: boolean) {
        this.isProjectUpdateLoading = isLoading;
    }

    getItemFromStorage(key: string) {
        return this.localStorageHelper.get(key);
    }

    getPackageIndexDate(packageId: string) {
        let pkg = this.allProjectPackages.find(p => p.id === packageId);
        if (!pkg) {
            const sets = this.allProjectPackages.filter(p => p.type === PackageListItemType.PackageSet);
            let setPkg: PackageResult | undefined = undefined;
            for (let set of sets) {
                setPkg = set.packages ? set.packages.find(p => p.id === packageId) : undefined;
                if (setPkg) {
                    break;
                }
            }

            if (setPkg) {
                return setPkg.indexDate;
            }
        }

        return pkg ? pkg.indexDate : '';
    }

    async createUpdateProject(name: string, owner: string, closeDialog: () => void) {
        this.setProjectUpdateLoading(true);
        const resp = await this.service.createUpdateProject(this.editableProject, name, owner);
        resp.map(async() => { 
            this.setEditableProject(null);
            const loadProjects = [this.loadProjects(), this.loadProjectsForTask()];
            await Promise.all(loadProjects);
            closeDialog();
        })
            .mapErr(err=>  {
                this.errorStore.addError(err.data);
            });
        this.setProjectUpdateLoading(false);
    }
    
    async getPackagesFromSet(packageSetId: string) {
        try {
            const resp = await this.service.getPackageSet(packageSetId);
            return resp;
        } catch (err) {
            console.log(err);
        }
        return undefined;
    }

    getUserNameById(id?: string) {
        const user = this.allUsers.find(u=> u.userId === id);
        return user ? `${user?.firstName} ${user?.lastName}` : '';
    }

    isUserDeleted(id: string) {
        const user = this.allUsers.find(u=> u.userId === id);
        return user?.isDeleted;
    }

    async getUsersForProject(projectId: string) {
        const users = await this.service.getUsersInProject(projectId);
        return users;
    }

    private async loadAllUsers() {
        const users = await this.service.getAllUsers();
        this.allUsers = users;  
    }
}