import { Button, Form, Modal, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import DashboardStore from '../stores/DashboardStore';
import WidgetConstructorStore from 'src/modules/administration/stores/WidgetConstructorStore';

type Props = {
    store: DashboardStore;
    widgetStore: WidgetConstructorStore;
    isVisible: boolean;
    closeDialog: () => void
};
const AddWidgetDialog: React.FC<Props> = ({ closeDialog, store, isVisible, widgetStore }) => {
    const [form] = Form.useForm();    
    const handleSubmit = () => {
        form.validateFields().then(async(vals) => {
            store.activateWidget(vals.id);
            handleClose();
        });
    };

    const handleClose = () => {
        form.resetFields();
        closeDialog();
    };
    return (
        <Modal
            title='Add widget'
            visible={isVisible}
            className="alpha-modal comment-in-modal"
            closable={false}
            footer={[
                <Button 
                    data-id="query-cancel-button"
                    key="query-cancel-button" 
                    className="light"
                    onClick={handleClose}
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="query-save-button"
                    key="query-save-button" 
                    type="primary"
                    htmlType="submit"
                    form="query-modal-form"
                >
                    Add
                </Button>
            ]}
        >
            <Form form={form} onFinish={handleSubmit} className="alpha-form" layout='vertical' id="query-modal-form">
                <FormItem
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Widget</span>}
                    name="id"
                    rules={[{ required: true, message: 'Widget is required' }]}

                >
                    <Select options={[...widgetStore.widgets, ...widgetStore.groupedWidgets].map(w=> ({value: w.id, label: w.name}))}/>
                </FormItem>
            </Form>
        </Modal>
    );
};
export default observer(AddWidgetDialog);
