import { TaskTemplate } from '../types';
import appClient from '../../common/services/AppClient';
import { UserModel } from '../../tasks/types';

export default class TaskTemplateService {
    getTaskTemplates(projectId?: string) {
        let url = process.env.REACT_APP_TASKS_URL + 'tasktemplates';
        if (projectId) {
            url += '/' + projectId;
        }
        return appClient.get<TaskTemplate[]>(url);
    }

    getAccessibleTaskTemplates() {
        const url = process.env.REACT_APP_TASKS_URL + 'tasktemplates/user';
        return appClient.get<TaskTemplate[]>(url);
    }

    createTaskTemplate(formData: FormData) {
        const url = process.env.REACT_APP_TASKS_URL + 'tasktemplates/new';
        return appClient.post(url, formData);
    }

    updateTaskTemplate(formData: FormData, templateId: string) {
        const payload = { templateId, ...formData };

        const url = process.env.REACT_APP_TASKS_URL + 'tasktemplates/update';
        return appClient.patch(url, payload);
    }

    deleteTaskType(templateId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `tasktemplates/delete/${templateId}`;
        return appClient.delete(url);
    }

    getUsersInProject(projectId: string) {
        const url = process.env.REACT_APP_TASKS_URL + `users/project/${projectId}?includeRoles=false`;
        return appClient.get<UserModel[]>(url);
    }
}