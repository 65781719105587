import { action, makeObservable, observable } from 'mobx';

export type AnalisedDocInput = {
    id: string;
    name: string;
    value: string;
    meta: {}
};

export default class AnalyseVisualStore {
    inputs: AnalisedDocInput[] = [];

    constructor() {
        makeObservable<AnalyseVisualStore>(this, {
            inputs: observable,
            setInputs: action.bound,
        });
    }

    setInputs(inputs: AnalisedDocInput[]) {
        this.inputs = inputs;
    }
}