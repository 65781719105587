import { message } from 'antd';
import { ClientActionData, StringKeyDictionary } from '../types/Actions';

export default class ClientActionRunner {

    executeAction(args: ClientActionData) {
        switch (args.actionType) {
        case 'OpenLink':
            this.handleOpenLink(args.fields);
            break;
        default:
            message.error(`Unsupported action type '${args.actionType}'`);
            break;
        }
    }

    private handleOpenLink(fields: StringKeyDictionary) {
        window.open(fields.url, '_blank', 'noreferrer');
    }
}