import * as React from 'react';
import { observer } from 'mobx-react-lite';
import TaskStatusesVisualStore from '../stores/TaskStatusesVisualStore';
import { Form, Input, Tree } from 'antd';
import { FlowEditorSelection } from '../types';

type Props = {
    store: TaskStatusesVisualStore;
    selection: FlowEditorSelection
};

const FlowNodeEditor: React.FC<Props> = ({ store, selection }) => {
    return (
        <>
            <Form.Item label={<span className="dialog-field-label">Name</span>} name='name'>
                <Input data-id="input-name" readOnly />
            </Form.Item>
            <Form.Item label={<span className="dialog-field-label">Code</span>} name='code'>
                <Input data-id="input-code" readOnly />
            </Form.Item>
            <Form.Item
                label={<span className="dialog-field-label">Allowed actions</span>}
                name='allowedActions'
                valuePropName='checkedKeys'
                trigger='onCheck'
            >
                <Tree
                    checkable
                    style={{ overflowY: 'scroll', marginLeft: '-24px' }}
                    treeData={store.actionDefinitions.map(x => ({ title: x.title || x.name, key: x.id }))}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onCheck={(arg: string[]) => store.taskStatuses.find(x => x.id === selection.item.id)!.allowedActions = arg}
                />
            </Form.Item>
        </>
    );
};

export default observer(FlowNodeEditor);