import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TaskViewVisualStore } from '../stores';
import { Button, Modal } from 'antd';
import ActionInputForm from './ActionInputForm';

type Props = {
    taskId: string;
    actionId: string;
    store: TaskViewVisualStore
};

const ActionInputDialog: React.FC<Props> = ({ taskId, actionId, store }) => {
    return (
        <Modal
            title={store.seletedActionModel?.title ?? store.seletedActionModel?.name ?? 'Parameters'}
            open={store.selectedAction !== undefined}
            className="alpha-modal comment-in-modal"
            closable={false}
            footer={[
                <Button
                    data-id="execute-action-cancel-button"
                    key="execute-action-cancel-button"
                    className="light"
                    onClick={() => store.setSelectedAction(undefined)}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="execute-action-button"
                    key="execute-action-button"
                    type="primary"
                    htmlType="submit"
                    form="execute-action-form"
                    loading={store.isActionLoading}
                >
                    Execute
                </Button>
            ]}
        >
            <ActionInputForm taskId={taskId} actionId={actionId} store={store} />
        </Modal>
    );
};

export default observer(ActionInputDialog);