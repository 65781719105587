import * as React from 'react';
import { Route } from 'react-router';
import NotificationsPage from '../screens/NotificationsPage';

const notificationRoute = () => {
    return (
        <Route
            path={'/notifications'}
            element={
                <NotificationsPage/>
            }
        />
    );
};

export default notificationRoute;