import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Input, Select, Form } from 'antd';
import TaskStatusesVisualStore from '../stores/TaskStatusesVisualStore';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { Utils } from 'src/modules/common/misc/Utils';

type Props = {
    store: TaskStatusesVisualStore
};

const TaskStatusEditDialog: React.FC<Props> = ({ store }) => {
    const [form] = useForm();
    React.useEffect(() => {
        form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.statusEditDialogVisible]);
    if (!store.selectedTaskStatus) {
        return null;
    }

    const handleSubmit = async () => {
        form.validateFields().then((values) => {
            store.updateTaskStatus(values.name, values.code);
        }).catch((err) => {
            console.log(err);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statusNameValidator = (rule: any, value: any, callback: any) => { 
        let statusesToValidate = store!.taskStatuses.filter(s=> s.isSubtaskStatus === store.selectedTaskStatus?.isSubtaskStatus).map(t => t.name.toLowerCase());
        if (value && statusesToValidate.includes(value.toLowerCase()) && store.selectedTaskStatus?.name !== value) {
            callback('Status with the same name already exists');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statusCodeValidator = (rule: any, value: any, callback: any) => { 
        let statusesToValidate = store!.taskStatuses.filter(s=> s.isSubtaskStatus === store.selectedTaskStatus?.isSubtaskStatus).map(t => t.code?.toLowerCase());
        if (value && statusesToValidate.includes(value.toLowerCase()) && store.selectedTaskStatus?.code !== value) {
            callback('Status with the same code already exists');
        } else {
            callback();
        }
    };


    return (
        <Modal
            data-id-type="modal-window"
            data-id-name={`Edit status ${store.selectedTaskStatus!.name}`}
            className="alpha-portal-dialog"
            title={`Edit status ${store.selectedTaskStatus!.name}`}
            open={store.statusEditDialogVisible}
            closable={false}
            centered
            footer={[
                <Button
                    data-id="button-edit-status-cancel"
                    size="large"
                    className="light"
                    key="task-status-save"
                    onClick={() => {
                        store.selectStatus(undefined);
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="button-edit-status-save"
                    type="primary"
                    size="large"
                    key="task-status-rename"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            ]}
        >   
            <Form
                layout="vertical"
                data-id="create-task-type-dialog-form"
                form={form}
                className='alpha-form'
                initialValues={
                    {
                        assginedUsers: store.currentStatusUsers ? store.currentStatusUsers.map(u => u.id) : undefined,
                        name: store.selectedTaskStatus.name,
                        code: store.selectedTaskStatus.code
                    }
                }
            >
                <FormItem
                    label={<span className="dialog-field-label">Name</span>}
                    name='name'
                    rules={[
                        { required: true, message: 'Name is required', whitespace: true },
                        { validator: statusNameValidator }
                        
                    ]}>
                    <Input data-id="input-Name" />
                </FormItem>
                <FormItem
                    label={<span className="dialog-field-label">Assigned users</span>}
                    name='assigneUsers'
                >
                    <Select
                        mode="multiple"
                        options={store.usersInProject ? store.usersInProject.map(s=> ({label: s.userName, value: s.id})) : []}
                        filterOption={Utils.filterOption}
                    />
                </FormItem>
                <FormItem
                    label={<span className="dialog-field-label">Code</span>}
                    name='code'
                    rules={[ { validator: statusCodeValidator }]}
                >
                    <Input data-id="input-code" />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default observer(TaskStatusEditDialog);