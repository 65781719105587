import * as React from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import TaskTypesService from '../services/TaskTypesService';
import TaskTypesVisualStore from './TaskTypesVisualStore';
import { ErrorStore, ProjectsStore, useStore } from '../../common/stores';
import { useTasksStore } from 'src/modules/tasks/stores/context';
import TasksRootStore from 'src/modules/tasks/stores/TasksRootStore';

function createStore(errorStore: ErrorStore, projectsStore: ProjectsStore, tasksRootStore: TasksRootStore) {

    const service = new TaskTypesService();
    const store = new TaskTypesVisualStore(service, projectsStore, tasksRootStore, errorStore);
    return {
        store,
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const tasksRootStore = useTasksStore().tasksRootStore;
    const store = useLocalObservable(() => createStore(root.errorStore, root.projectsStore, tasksRootStore));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useTaskTypesStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    
    return store;
};