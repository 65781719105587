import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ErrorStore, ProjectsStore } from '../../common/stores';
import { ALL_PROJECTS } from '../../tasks/screens/TasksPage';
import { TaskService } from '../../tasks/services';
import { GlobalAdministrationService } from '../services/GlobalAdministrationService';
import { DashboardWidgetType } from '../types/DashboardWidget';
import { DashboardGroupedWidgetMeta, DashboardWidgetMeta, DashboardWidgetMetaResult } from '../types/DashboardWidgetMeta';
import { MetadataDefinition } from '../types/Metadata';
import { TaskStatus } from '../../task_statuses/types';
import { TaskType } from '../../task_types/types';
import TaskTypesService from '../../task_types/services/TaskTypesService';

export default class WidgetConstructorStore {
    selectedProjectId: string = ALL_PROJECTS;

    widgets: DashboardWidgetMeta[] = [];

    groupedWidgets: DashboardGroupedWidgetMeta[] = [];

    metadata: MetadataDefinition[] = [];

    fitleredMetadata: MetadataDefinition[] = [];

    currentWidgetType: DashboardWidgetType | null = null;

    editableWidget: DashboardWidgetMetaResult | null;

    fieldNameDict: {[key: number]: string} = {};

    taskStatuses: TaskStatus[] = [];

    taskTypes: TaskType[] = [];

    isLoading: boolean = false;

    constructor(private projectStore: ProjectsStore, private adminService: GlobalAdministrationService, 
                private tasksService: TaskService, private taskTypesService: TaskTypesService, private errorStore: ErrorStore){
        makeObservable<WidgetConstructorStore>(this, {
            selectedProjectId: observable,
            widgets: observable,
            groupedWidgets: observable,
            metadata: observable,
            fitleredMetadata: observable,
            editableWidget: observable,
            currentWidgetType: observable,
            fieldNameDict: observable,
            taskTypes: observable,
            isLoading: observable,
            filteredTaskTypes: computed,
            setSelectedProjectId: action.bound,
            setSelectedWidgetType: action.bound,
            setEditableWidget: action.bound,
            unselectProject: action,
            setFieldNameDict: action,
            removeFieldNameDict: action,
            getMetadata: action,
            setIsLoading: action
        });
    }

 
    get filteredTaskTypes() {
        return this.selectedProjectId === ALL_PROJECTS ? 
            this.taskTypes.map(f=> ({id: f.id, label: `${f.name} (${this.getProjectIdLabel(f.projectId)})`})) : 
            this.taskTypes.filter(t=> t.projectId === this.selectedProjectId).map(f=> ({id: f.id, label: f.name}));
    }

    get filteredTaskStatuses() {
        return this.taskStatuses.filter(t=> this.filteredTaskTypes.map(x=> x.id).includes(t.typeId));
    }

    getProjectIdLabel (projectId: string) {
        // TODO: This check is done because there can be task type of deleted project.
        const project = this.projectStore.projects.find(p=> p.id === projectId);
        if (project) {
            return project.name;
        }
        return 'Deleted project';
    }

    async init() {
        this.setIsLoading(true);
        const promises = [this.getWidgets(), this.getMetadata()];
        await Promise.all(promises);
        this.setIsLoading(false);
    }

    setIsLoading(val: boolean) {
        this.isLoading = val;
    }

    async getWidgets() {
        const widgets = await this.adminService.getDashboardWidgetsMeta() || [];
        runInAction(() => this.widgets = widgets.filter(w=> w.widgetType !== DashboardWidgetType.Grouped) as DashboardWidgetMeta[]);
        this.groupedWidgets = widgets.filter(w=> w.widgetType === DashboardWidgetType.Grouped) as DashboardGroupedWidgetMeta[];
    }

    async getMetadata ()  {
        const resp = await this.adminService.getMetadataDefinitionsForProject();
        resp.map((metadata) => {
            runInAction(() => {
                this.metadata = metadata;
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    async createUpdateWidget(formData: {}) {
        Object.assign(formData, {id: this.editableWidget?.id});
        await this.adminService.createUpdateDashboardWidget(formData);
        this.setEditableWidget(null);
        this.getWidgets();
    }

    async createUpdateGroupedWidget(formData: {}) {
        Object.assign(formData, {id: this.editableWidget?.id});
        await this.adminService.createUpdateDashboardGroupedWidget(formData);
        this.setEditableWidget(null);
        this.getWidgets();
    }

    async setSelectedProjectId(projectId: string) {
        this.selectedProjectId = projectId;
        this.fitleredMetadata = this.metadata.filter(m=> m.projectId === projectId);
    }
    setSelectedWidgetType(currentWidgetType: DashboardWidgetType | null) {
        this.currentWidgetType = currentWidgetType;
    }

    async getTaskTypes() {
        if (this.projectStore.projects) {
            const resp = await this.taskTypesService.getTaskTypesForProject();
            resp.map(t=> {
                runInAction(() => {
                    this.taskTypes = t;
                });
            }).mapErr((err) => this.errorStore.addError(err.data));
        }
    }

    async getTaskTypesFor(projectId: string) {
        const resp = await this.taskTypesService.getTaskTypesForProject(projectId);
        resp.map(t=> {
            runInAction(() => {
                this.taskTypes = t;
            });
        }).mapErr((err) => this.errorStore.addError(err.data));
    }
    
    setEditableWidget(id: string | null) {
        if (id === null) {
            this.editableWidget = null;
            return;
        }
        const widget = [...this.widgets, ...this.groupedWidgets].find(w=> w.id === id)!;
        this.setSelectedProjectId(widget.projectId);
        this.setSelectedWidgetType(widget.widgetType);
        this.editableWidget = widget;
    }

    async deleteWidget(id: string) {
        await this.adminService.deleteWidget(id);
        this.getWidgets();
    }

    unselectProject() {
        this.selectedProjectId = ALL_PROJECTS;
    }

    setFieldNameDict(index: number, value: string) {
        this.fieldNameDict[index] = value;
    }

    removeFieldNameDict(index: number) {
        const newDict = {};
        for(let key of  Object.keys(this.fieldNameDict)) {
            if (Number(key) > index) {
                newDict[Number(key) - 1] = this.fieldNameDict[Number(key)];
            } else if (Number(key) < index) {
                newDict[Number(key)] = this.fieldNameDict[Number(key)];
            }
        }
        this.fieldNameDict = newDict;
    }

    clearFieldTypeDict() {
        this.fieldNameDict = {};
    }

    async getTaskStatuses() {
        const resp = await this.tasksService.getTaskStatuses();
        resp.map(r=> {
            runInAction(() => {
                this.taskStatuses = r;
            });
        });
    }

    getMetaById(id: string) {
        return this.metadata.find(m=> m.id === id);
    }
}