import { useRoutes } from 'react-router';
import TasksPage from '../screens/TasksPage';
import * as React from 'react';
import  * as docsContext  from '../../documents/stores/context';

type Props = {
    isSideMenuCollapsed: boolean
};

const TasksRoute: React.FC<Props> = ({isSideMenuCollapsed}) => {
    const reactElement =  
        <docsContext.DocumentsStoreProvider>
            <TasksPage isSideMenuCollapsed={isSideMenuCollapsed}/>  
        </docsContext.DocumentsStoreProvider>;
    const Page = () => useRoutes([
        '/tasks/project/:projectId/widget/:widgetId', 
        '/tasks/project/:projectId/widget/:widgetId/value/:value',
        '/tasks/project/:projectId/activity/:activityType', 
        '/tasks/',
        '/tasks/:taskId']
        .map(path => ({path, element: reactElement})));
    return (
        <Page/>
    );
};
  
export default TasksRoute;