import { Collapse, Row, Col, Skeleton, Select, Tag, DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/misc/Utils';
import TaskViewVisualStore, { AccessType } from '../stores/TaskViewVisualStore';
import ChangePrivacyDialog from './ChangePrivacyDialog';
import TaskUserProfilePicture from './TaskUserProfilePicture';
import * as moment from 'moment';
import { TaskCommentsStore } from '../stores';
import { useNavigate } from 'react-router';

type Props = {
    store: TaskViewVisualStore;
    commentStore: TaskCommentsStore;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getExpandIcon:  (panelProps: any) => JSX.Element;
    disabledDate: (current: moment.Moment) => boolean;
    isTaskClosed: boolean
};
const DetailsSection: React.FC<Props> = ({ store, getExpandIcon, disabledDate, isTaskClosed }) => {
    const [isPrivacyDialogVisible, setIsPrivacyDialogVisible] = React.useState(false);
    const navigate = useNavigate();

    const taskPreview = store.taskPreview!;

    const handleRelatedTaskClick = () => {
        if (store.getRelatedTask()) {
            navigate(`/tasks/${store.getRelatedTask()}`);
        }
    };

    const getUserInacitivityLabel = () => {
        const isDeleted = store.userProfiles.some(u=> u.userId === store.taskPreview?.createdBy && u.isDeleted);
        return <span style={{color: 'red'}}>{isDeleted ? ' (Inactive)' : ''}</span>;
    };


    return (
        <div data-id-type="collapse-container" data-id-name="Details">
            <Collapse expandIcon={getExpandIcon} bordered={false} defaultActiveKey="details" className="alpha-task-section-collapse">
                <Collapse.Panel header="Details" key="details" className="alpha-task-section-collapse-panel">
                    <div className="dialog-section">
                        <Row className="inputs-container input-row">
                            <Col span={10}>
                                <span className="input-label">Owner</span>
                            </Col>
                            <Col span={14}>
                                <TaskUserProfilePicture
                                    store={store}
                                    style={{
                                        marginLeft: '-4px'
                                    }}
                                    className="sender-avatar"
                                    userName={Utils.parseTaskSender(taskPreview, store.allUsersFullNameResolver)}
                                    userId={Utils.parseTaskSenderId(taskPreview)}
                                    size={24}
                                />
                                <>
                                    <span style={{ maxWidth: 'calc(100% - 42px)', marginLeft: 10 }}>
                                        {Utils.parseTaskSender(store.taskPreview!, store.allUsersFullNameResolver)}
                                    </span>
                                    {getUserInacitivityLabel()}
                                </>
                            </Col>
                        </Row>
                        <Row className="inputs-container input-row">
                            <Col span={10}>
                                <span className="input-label">Task type</span>
                            </Col>
                            <Col span={14}>
                                {store.taskTypesLoading
                                    ? <div style={{ marginTop: 8, height: '16px' }}><Skeleton title={false} avatar={false} paragraph={{ rows: 1 }} active /></div>
                                    :
                                    <Select
                                        className={`borderless-select ${!taskPreview.taskType ? 'required' : ''}`}
                                        placeholder="To be defined"
                                        loading={store.taskTypesLoading}
                                        disabled={store.taskTypeChangeDisabled}
                                        style={{ marginLeft: '-12px' }}
                                        value={taskPreview.taskType}
                                        onChange={(option: unknown) =>
                                            store.updateField(store.taskPreview!.id, 'TaskType', option, () => store.handleTypeChange(option as string))
                                        }
                                    >
                                        {store.taskTypes && store.taskPreviewTypes.map(t => (
                                            <Select.Option key={t.id} value={t.id} style={{ display: t.isEnabled ? 'block' : 'none' }}>
                                                {t.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                }
                            </Col>
                        </Row>
                        <Row className="inputs-container input-row">
                            <Col span={10}>
                                <span className="input-label">Priority</span>
                            </Col>
                            <Col span={14}>
                                <Select
                                    disabled={store.taskFormLocked}
                                    style={{ marginLeft: '-12px' }}
                                    className={`priority-select ${taskPreview.priority}`}
                                    value={taskPreview.priority}
                                    onChange={(option: unknown) => store.updateField(taskPreview.id, 'Priority', option)}
                                >
                                    <Select.Option value="LOW"><Tag color="#9BA0AA" className="alpha-info-tag">Low</Tag></Select.Option>
                                    <Select.Option value="MEDIUM"><Tag color="#FADB14" className="alpha-info-tag">Medium</Tag></Select.Option>
                                    <Select.Option value="HIGH"><Tag color="#F5222D" className="alpha-info-tag">High</Tag></Select.Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row className="inputs-container input-row">
                            <Col span={10}>
                                <span className="input-label">Reminder</span>
                            </Col>
                            <Col span={14}>
                                <DatePicker
                                    disabled={store.taskFormLocked || !store.hasDueDate}
                                    className="borderless-picker icon-left"
                                    suffixIcon={<i className="alpha-icon xs calendar-icon" />}
                                    placeholder="Set reminder"
                                    disabledDate={disabledDate}
                                    value={Utils.formatDatePickerValue(taskPreview.reminder)}
                                    onChange={(m) => store.updateField(taskPreview.id, 'Reminder', m?.toISOString())}
                                    format={Utils.getDateFormat()}
                                />
                                {taskPreview.dueDate
                                    && Utils.convertDateStringToMoment(taskPreview.reminder) > Utils.convertDateStringToMoment(taskPreview.dueDate)
                                    ? (<div className="task-date-warning">Reminder is after due date</div>)
                                    : null}
                            </Col>
                        </Row>
                        <Row className="inputs-container input-row">
                            {isPrivacyDialogVisible && <ChangePrivacyDialog
                                store={store}
                                isDialogVisible={isPrivacyDialogVisible}
                                closeDialog={() => setIsPrivacyDialogVisible(false)}
                                task={taskPreview}
                            />}
                            <Col span={10}>
                                <span className="input-label">Access</span>
                            </Col>
                            <Col span={14}>
                                <span
                                    className={`task-privacy-change ${taskPreview.isSubtask || isTaskClosed
                                        ? 'disabled' : ''}`}
                                    onClick={() => store.taskFormLocked ? null : setIsPrivacyDialogVisible(true)}
                                >
                                    {AccessType[taskPreview.accessType]}
                                </span>
                            </Col>
                        </Row>
                        <Row className="inputs-container input-row">
                            <Col span={10}>
                                <span className="input-label">Related task</span>
                            </Col>
                            <Col span={14} style={{ cursor: 'pointer' }}>
                                <span onClick={handleRelatedTaskClick}>{store.getRelatedTask() && 'Link' || '-'}</span>
                            </Col>
                        </Row>
                        {/* commented out for demo purposes */}
                        {/* <Row className="inputs-container input-row">
                            <Col span={10}>
                                <span className="input-label">Message Function</span>
                            </Col>
                            <Col span={14}>
                                <span>{taskPreview.statistics?.messageFunction || '-'}</span>
                            </Col>
                        </Row> */}
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>);
};
export default observer(DetailsSection);