import { Tabs, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ReactQuill from 'react-quill';
import { TaskComment, TaskActivityEntry } from '../../../tasks/components';
import { TaskCommentsStore } from '../../../tasks/stores';
import TaskViewVisualStore from '../../../tasks/stores/TaskViewVisualStore';
import { Utils } from '../../misc/Utils';

type TaskCommentsSectionProps  = {
    store: TaskViewVisualStore;
    commentsStore: TaskCommentsStore;
    isFullView?: boolean
};
const { TabPane } = Tabs;

const TaskCommentsSection: React.FC<TaskCommentsSectionProps> = ({store, commentsStore, isFullView = false}) => {
    return (
        <>
            <Tabs className="full-width-tabs activity-tabs-wrapper" animated={false}>                
                <TabPane tab={<span data-id="button-comments-list-expnaded">Comments</span>} key="1">
                    {commentsStore.comments && commentsStore.comments.length > 0
                        ? (
                            <div className="comments-container">
                                {commentsStore.comments.map((c,i) => (
                                    <div key={i} data-id-type="flag-comments-expanded">
                                        <TaskComment
                                            key={c.id}
                                            store={commentsStore}
                                            currentUserId={store.currentUserId}
                                            comment={c}
                                            disabled={store.taskFormLocked}
                                            taskPrefix={store.taskPrefix}
                                            taskStore={store}
                                        />
                                    </div>
                                ))}
                            </div>
                        )
                        : (
                            <div className="empty-message">
                                There are no comments yet.
                            </div>
                        )
                    }
                </TabPane>
                <TabPane tab={<span data-id="button-activity-list-expnaded">Activity</span>} key="2">
                    {store.taskActivities && store.taskActivities.length > 0
                        ? (
                            <div className="task-activity-container" style={{ maxHeight: 'calc(100vh - 220px)', overflow: 'auto' }}>
                                {store.taskActivities.map((a,i) => (
                                    <div key={i}  data-id-type="flag-activity-expanded">
                                        <TaskActivityEntry key={a.id} store={store} activity={a} userName={store.allUsersFullNameResolver[a.doneBy] || a.doneBy} />
                                    </div>
                                ))}
                            </div>
                        )
                        : (
                            <div className="empty-message">There are no activity records yet.</div>
                        )}    
                </TabPane>
            </Tabs>
            {isFullView  && <div className="comment-input-container">
                <div className="comment-input-wrapper"  data-id={'input-comment'}>
                    <ReactQuill
                        modules={Utils.getQuilModules()}
                        readOnly={store.taskFormLocked}
                        value={commentsStore.taskNewComment || ''}
                        onChange={commentsStore.setTaskNewComment}
                        // eslint-disable-next-line max-len
                        className={`comment-input alpha-quill tb-bottom ${commentsStore.taskNewComment && commentsStore.taskNewComment.replace(Utils.htmlTags, '').trim().length > 0 ? 'has-text' : ''}`}
                        placeholder="Add comment..."
                    />
                    <div className="comment-controls">
                        <Button
                            type="primary"
                            disabled={!commentsStore.canPostComment}
                            onClick={() => commentsStore.postComment(store.taskPreview!.id)}
                            loading={commentsStore.postingComment}
                        >
                            Comment
                        </Button>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default observer(TaskCommentsSection);