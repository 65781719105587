import { Route } from 'react-router';
import * as React from 'react';
import  * as taskTypesContext  from '../stores/context';
import TaskTypesPage from '../screens/TaskTypesPage';

const taskTypeRoute = () => {
    return (
        <>
            <Route
                path={'/task/types'}
                element={
                    <taskTypesContext.StoreProvider>
                        <TaskTypesPage/>
                    </taskTypesContext.StoreProvider>
                }
            />
        </>
       
    );
};
  
export default taskTypeRoute;

