import * as React from 'react';
import { Observer } from 'mobx-react';
import { Page, Pdf, Document } from 'react-pdf';
import _ from 'lodash';
import { InputNumber } from 'antd';
import { AutoSizer } from 'react-virtualized';
import DocumentViewVisualStore from '../stores/DocumentViewVisualStore';
import { observer } from 'mobx-react-lite';

type PageState = {
    numPages: number;
    pageNumber: number
};

type Props = {
    store: DocumentViewVisualStore
};
export const PdfViewer: React.FC<Props> = ({store}) => {
    const [pageState, setPageState] = React.useState<PageState>({numPages: 0, pageNumber: 1});


    const setPages = (numPages: number, prevState: PageState) => ({ ...prevState, numPages: numPages });


    const handleDocumentLoaded = ({ numPages }: Pdf) => setPageState((s: PageState) => setPages(numPages, s));

    return (
        <div className="document-viewer-container">
            <div className="document-viewer-toolBar">
                <div className="document-viewer-toolBar-zoom">
                    <label>Scale:</label>
                    <InputNumber
                        defaultValue={store!.scale * 100}
                        min={0}
                        step={10}
                        max={100}
                        formatter={value => `${value}%`}
                        onChange={v => {
                            store!.setScale(v as number);
                        }}
                        parser={value => parseInt(value!.replace('%', ''), 10)}
                    />
                </div>
            </div>
            <Document
                file={store.file}
                onLoadSuccess={handleDocumentLoaded}
            >
                <div className="pdf-document-viewr-sidebar">
                    {/* <Outline 
                        // onItemClick={(p) => this.navigateToPage(p.pageNumber)} 
                    /> */}
                </div>
                <div className="pdf-document-viewer">
                    <AutoSizer
                        style={{height: '100%', width: '100%', textAlign: 'center'}}
                    >
                        {({ width }) =>
                            (
                                _.range(pageState.numPages + 1).map(v =>
                                // eslint-disable-next-line max-len
                                    <Observer key={v}>{() =>
                                        <Page
                                            // eslint-disable-next-line max-len
                                            key={v}
                                            width={width * store!.scale * 0.8}
                                            renderTextLayer={false}
                                            // ref={(r) => this.pageRefs.push(r!)}
                                            pageNumber={v}
                                            renderAnnotations={false}
                                        />}
                                    </Observer>)
                            )}
                    </AutoSizer>
                </div>
            </Document>
        </div>
    );
};

export default observer(PdfViewer);
