import { message } from 'antd';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ErrorStore } from '../../common/stores';
import { ActionService } from '../services/ActionService';
import { GlobalAdministrationService } from '../services/GlobalAdministrationService';
import { ActionDefinition, ActionField, ActionType, EditorConfig } from '../types/Actions';
import { MetadataDefinition } from '../types/Metadata';
import TasksRootStore from 'src/modules/tasks/stores/TasksRootStore';

export default class ActionDefinitionStore {
    actionTypes: ActionType[] = [];
    actionDefinitions: ActionDefinition[] = [];
    metadata: MetadataDefinition[] = [];
    editDialogMode: 'create' | 'edit' = 'create';
    editDialogVisible: boolean = false;
    editInputDialogVisible: boolean = false;
    selectedDefinition: ActionDefinition | null;
    selectedProjectId: string;
    selectedEditorConfig?: EditorConfig;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editorConfigChangeCallback?: (values: any) => void;


    constructor(
        private adminService: GlobalAdministrationService,
        private actionService: ActionService,
        private taskRootStore: TasksRootStore,
        private errorStore: ErrorStore) {
        makeObservable<ActionDefinitionStore>
        (this, {
            actionTypes: observable,
            actionDefinitions: observable,
            metadata: observable,
            editDialogMode: observable,
            editDialogVisible: observable,
            editInputDialogVisible: observable,
            selectedDefinition: observable,
            selectedProjectId: observable,
            selectedEditorConfig: observable,
            editorConfigChangeCallback: observable,
            setNewDefinitionDialogVisible: action.bound,
            setEditInputDialogVisible: action.bound,
            setSelectedProjectId: action.bound,
            loadActionTypes: action.bound,
            loadDefinitions: action.bound,
            selectActionDefinition: action.bound,
            createActionDefinition: action.bound,
            canSaveActionDefinition: computed,
            deleteActionDefinition: action.bound,
            updateActionDefinition: action.bound,
        });
    }

    get canSaveActionDefinition() {
        return true;
    }

    async setSelectedProjectId(projectId: string) {
        this.selectedProjectId = projectId;
        this.loadDefinitions(projectId);
        const resp = await this.adminService.getMetadataDefinitionsForProject(projectId);
        resp.map((metadata) => {
            runInAction(() => {
                this.metadata = metadata;
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    selectActionDefinition(definition: ActionDefinition | null) {
        this.selectedDefinition = definition;
        if (!definition) {
            this.editDialogVisible = false;
        } else {
            this.editDialogMode = 'edit';
            this.editDialogVisible = true;
        }
    }

    setNewDefinitionDialogVisible(visible: boolean) {
        this.selectedDefinition = { 
            id: '',
            name: '',
            projectId: this.selectedProjectId,
            actionType: '',
            isClientSide: false,
            fields: []
        };
        this.editDialogMode = 'create';
        this.editDialogVisible = visible;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setEditInputDialogVisible(visible: boolean, editorConfig?: EditorConfig, onChange?: (values: any) => void) {
        this.selectedEditorConfig = visible ? editorConfig : undefined;
        this.editorConfigChangeCallback = visible ? onChange : undefined;
        this.editInputDialogVisible = visible;
    }

    async loadActionTypes() {
        let res = await this.actionService.getActionTypes();
        res.map(data => {
            runInAction(() => {
                this.actionTypes = data;
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => {
            this.errorStore.addError(err.data);
        });
    }

    async loadDefinitions(projectId: string) {
        var response = await this.actionService.getActionDefinitions(projectId);

        response.map((definitions: ActionDefinition[]) => {
            runInAction(() => {
                this.actionDefinitions = definitions!;
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    async createActionDefinition(formValues: FormData) {
        try {
            const resp = await this.actionService.createActionDefinition(formValues);
            resp.map(() => {
                runInAction(() => {
                    this.loadDefinitions(this.selectedProjectId);
                    this.setNewDefinitionDialogVisible(false);
                });
                this.taskRootStore.loadActionDefinitions();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => {
                this.errorStore.addError(err.data);
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async updateActionDefinition(actionType: string, title: string, fields: ActionField[] | []) {
        let actionDefinitionId = this.selectedDefinition!.id;

        var response = await this.actionService.updateActionDefinition(actionDefinitionId, actionType, title, fields);
        response.map(() => {
            runInAction(() => {
                this.loadDefinitions(this.selectedProjectId);
                this.setNewDefinitionDialogVisible(false);
            });
            this.taskRootStore.loadActionDefinitions();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    async deleteActionDefinition(actionDefinitionId: string) {
        var response = await this.actionService.deleteActionDefinition(actionDefinitionId);
        response.map(() => {
            runInAction(() => {
                let newDefinitions = this.actionDefinitions.slice();
                newDefinitions = newDefinitions.filter(t => t.id !== actionDefinitionId);
                this.actionDefinitions = newDefinitions;
            });
            this.taskRootStore.loadActionDefinitions();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => {
            if (err.status === 409) {
                message.error(err.data!.title);
            } else {
                this.errorStore.addError(err.data);
            }
        });
    }

    async exportActionDefinition(id: string) {
        await this.actionService.exportActionDefinition(id);
    }

    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async importActionDefinition(formValues: any) {
        const formData = new FormData();
        formData.append('file', formValues.file);
        formData.append('name', formValues.name);
        if (formValues.title) {
            formData.append('title', formValues.title);
        }
        const result = await this.actionService.importActionDefinition(formData, this.selectedProjectId);
        result
            .map(() => { 
                this.loadDefinitions(this.selectedProjectId);
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .mapErr((err: any)=>  {
                this.errorStore.addError(err.data);
            });
        return result;
    }

}