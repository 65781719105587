import * as React from 'react';
import { observer } from 'mobx-react-lite';
import TaskStatusesVisualStore from '../stores/TaskStatusesVisualStore';
import { Button, Form, Input } from 'antd';
import { IFlowchartGraph } from '@ant-design/flowchart/lib';
import { FlowEditorSelection } from '../types';
import { DeleteOutlined } from '@ant-design/icons';

type Props = {
    store: TaskStatusesVisualStore;
    graph: IFlowchartGraph;
    selection: FlowEditorSelection
};

const FlowEdgeEditor: React.FC<Props> = ({ store, graph, selection}) => {
    const handleRemoveEdge = () => {
        if (graph && selection.item) {
            graph.removeEdge(selection.item.id);
            let status = store.taskStatuses.find(x => x.id === selection.item.source.cell);
            if (status?.transitions) {
                let newArr = status.transitions.slice().filter(x => x.targetStatusId !== selection.item.target.cell);
                status.transitions = newArr;
            }
        }
    };

    return (
        <>
            <Form.Item label={<span className="dialog-field-label">Source</span>} name='source'>
                <Input data-id="input-source" readOnly />
            </Form.Item>
            <Form.Item label={<span className="dialog-field-label">Target</span>} name='target'>
                <Input data-id="input-target" readOnly />
            </Form.Item>

            <Button onClick={handleRemoveEdge}>
                <DeleteOutlined />
                Remove transition
            </Button>
        </>
    );
};

export default observer(FlowEdgeEditor);