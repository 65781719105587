import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ColumnsFilter } from '../../common/services/types';
import { TasksData } from '../types';
import TasksRootStore from './TasksRootStore';
import { ALL_PROJECTS } from '../screens/TasksPage';

export default class TasksFiltersVisualStore {

    columnsFilterDict: {[key: string]: ColumnsFilter} = {};

    currentFilterOperator: string;

    constructor(private tasksRootStore: TasksRootStore) {
        makeObservable<TasksFiltersVisualStore>(this, {
            columnsFilterDict: observable,
            setCurrentFilterValue: action,
            resetColumnFilter: action,
            applyColumnFilter: action,
            taskTypesForCurrentProject: computed,
            handleAllFiltersReset: action.bound,
            isGridView: computed
        });
    }

    get taskTypesForCurrentProject() {
        return this.tasksRootStore.taskTypesForCurrentProject;
    }

    get taskStatusesForCurrentProject() {
        return this.tasksRootStore.taskStatusesForCurrentProject;
    }

    get allUsersFullNameResolver() {
        return this.tasksRootStore.allUsersFullNameResolver;
    }

    get isGridView() {
        return this.tasksRootStore.isGridView;
    }

    setColumnsFilter(tasks: TasksData) {
        if (tasks.userColumnsFilter) {
            for(let colFilter of tasks.userColumnsFilter.filters) {
                this.columnsFilterDict[colFilter.fieldName] = {
                    fieldName: colFilter.fieldName,
                    value: colFilter.value,
                    fieldType: colFilter.fieldType,
                    operator: colFilter.operator,
                    extraFilters: colFilter.extraFilters,
                    saved: true,
                    isWidgetFilter: false
                };
            }
        }

        for(let colFilter of tasks.widgetFilters) {
            this.columnsFilterDict[colFilter.fieldName] = {
                fieldName: colFilter.fieldName,
                value: colFilter.value,
                fieldType: colFilter.fieldType,
                operator: colFilter.operator,
                extraFilters: colFilter.extraFilters,
                saved: true,
                isWidgetFilter: true
            };
        }
    }

    setCurrentFilterValue(colName: string, value: unknown[], isMetaData: boolean) {
        if (this.columnsFilterDict[colName]) {
            const obj = Object.assign(this.columnsFilterDict[colName], {value: value});
            runInAction(() => {
                delete this.columnsFilterDict[colName];
                this.columnsFilterDict[colName] = obj;
            });
        } else {
            this.columnsFilterDict[colName] = {
                fieldName: colName,
                operator: this.currentFilterOperator,
                value,
                extraFilters: {},
                fieldType: isMetaData ? 'Metadata' : 'General',
                saved: false,
                isWidgetFilter: false
            };
        }
    }

    setCurrentFilterOperator(operator: string) {
        this.currentFilterOperator = operator;
    }

    unsetAllColumnsFilters() {
        runInAction(() => this.columnsFilterDict = {});
    }

    async applyColumnFilter(columnName: string) {
        this.tasksRootStore.setTasksIsLoading(true);
        const projectId = this.tasksRootStore.selectedProject === ALL_PROJECTS ? null : this.tasksRootStore.selectedProject;
        this.columnsFilterDict[columnName].operator = this.currentFilterOperator;   
        await this.tasksRootStore.updateColumnFilters(projectId, this.prepareRequestFilters());
        this.setCurrentFilterOperator('Equal');
        this.markFilterAsSaved(columnName);
    }

    async resetColumnFilter(columnName: string) {
        this.tasksRootStore.setTasksIsLoading(true);
        const projectId = this.tasksRootStore.selectedProject === ALL_PROJECTS ? null : this.tasksRootStore.selectedProject;
        this.removeFilter(columnName);
        await this.tasksRootStore.updateColumnFilters(projectId, this.prepareRequestFilters());
        this.tasksRootStore.setTasksIsLoading(false);
    }

    async handleAllFiltersReset() {
        await this.tasksRootStore.resetAllFilters();
    }

    markFilterAsSaved(columnName: string) {
        this.columnsFilterDict[columnName].saved = true;
    }

    removeFilter(columnName: string) {    
        delete this.columnsFilterDict[columnName];
    }

    private prepareRequestFilters() {
        return Object.values(this.columnsFilterDict).filter(x=> !x.isWidgetFilter).map(f=> {
            if (f.fieldType === 'Metadata') {
                const metadataId = this.tasksRootStore.metadataDefinitions.find(m=> m.name === f.fieldName)!.id;
                f.metatadaId = metadataId;
                return f;
            }
            return f;
        });
    }
}
