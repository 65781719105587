/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react';
import { TaskCommentsStore } from '../stores';
import { observer } from 'mobx-react-lite';
import { TaskModel } from '../types';
import { useStore } from 'src/modules/common/stores';

type Props = {
    store: TaskCommentsStore;
    alphaId: string
};

const TaskAlphaIdHyperlink: React.FC<Props> = ({store, alphaId}) => {
    const {errorStore} = useStore();
    if (!alphaId) {
        return null; 
    }

    const [task, setTask] = React.useState<TaskModel | undefined>(undefined);

    React.useEffect(() => {
        if (alphaId) {
            store.getTaskByAlphaId(alphaId).then(resp => {
                resp.map((r: TaskModel) => {
                    setTask(r);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }).mapErr((err: any) => errorStore.addError(err.data));
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [alphaId, store]);

    const handleClick = () => {
        if (task) {
            const url = `${process.env.PUBLIC_URL}/tasks/${task.id}`;
            window.open(url, '_blank')!.focus();
        }
    };

    return(
        <span className={task ? 'hyperlink' : ''} onClick={handleClick}>{alphaId}</span>
    );
};

export default observer(TaskAlphaIdHyperlink);