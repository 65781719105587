import * as React from 'react';
import { TaskCommentsStore, TaskViewVisualStore } from '../stores';
import {
    Drawer,
    Row,
    Button,
    Collapse,
} from 'antd';
import { observer } from 'mobx-react-lite';
import ReactQuill from 'react-quill';
import { Utils } from '../../common/misc/Utils';
import { useNavigate } from 'react-router';
import TaskPreview from './TaskPreview';
import TaskPreviewHeader from '../../common/components/TaskPreviewHeader';
import TaskCommentsSection from 'src/modules/common/components/TaskFullViewSections/TaskCommentsSection';

type Props = {
    store: TaskViewVisualStore;
    commentStore: TaskCommentsStore;
    subtasksRef?: React.MutableRefObject<null>
};

const TaskPreviewDialog: React.FC<Props> = ({ store, commentStore }) => {
    const { taskPreview } = store;
    const previewRef = React.useRef(null);
    const navigateTo = useNavigate();

    const handleMouseMove = React.useCallback((e: MouseEvent) => {
        if (!store.taskDrawerIsResizing) {
            return;
        }

        let offsetRight =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minWidth = 404;
        let maxWidth = Math.max(window.innerWidth / 2, 600);
        if (offsetRight > minWidth && offsetRight < maxWidth) {
            store.setTaskDrawerWidth(offsetRight);
        }
    }, [store]);

    const handleMouseUp = React.useCallback(() => {
        store.setTaskDrawerIsResizing(false);
    }, [store]);

    React.useEffect(() => {
        document.addEventListener('mousemove', (e: MouseEvent) => handleMouseMove(e));
        document.addEventListener('mouseup', () => handleMouseUp());

        return (() => {
            document.removeEventListener('mousemove', (e: MouseEvent) => handleMouseMove(e));
            document.removeEventListener('mouseup', () => handleMouseUp());
        });
    }, [handleMouseMove, handleMouseUp]);

    React.useEffect(() => {
        if (taskPreview && taskPreview.projectId) {
            commentStore.loadComments(taskPreview.id);
        } else {
            commentStore.unloadComments();
        }
    }, [taskPreview, commentStore]);


    const handleMouseDown = () => {
        store.setTaskDrawerIsResizing(true);
    };

    if (!taskPreview) {
        return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getExpandIcon = (panelProps: any) => {
        return (
            <i style={!panelProps.isActive ? { transform: 'rotate(-90deg)', transition: 'transform 0.24s' } :
                { transition: 'transform 0.24s' }} className="alpha-icon xxxs arrow-down-icon" />
        );
    };

    const expandTask = () => {
        if (!taskPreview.isViewed) {
            store.updateField(taskPreview.id, 'IsViewed', true);
        }
        store.setPageIsLoading(true);
        navigateTo(`/tasks/${taskPreview.id}`);
    };

    return (
        <Drawer
            data-id="dialog-container-right"
            title={(
                <Row>
                    <div className="drawer-resizer" onMouseDown={() => handleMouseDown()} />
                    <TaskPreviewHeader store={store} handleCollapseExpandTask={expandTask} isFullView={false}/>
                </Row>
            )}
            maskClosable={false}
            placement="right"
            className="task-properties-modal drawer"
            open={store.showPreviewDialog}
            mask={false}
            closable={false}
            getContainer={false}
            width={store.taskDrawerWidth}
            footer={
                <div className="comment-input-container">
                    <div className="comment-input-wrapper" data-id={'input-comment'}>
                        <ReactQuill
                            modules={Utils.getQuilModules()}
                            readOnly={store.taskFormLocked}
                            value={commentStore.taskNewComment || ''}
                            onChange={commentStore.setTaskNewComment}
                            // eslint-disable-next-line max-len
                            className={`comment-input alpha-quill tb-bottom ${commentStore.taskNewComment && commentStore.taskNewComment.replace(Utils.htmlTags, '').trim().length > 0 ? 'has-text' : ''}`}
                            placeholder="Add comment..."
                        />
                        <div id="task-preview-comment" className="comment-controls">
                            <Button
                                type="primary"
                                disabled={!commentStore.canPostComment}
                                onClick={() => commentStore.postComment(taskPreview!.id)}
                                loading={commentStore.postingComment}
                            >
                                Comment
                            </Button>
                        </div>
                    </div>
                </div>
            }
        >
            <div className="dialog-content-container" ref={previewRef}>
                <TaskPreview store={store} commentsStore={commentStore} isFullView={false} previewRef={previewRef}/>
                <div data-id-type="collapse-container" data-id-name="Activity">
                    <Collapse expandIcon={getExpandIcon} bordered={false} className="alpha-task-section-collapse no-padding">
                        <Collapse.Panel header="Activity" key="activity" className="alpha-task-section-collapse-panel">
                            <TaskCommentsSection store={store} commentsStore={commentStore}/>
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </div>
        </Drawer>
    );
};

export default observer(TaskPreviewDialog);