import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, Input, Button, Form, Checkbox, Select, Space, InputNumber } from 'antd';
import TaskTypesVisualStore from '../stores/TaskTypesVisualStore';
import FormItem from 'antd/lib/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import { periodOptions } from './TaskTypeCreateDialog';
import { UserProfile } from 'src/modules/common/services/types';

type Props = {
    store: TaskTypesVisualStore
};
// TO-DO: Merge with create dialog
const TaskTypeEditDialog: React.FC<Props> = ({store}) => {
    const [form] = useForm();
    React.useEffect(() => {
        form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.typeEditDialogVisible]);
    if (!store.selectedTaskType) {
        return null; 
    }

    const handleSubmit = async () => {
        form.validateFields().then((values) => {
            const dueDate = !values.count ? null : {count: values.count, period: values.period};
            store.editTaskType(values.name, values.assignee, values.code, values.canChangeStatusManually, values.distinctSubtaskStatuses, dueDate); 
        }).catch((err) => {
            console.log(err);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const taskTypeNamesValidator = (rule: any, value: any, callback: any) => { 
        let taskTypesToValidate = store!.taskTypes.map(t => t.name.toLowerCase());
        if (value && taskTypesToValidate.includes(value.toLowerCase()) && store.selectedTaskType?.name !== value) {
            callback('Task type with the same name already exists');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const taskTypesCodesValidator = (rule: any, value: any, callback: any) => { 
        let taskTypesToValidate = store!.taskTypes.map(t => t.code?.toLowerCase() ?? '');
        if (value && taskTypesToValidate.includes(value.toLowerCase()) && store.selectedTaskType?.code !== value) {
            callback('Task type with the same code already exists');
        } else {
            callback();
        }
    };

    const getUserLabel = (user: UserProfile) => {
        return (
            <>
                <span>{`${user.firstName} ${user.lastName}`}</span>
                <span style={{color: 'red'}}>{user.isDeleted ? ' (Inactive)' : ''}</span>
            </>);
    };


    return (
        <Modal
            data-id-type="modal-window"
            data-id-name="update type"
            className="alpha-modal"
            centered
            title={`Update Task Type ${store.selectedTaskType!.name}`}
            open={store.typeEditDialogVisible}
            onCancel={() => {
                store.selectType(undefined); 
            }}
            closable={false}
            footer={[
                <Button 
                    data-id="button-edit-task-type-cancel"
                    key="task-type-cancel" 
                    size="large"
                    className="light"
                    onClick={() => {
                        store.selectType(undefined); 
                    }}
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="button-edit-task-type-Save"
                    type="primary"
                    key="task-type-update" 
                    size="large"
                    onClick={handleSubmit}
                >
                        Save
                </Button>
            ]}
        >
            <Form 
                layout="vertical"
                data-id="create-task-type-dialog-form"
                form={form}
                className='alpha-form'
                initialValues={{...store.selectedTaskType, ...{count: store.selectedTaskType.dueDate?.count, period: store.selectedTaskType.dueDate?.period}}}
            >
                <FormItem 
                    label={<span className="dialog-field-label">Name</span>}
                    name='name'  
                    rules={[
                        { required: true, message: 'Name is required', whitespace: true },
                        { validator : taskTypeNamesValidator}
                    ]}>
                    <Input data-id="input-Name" placeholder="Name" />
                </FormItem>
                <FormItem
                    label={<span className="dialog-field-label">Default assignee</span>}
                    name="assignee"
                    rules={[{ required: true, message: 'Project owner is required', whitespace: true }]}
                    initialValue={store.selectedTaskType.assignee}
                >
                    <Select options={store.filteredUsersForProject.map(u=> ({value: u.userId, label: getUserLabel(u)}))} />
                </FormItem>
                <FormItem 
                    label={<span className="dialog-field-label">Code</span>}
                    name='code'  
                    rules={[{ validator : taskTypesCodesValidator}]}
                >
                    <Input data-id="input-Name" placeholder="Code"/>
                </FormItem>
                <FormItem label='Due period'>
                    <Space>
                        <FormItem 
                            name='count'  
                        >
                            <InputNumber min={1} data-id="input-cout"/>
                        </FormItem>
                        <FormItem 
                            name='period'  
                            style={{width: 100}}
                        >
                            <Select data-id="input-period" options={periodOptions.map(p=> ({label: p, value: p.charAt(0)}))}/>
                        </FormItem>
                    </Space>
                </FormItem>
                <FormItem 
                    label={<span className="dialog-field-label">Manual status change allowed</span>}
                    name='canChangeStatusManually'
                    valuePropName='checked'
                >
                    <Checkbox data-id="input-Name"/>
                </FormItem>
                <FormItem 
                    label={<span className="dialog-field-label">Distinct subtask statuses</span>}
                    name='distinctSubtaskStatuses'
                    valuePropName='checked'
                >
                    <Checkbox data-id="input-Name"/>
                </FormItem>
            </Form>
        </Modal>
    );
};

export default observer(TaskTypeEditDialog);