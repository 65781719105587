import { Checkbox, Form, InputNumber } from 'antd';
import React from 'react';

export const TextEditorConfigForm: React.FC = () => {
    return (
        <>
            <Form.Item name="maxLength" label="Max length">
                <InputNumber />
            </Form.Item>
            <Form.Item
                name="isMultiLine"
                wrapperCol={{offset: 8}}
                valuePropName="checked">
                <Checkbox>Multiline</Checkbox>
            </Form.Item>
        </>
    );
};