import * as React from 'react';
import { TaskViewVisualStore } from '../stores';
import { observer } from 'mobx-react-lite';

type Props = {
    store: TaskViewVisualStore
};

type EmailInfoEntryProps = {
    value: string | undefined;
    label: string
};

const EmailInfoEntry: React.FC<EmailInfoEntryProps> = ({ label, value }) => {
    if (!value) {
        return null;
    }

    return (
        <div className="email-info-entry">
            <span className="info-entry-label">{label}: </span>
            <span className="info-entry-value">{value}</span>
        </div>
    );
};

const TaskEmailPreview: React.FC<Props> = ({ store }) => {
    if (!store.previewEmailMetadata) {
        return null;
    }

    return (
        <div className="email-preview">
            <div className="email-info-container">
                <EmailInfoEntry label="From" value={store.previewEmailMetadata.from} />
                <EmailInfoEntry label="To" value={store.previewEmailMetadata.to ? store.previewEmailMetadata.to.join('; ') : undefined} />
                <EmailInfoEntry
                    label="Date"
                    value={store.previewEmailMetadata.date}
                />
                <EmailInfoEntry label="Subject" value={store.previewEmailMetadata.subject} />
            </div>
            <div className="email-body-container">
                {store.previewEmailMetadata.body}
            </div>
        </div>
    );
};

export default observer(TaskEmailPreview);