import { DashboardWidgetType } from 'src/modules/administration/types/DashboardWidget';

export default abstract class WidgetBase {
    id: string;
    name: string;
    widgetType: DashboardWidgetType;
    projectId: string;
    isDefaultWidget: boolean;

    constructor(id: string, widgetType: DashboardWidgetType, name: string, projecId: string, isDefaultWidget: boolean){
        this.id = id;
        this.name = name;
        this.widgetType = widgetType;
        this.projectId = projecId;
        this.isDefaultWidget = isDefaultWidget;
    }
}