import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import UsersManagerStore from '../../administration/stores/UsersManagerStore';
import { UserProfileStore } from '../../common/stores';
import ResetPasswordForm from './ResetPasswordForm';

type Props =  {
    store: UserProfileStore | UsersManagerStore
};

const ResetPasswordDialog: React.FC<Props> = ({store}) => {
    const handleCancel = () => {
        store!.setResetPasswordDialogVisible(false);
    };

    return (
        <Modal
            open={store!.resetPasswordDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose
            footer={null}
            zIndex={10000}
        >
            <ResetPasswordForm store={store} handleCancel={handleCancel} />
        </Modal>
    );
};
export default observer(ResetPasswordDialog);