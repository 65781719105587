import * as React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { Button, Table, Popconfirm, Layout, Tooltip, Select, Checkbox } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ProjectsStore } from '../../common/stores';
import MetadataDefinitionStore from '../stores/MetadataDefinitionStore';
import { MetadataDefinition } from '../types/Metadata';
import MetadataDefinitionCreateEditDialog from './MetadataDefinitionCreateEditDialog';
import { Utils } from 'src/modules/common/misc/Utils';
import { DragableBodyRow } from 'src/modules/common/components/TableRowDragAndDropRenderer';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const { Content } = Layout;
type Props = {
    store: MetadataDefinitionStore;
    projectsStore: ProjectsStore
};

const MetadataDefinitionsList: React.FC<Props> = ({ store, projectsStore }) => {
    const [projectSelected, setProjectSelected] = React.useState<boolean>(false);

    const columns: ColumnProps<MetadataDefinition>[] = [
        {
            title: 'Name', dataIndex: 'name', key: 'name',
            render: (text: string, record: MetadataDefinition) => (
                <span data-id-cells="name">{record.name}</span>
            )
        },
        {
            title: 'Title', dataIndex: 'title', key: 'title',
            render: (text: string, record: MetadataDefinition) => (
                <span data-id-cells="title">{record.title}</span>
            )
        },
        {
            title: 'Type', dataIndex: 'fieldType', key: 'fieldType',
            render: (text: string, record: MetadataDefinition) => (
                <span data-id-cells="fieldType">{record.fieldType}</span>
            )
        },
        {
            title: 'Task Types', dataIndex: 'taskTypes', key: 'taskTypes',
            render: (text: string, record: MetadataDefinition) => (
                <Observer>{() => (
                    <span data-id-cells="taskTypes">{record.taskTypes && record.taskTypes.map(id => store.taskTypes.find(t=> t.id === id)?.name).join(', ')}</span>)}
                </Observer>
            )
        },
        {
            title: 'Description', dataIndex: 'description', key: 'description',
            render: (text: string, record: MetadataDefinition) => (
                <span data-id-cells="name">{record.description}</span>
            )
        },
        {
            title: 'Is visible', dataIndex: 'isVisible', key: 'isVisible',
            render: (text: string, record: MetadataDefinition) => (
                <Checkbox data-id-cells="isVisible" checked={record.isVisible}/>
            )
        },
        {
            title: 'Actions',
            width: 160,
            render: (text: string, record: MetadataDefinition) => (
                <div className="row-actions-wrapper">
                    <Button
                        data-id-cells="Edit metadata definition"
                        title="Rename"
                        size="small"
                        type="link"
                        onClick={() => {
                            store.selectMetadataDefinition(record);
                        }}
                    >
                        <Tooltip title="Edit metadata definition" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id="data-id-popconfirm-box"
                        title="Are you sure you want to delete this metadata definition?"
                        onConfirm={() => {
                            store.deleteMetadataDefinition(record.id);
                        }}
                        placement="topRight"
                    >
                        <Button data-id-cells="Delete metadata definition" title="Delete" size="small" type="link">
                            <Tooltip title="Delete metadata definition" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                </div>
            )
        }
    ];

    const dragAndDropBody =  {
        body: {
            row: DragableBodyRow
        }
    };

    const moveRow = (dragIndex: number, dropIndex: number, draggedRecord: MetadataDefinition) => {
        store.updateListOrder(draggedRecord.id, dragIndex, dropIndex);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Layout className="screen-size tasks-types-layout layout-with-table">
                <div className='header-wrapper'>
                    <div className='title-wrapper'>
                        <div className="header-title">Metadata Definitions</div>
                    </div>
                    <Button key={1} disabled={!store.selectedProjectId} data-id="button-add-definition" type="primary" size="large" onClick={() => {
                        store.setNewDefinitionDialogVisible(true);
                    }}>Add definition</Button>
                </div>
                <MetadataDefinitionCreateEditDialog store={store} />
                <Layout>
                    <Content>
                        <div className='projects-list-container' style={{ marginBottom: 10 }}>
                            <Select
                                showSearch
                                filterOption={Utils.filterOption}
                                options={projectsStore.administrableProjects?.map(p => ({ label: p.name, value: p.id })) || []}
                                placeholder='Select project'
                                onChange={v => {
                                    setProjectSelected(true);
                                    store.setSelectedProjectId(v);
                                }}
                            />
                        </div>
                        <Table
                            data-id="table-tasks-types-list"
                            className="alpha-table metadata"
                            loading={store.isTableLoding}
                            columns={columns}
                            dataSource={projectSelected ? store.metadataDefinitions.slice().sort((a, b) => a.position - b.position) : []}
                            rowKey={(r) => r.id}
                            pagination={false}
                            components={dragAndDropBody}
                            onRow={(record, index) => ({
                                index,
                                record,
                                moveRow: moveRow
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            } as any)}
                            rowSelection={{}}
                        />
                    </Content>
                </Layout>
            </Layout>
        </DndProvider>
    );
};

export default observer(MetadataDefinitionsList);